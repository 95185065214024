import React from "react";
import { Widget, addResponseMessage } from "react-chat-widget";
import "react-chat-widget/lib/styles.css";
import { useChat } from "context/ChatContext";
import { useHelpAssistantServices } from "services";
import { lang } from "hooks";

const ChatBox = () => {
  const { closeChatEl } = useChat();
  const { completionRequest } = useHelpAssistantServices();

  const handleNewUserMessage = async (newMessage) => {
    //console.log("ChatBox.handleNewUserMessage:", newMessage);
    const response = await completionRequest({ message: newMessage });
    if (response.status_code == 200) {
      addResponseMessage(response.data);
      return;
    }
    addResponseMessage(lang()("HELP_ASSISTANT_COMPLETION_ERROR"));
  };

  const handleToggle = (status) => {
    console.log("Chat toggled", status);
    if (status == false) {
      closeChatEl();
    }
  };

  return (
    <Widget
      handleNewUserMessage={handleNewUserMessage}
      title={lang("ucfirst")("G_ASK_QUESTION")}
      //subtitle="We are here to help"
      handleToggle={handleToggle}
      resizable={true}
      emojis={true}
      autofocus={true}
      showCloseButton={true}
      senderPlaceHolder={lang("ucfirst")("G_TYPE_MSG")}
      //profileAvatar=
    />
  );
};

export default ChatBox;
