// src/customConsole.js

// Save original console methods
const originalConsoleLog = console.log;
const originalConsoleError = console.error;
const originalConsoleWarn = console.warn;

function stringToBool(str) {
  return str === "1" || str.toLowerCase() === "true";
}

// Create a wrapper for console.log
console.log = function (...args) {
  const debugBuild = stringToBool(process.env.REACT_APP_DEBUG_BUILD);
  if (process.env.NODE_ENV !== "production" || debugBuild) {
    originalConsoleLog.apply(console, args);
  }
};

// Create a wrapper for console.error
console.error = function (...args) {
  const debugBuild = stringToBool(process.env.REACT_APP_DEBUG_BUILD);
  if (process.env.NODE_ENV !== "production" || debugBuild) {
    originalConsoleError.apply(console, args);
  }
};

// Create a wrapper for console.warn
console.warn = function (...args) {
  const debugBuild = stringToBool(process.env.REACT_APP_DEBUG_BUILD);
  if (process.env.NODE_ENV !== "production" || debugBuild) {
    originalConsoleWarn.apply(console, args);
  }
};
