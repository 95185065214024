import { Link, Icon, IconButton, Divider } from "@mui/material";
import { MDBox, MDInput, MDTypography, MDButton } from "components";
import ConfiguratorRoot from "components/Configurator/ConfiguratorRoot";
import { useMaterialUIController, setOpenConfigurator, setSidenavColor } from "context";

function Configurator() {
  const [controller, dispatch] = useMaterialUIController();
  const { openConfigurator, sidenavColor, darkMode, currentMode } = controller;
  const sidenavColors = ["primary", "dark", "info", "success", "warning", "error"];
  const handleCloseConfigurator = () => setOpenConfigurator(dispatch, false);

  return (
    <ConfiguratorRoot variant="permanent" ownerState={{ openConfigurator }}>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        pt={4}
        pb={0.5}
        px={3}
      >
        <MDTypography variant="h5">Super Admin Panel</MDTypography>
        <Icon
          sx={({ typography: { size }, palette: { dark, white } }) => ({
            fontSize: `${size.lg} !important`,
            color: darkMode ? white.main : dark.main,
            stroke: "currentColor",
            strokeWidth: "2px",
            cursor: "pointer",
            transform: "translateY(5px)",
          })}
          onClick={handleCloseConfigurator}
        >
          close
        </Icon>
      </MDBox>

      <Divider />

      <MDBox pt={0.5} pb={3} px={3}>
        <MDBox>
          <MDTypography variant="h6">Sidenav Colors</MDTypography>

          <MDBox mb={0.5}>
            {sidenavColors.map((color) => (
              <IconButton
                key={color}
                sx={({
                  borders: { borderWidth },
                  palette: { white, dark, background },
                  transitions,
                }) => ({
                  width: "24px",
                  height: "24px",
                  padding: 0,
                  border: `${borderWidth[1]} solid ${darkMode ? background.sidenav : white.main}`,
                  borderColor: () => {
                    let borderColorValue = sidenavColor === color && dark.main;

                    if (darkMode && sidenavColor === color) {
                      borderColorValue = white.main;
                    }

                    return borderColorValue;
                  },
                  transition: transitions.create("border-color", {
                    easing: transitions.easing.sharp,
                    duration: transitions.duration.shorter,
                  }),
                  backgroundImage: ({ functions: { linearGradient }, palette: { gradients } }) =>
                    linearGradient(gradients[color].main, gradients[color].state),

                  "&:not(:last-child)": {
                    mr: 1,
                  },

                  "&:hover, &:focus, &:active": {
                    borderColor: darkMode ? white.main : dark.main,
                  },
                })}
                onClick={() => setSidenavColor(dispatch, color)}
              />
            ))}
          </MDBox>
        </MDBox>
        <Divider />
        <MDBox mt={3} mb={2}>
          <MDTypography variant="h6">Swagger Docs</MDTypography>
          <MDBox mt={1}>
            <MDButton
              component={Link}
              href="/docs/backend"
              target="_blank"
              rel="noreferrer"
              color={darkMode ? "light" : "dark"}
              variant="outlined"
              fullWidth
            >
              backend reference
            </MDButton>
          </MDBox>
        </MDBox>
        <Divider />
        <MDTypography variant="h6">Simulate User</MDTypography>
        <MDBox mt={1}>
          <MDInput
            fullWidth
            name="simulator_username"
            label="Type username"
            //value={formValues.model_model}
            //onChange={(e) => handleInputChange(e)}
          />
        </MDBox>

        <MDBox mt={1}>
          <MDButton color={darkMode ? "light" : "dark"} variant="outlined" fullWidth>
            Find user
          </MDButton>
        </MDBox>
      </MDBox>
    </ConfiguratorRoot>
  );
}

export default Configurator;
