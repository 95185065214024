import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { ListItem, ListItemIcon, ListItemText, Collapse, List, Icon } from "@mui/material";
import MDBox from "components/MDBox";
import { useMaterialUIController } from "context";
import {
  collapseItem,
  collapseIconBox,
  collapseIcon,
  collapseText,
} from "components/Sidenav/styles/sidenavCollapse";

function SidenavCollapse({ icon, name, active, noCollapse, children, ...rest }) {
  const [controller] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode, sidenavColor } = controller;
  const [open, setOpen] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [clickTriggered, setClickTriggered] = useState(false);
  const clickedElementRef = useRef(null);
  const menuRef = useRef(null);
  const hasRun = useRef(false);

  const handleClick = () => {
    setOpen(!open);
    clickedElementRef.current = event.target;
  };

  useEffect(() => {
    menuRef.current = document.querySelector("ul.MuiList-root.MuiList-padding:first-of-type");
    console.log("SidenavCollapse.useEffect.menuRef.current", menuRef.current);
    const handleClick = (event) => {
      if (menuRef.current && menuRef.current.contains(event.target)) {
        console.log("SidenavCollapse.handleClick");
        setClickTriggered(true);
        clickedElementRef.current = event.target;
      }
    };

    /*if (menuRef.current) {
      menuRef.current.addEventListener("click", handleClick);
    }*/
    document.addEventListener("click", handleClick);

    return () => {
      /*if (menuRef.current) {
        menuRef.current.removeEventListener("click", handleClick);
      }*/
      document.removeEventListener("click", handleClick);
    };
  }, []);

  useEffect(() => {
    if (clickTriggered && !isInitialized) {
      console.log("SidenavCollapse.useEffect.clickTriggered:", clickTriggered);
      setIsInitialized(true); // Set to true once the effect runs
      const menu = document.querySelector("ul.MuiList-root.MuiList-padding:first-of-type");

      if (menu) {
        console.log("menu", menu);
        const listItems = menu.querySelectorAll("li");
        console.log("listItems", listItems);
        listItems.forEach((li) => {
          const targetDiv = li.querySelector("div");

          if (targetDiv) {
            console.log("targetDiv", targetDiv);
            targetDiv.style.backgroundColor = "";
            targetDiv.style.borderRadius = "";
            targetDiv.classList.remove("highlighted");
          }
        });

        const activeLink = document.querySelector("a.active");

        if (activeLink) {
          const parentLi = activeLink.closest("li");

          if (parentLi) {
            const parentCollapse = parentLi.closest("div.MuiCollapse-root");

            if (parentCollapse) {
              const previousLi = parentCollapse.previousElementSibling;

              if (previousLi && previousLi.tagName === "LI") {
                const targetDiv = previousLi.querySelector("div");

                if (targetDiv) {
                  targetDiv.style.backgroundColor = "rgba(76, 175, 80, 0.1)";
                  targetDiv.style.borderRadius = "8px";
                  targetDiv.classList.add("highlighted");
                }
              }
            }
          }
        }

        const collapsable = menu.querySelectorAll("li.MuiListItem-root.MuiListItem-padding");
        collapsable.forEach((item) => {
          const targetDiv = item.querySelector("div");

          if (targetDiv) {
            console.log("item.targetDiv", targetDiv);
            const hasClass = targetDiv.classList.contains("highlighted");

            if (hasClass) {
              //console.log("Div has the 'highlighted' class.");
            } else {
              const spanIcon = item.querySelectorAll("span");
              spanIcon.forEach((item) => {
                if (
                  item.textContent == "expand_less" &&
                  clickedElementRef.current.textContent != "expand_more" &&
                  clickedElementRef.current.textContent != "expand_less" &&
                  !clickedElementRef.current.classList.contains("collapse-menu-section") &&
                  !clickedElementRef.current.parentElement.classList.contains(
                    "collapse-menu-section"
                  ) &&
                  !clickedElementRef.current.classList.contains("external-link") &&
                  !clickedElementRef.current.parentElement.classList.contains("external-link")
                ) {
                  console.log(
                    clickedElementRef.current,
                    clickedElementRef.current.parentElement,
                    item
                  );
                  targetDiv.parentElement.click();
                }
              });
            }
          }
        });
      }

      setClickTriggered(false);
    }

    if (!clickTriggered) {
      setIsInitialized(false);
    }
  }, [clickTriggered, children, isInitialized]);

  const { noCollapse: _, ...filteredRest } = rest;

  return (
    <>
      <ListItem component="li" onClick={handleClick}>
        <MDBox
          {...filteredRest}
          sx={(theme) =>
            collapseItem(theme, {
              active,
              transparentSidenav,
              whiteSidenav,
              darkMode,
              sidenavColor,
            })
          }
        >
          <ListItemIcon
            sx={(theme) =>
              collapseIconBox(theme, { transparentSidenav, whiteSidenav, darkMode, active })
            }
          >
            {typeof icon === "string" ? (
              <Icon sx={(theme) => collapseIcon(theme, { active })}>{icon}</Icon>
            ) : (
              React.cloneElement(icon, { className: "collapse-menu-section" })
            )}
          </ListItemIcon>

          <ListItemText
            primary={name}
            sx={(theme) =>
              collapseText(theme, {
                miniSidenav,
                transparentSidenav,
                whiteSidenav,
                active,
              })
            }
          />
          {children && (open ? <Icon>expand_less</Icon> : <Icon>expand_more</Icon>)}
        </MDBox>
      </ListItem>
      {children && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {React.Children.map(children, (child) => React.cloneElement(child))}
          </List>
        </Collapse>
      )}
    </>
  );
}

SidenavCollapse.defaultProps = {
  active: false,
};

SidenavCollapse.propTypes = {
  icon: PropTypes.node.isRequired,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  active: PropTypes.bool,
  noCollapse: PropTypes.bool,
  children: PropTypes.node,
};

export default SidenavCollapse;
