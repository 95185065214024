import React, { useState, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "!style-loader!css-loader!react-input-range/lib/css/index.css";
import InputRange from "react-input-range";
import PropTypes from "prop-types";
import { useSnackbar, lang, useSelectToggle } from "hooks";
import { useChatAssistantService } from "services";
import { useUserContext } from "context";
import {
  Icon,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  CardContent,
  TextareaAutosize,
  Tooltip,
  Grid,
  IconButton,
} from "@mui/material";
import {
  MDBox,
  MDTypography,
  MDInput,
  MDButton,
  Panel,
  CustomHidden,
  LoadingOverlay,
  EnlargeTextareaDialog,
} from "components";
import {
  //inputReadOnlySX,
  areRequiredFieldsFilled,
  hasChanges,
  formatErrorMsg,
  HelpToolTip,
  CustomBadge,
  collectKeys,
  inputErrorSX,
} from "utils";
import { default as ReactSelect } from "react-select";
import makeAnimated from "react-select/animated";
import theme from "assets/theme";
import themeDark from "assets/theme-dark";
import { assistantFormDefaults } from "configDefaults";

const RecordForm = ({ record, onEdit, darkMode, mainColor, promptTemplate = null }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { showSnackbar, SnackbarComponent } = useSnackbar();
  const [formErrors, setFormErrors] = useState([]);
  const [initialValues, setInitialValues] = useState({});
  const { selectOpen, handleIconClick, closeSelect, openSelect } = useSelectToggle();
  //const [readOnlyFields, setReadOnlyFields] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [formValues, setFormValues] = useState({
    name: "New Assistant",
    model_provider: "",
    model_model: "",
    model_temperature: 0,
    model_correctTemperatureValue: 0.0,
    model_system_prompt: "",
    model_knowledge_base: "",
    model_max_tokens: "",
    model_max_history: 20,
    knowledge_base_files: [],
    model_tools: "[]",
  });
  const [requiredFields] = useState([
    "name",
    "model_provider",
    "model_model",
    "model_system_prompt",
    "model_max_history",
  ]);
  const { addChatAssistant, editChatAssistant } = useChatAssistantService();
  const { providers, knowledgeBaseFiles, chatPromptTemplates } = useUserContext();
  const [provider, setProvider] = useState("");
  const [knowledgeBaseFilesOptions, setKnowledgeBaseFilesOptions] = useState([]);
  const animatedComponents = makeAnimated();
  const myTheme = darkMode ? themeDark : theme;
  const [openTextareaFullscreen, setOpenTextareaFullscreen] = useState(false);

  const onCloseTextareaFullscreen = () => {
    setOpenTextareaFullscreen(false);
  };

  useEffect(() => {
    console.log("knowledgeBaseFiles", knowledgeBaseFiles);
    setKnowledgeBaseFilesOptions(
      knowledgeBaseFiles.map((item) => ({
        value: item.filename,
        label: item.filename,
      }))
    );

    if (record) {
      const { name, model } = record;
      const convert_temperature = model.temperature ? parseFloat(model.temperature) * 10 : 0;
      const knowledge_base = model.knowledge_base.map((item) => ({
        value: item,
        label: item,
      }));
      setFormValues({
        name,
        model_model: model.model,
        model_temperature: convert_temperature,
        model_correctTemperatureValue: model.temperature || 0.0,
        model_system_prompt: model.system_prompt,
        model_provider: model.provider.sid,
        knowledge_base_files: knowledge_base || [],
        model_tools: JSON.stringify(model.tools, null, 2) || "[]",
        model_max_tokens: model.max_tokens ?? "",
        model_max_history: model.max_history,
      });
      setInitialValues({
        name,
        model_model: model.model,
        model_temperature: convert_temperature,
        model_correctTemperatureValue: model.temperature || 0.0,
        model_system_prompt: model.system_prompt,
        model_provider: model.provider.sid,
        knowledge_base_files: knowledge_base || [],
        model_tools: JSON.stringify(model.tools, null, 2) || "[]",
        model_max_tokens: model.max_tokens ?? "",
        model_max_history: model.max_history,
      });
      //setReadOnlyFields(["name"]);
    } else if (promptTemplate != null) {
      setFormValues((prevValues) => ({
        ...prevValues,
        model_system_prompt: chatPromptTemplates[promptTemplate].content,
      }));
      setInitialValues((prevValues) => ({
        ...prevValues,
        model_system_prompt: chatPromptTemplates[promptTemplate].content,
      }));
    }
  }, [record]);

  const handleInputChange = (event) => {
    const { name, value: inputValue } = event.target;
    let newValue = inputValue;
    console.log("handleInputChange", name, typeof newValue, newValue);
    if (name == "model_provider") {
      handleProviderChange(event);
    }
    if (
      name == "model_max_history" &&
      (newValue > assistantFormDefaults.max_history || isNaN(newValue) || !newValue)
    ) {
      if (isNaN(newValue)) {
        newValue = 1;
      } else if (!newValue) {
        newValue = 20;
      } else {
        newValue = assistantFormDefaults.max_history;
      }
    }
    if (
      name == "model_max_tokens" &&
      (newValue > assistantFormDefaults.max_tokens || isNaN(newValue))
    ) {
      if (isNaN(newValue)) {
        newValue = 1;
      } else {
        newValue = assistantFormDefaults.max_tokens;
      }
    }
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: newValue,
    }));
    setFormErrors((prevErrors) => prevErrors.filter((errorField) => errorField !== name));
  };

  const handleKnowledgeBaseSelectorChange = (selectedFiles) => {
    console.log("handleKnowledgeBaseSelectorChange:", selectedFiles);
    setFormValues((prevValues) => ({ ...prevValues, knowledge_base_files: selectedFiles }));
  };

  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    const { name } = formValues;
    const files = (formValues.knowledge_base_files || []).map((option) => option.value);
    let errorMsg = "";
    let tools = "";
    try {
      if (formValues.model_tools) {
        tools = JSON.parse(formValues.model_tools);
      } else {
        tools = [];
      }
    } catch (error) {
      console.error("Error handling record:", error);
      showSnackbar("error", lang("ucfirst")("CHAT_ASSISTANT_INVALID_TOOLS_FORMAT_ERROR"));
      setIsLoading(false);
      return;
    }
    try {
      const temperature =
        formValues.model_correctTemperatureValue === 0
          ? null
          : formValues.model_correctTemperatureValue;
      if (record) {
        const model_params = {
          model: formValues.model_model,
          provider_sid: formValues.model_provider,
          system_prompt: formValues.model_system_prompt,
          temperature: temperature,
          knowledge_base: files,
          tools: tools,
          max_history: formValues.model_max_history,
        };
        if (formValues.model_max_tokens) {
          model_params["max_tokens"] = formValues.model_max_tokens;
        }
        const response = await editChatAssistant({
          sid: record.sid,
          name,
          model: model_params,
        });
        if (response.status_code === 200) {
          setInitialValues(formValues);
          onEdit(false);
        } else if ((errorMsg = formatErrorMsg(response.data))) {
          setFormErrors(collectKeys(response.data.details));
          showSnackbar("error", errorMsg);
        } else {
          showSnackbar("error", lang("ucfirst")("G_FORM_ERROR"));
        }
      } else {
        const model_params = {
          model: formValues.model_model,
          provider_sid: formValues.model_provider,
          system_prompt: formValues.model_system_prompt,
          temperature: temperature,
          knowledge_base: files,
          tools: tools,
          max_history: formValues.model_max_history,
        };
        if (formValues.model_max_tokens) {
          model_params["max_tokens"] = formValues.model_max_tokens;
        }
        const response = await addChatAssistant({
          name,
          model: model_params,
        });
        if (response.status_code === 200) {
          onEdit(true);
        } else if ((errorMsg = formatErrorMsg(response.data))) {
          setFormErrors(collectKeys(response.data.details));
          showSnackbar("error", errorMsg);
        } else {
          showSnackbar("error", lang("ucfirst")("G_FORM_ERROR"));
        }
      }
    } catch (error) {
      console.error("Error handling record:", error);
      showSnackbar("error", lang("ucfirst")("G_FORM_ERROR"));
    }
    setIsLoading(false);
  };

  const handleTemperatureChange = (input) => {
    const { name, value } = input.target ? input.target : input;
    const convertedValue = (value / 10).toFixed(1);
    setFormValues((prevValues) => ({
      ...prevValues,
      model_correctTemperatureValue: convertedValue,
    }));

    setFormValues((prevValues) => ({
      ...prevValues,
      model_temperature: value,
    }));
  };

  const handleProviderChange = (e) => {
    const newValue = e.target.value;
    setProvider(newValue);
    const provider = providers
      ? Object.values(providers).find((provider) => provider.sid === newValue)
      : null;
    if (provider) {
      if ("model" in provider.default_config) {
        setFormValues((prevValues) => ({
          ...prevValues,
          model_model: provider.default_config.model,
        }));
      }
      if ("temperature" in provider.default_config) {
        setFormValues((prevValues) => ({
          ...prevValues,
          model_correctTemperatureValue: provider.default_config.temperature,
          model_temperature: provider.default_config.temperature * 10,
        }));
      }
    }
  };

  const providerOptions = providers
    ? Object.entries(providers)
        .filter(([key, provider]) => provider.type === "model") // Filter by provider type
        .map(([key, provider]) => (
          <MenuItem key={key} value={provider.sid}>
            {provider.name} ({provider.sid})
          </MenuItem>
        ))
    : null;

  const defaultConfigElement = (height = "150px !important") => {
    return (
      <TextareaAutosize
        name="model_system_prompt"
        minRows={5}
        maxRows={5}
        aria-label="System Prompt*"
        placeholder="System Prompt*"
        value={formValues.model_system_prompt}
        onChange={handleInputChange}
        style={{
          borderColor: darkMode ? "rgba(255, 255, 255, 0.5)" : "rgb(12 10 10 / 12%)",
          outline: "none",
          width: "100%",
          //height: "150px",
          height: height,
          padding: "12px",
          fontSize: "0.9rem",
          resize: "none",
          overflow: "auto",
          scrollbarWidth: "thin",
          //minHeight: "80px",
          color: darkMode ? "white" : "black",
          backgroundColor: "transparent",
          borderRadius: "8px",
        }}
      />
    );
  };

  return (
    <Tabs
      forceRenderTabPanel
      selectedIndex={activeTab}
      onSelect={(index) => setActiveTab(index)}
      selectedTabClassName={
        darkMode ? "react-tabs-dark__tab--selected" : "react-tabs-dark__tab--selected"
      }
    >
      <EnlargeTextareaDialog
        open={openTextareaFullscreen}
        onClose={onCloseTextareaFullscreen}
        darkMode={darkMode}
        element={defaultConfigElement("15vh")}
      />
      <TabList>
        <Tab>
          <MDTypography
            variant="h6"
            fontWeight="regular"
            sx={{ color: formErrors.includes("name") ? "red" : "inherit" }}
          >
            <Icon fontSize="small">psychology_outlined</Icon>{" "}
            <CustomHidden only={["xs"]}>{lang("ucfirst")("G_MODEL")}</CustomHidden>
          </MDTypography>
        </Tab>
        <Tab>
          <MDTypography
            variant="h6"
            fontWeight="regular"
            sx={{ color: formErrors.includes("model_tools") ? "red" : "inherit" }}
          >
            <Icon fontSize="small">home_repair_service_outlined</Icon>{" "}
            <CustomHidden only={["xs"]}>{lang("ucfirst")("G_TOOLS")}</CustomHidden>
          </MDTypography>
        </Tab>
        <Tab>
          <MDTypography variant="h6" fontWeight="regular" color="inherit">
            <Icon fontSize="small">school_outlined</Icon>{" "}
            <CustomHidden only={["xs"]}>{lang("ucfirst")("G_KNOWLEDGE_BASE")}</CustomHidden>
          </MDTypography>
        </Tab>
        <Tab>
          <MDTypography variant="h6" fontWeight="regular" color="inherit">
            <Icon fontSize="small">settings_suggest_outlined</Icon>{" "}
            <CustomHidden only={["xs"]}>{lang("ucfirst")("G_ADVANCED")}</CustomHidden>
          </MDTypography>
        </Tab>
      </TabList>
      <Panel darkMode={darkMode}>
        <LoadingOverlay isLoading={isLoading}>
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              border: "10px",
            }}
          >
            <MDBox component="form" mt={1} onSubmit={handleSubmit}>
              {SnackbarComponent}
              <MDBox
                sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
              >
                <MDTypography variant="h5" fontWeight="regular" color="error" sx={{ opacity: 0.8 }}>
                  <Icon fontSize="medium" sx={{ transform: "translateY(4px)" }}>
                    {record ? "edit" : "add_circle_outline"}
                  </Icon>{" "}
                  {record
                    ? lang("ucwords")("CHAT_ASSISTANTS_EDIT_RECORD_TITLE")
                    : lang("ucwords")("CHAT_ASSISTANTS_NEW_RECORD_TITLE")}
                </MDTypography>
                {record && (
                  <CustomHidden only={["xs", "sm"]}>
                    <CustomBadge text={record.sid} lang={lang} darkMode={darkMode} />
                  </CustomHidden>
                )}
              </MDBox>
              <TabPanel>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={6}>
                    <MDBox mb={0} mt={2} sx={{ opacity: 0.8, marginBottom: -2 }}>
                      <MDInput
                        fullWidth
                        name="name"
                        label={`${lang("ucwords")("G_NAME")}*`}
                        value={formValues.name}
                        onChange={handleInputChange}
                        error={formErrors.includes("name")}
                        InputProps={{
                          endAdornment:
                            !formErrors.includes("name") &&
                            HelpToolTip(lang()("G_FORM_TIP_FIELD_NAME"), darkMode),
                        }}
                        sx={inputErrorSX(darkMode, formErrors.includes("name"))}
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <MDBox mb={0} sx={{ opacity: 0.8 }}>
                      <FormControl fullWidth margin="normal">
                        <InputLabel id="provider-type-label">Provider*</InputLabel>
                        <Select
                          name="model_provider"
                          labelId="provider-type-label"
                          value={formValues.model_provider}
                          onChange={handleInputChange}
                          open={selectOpen["model_provider"] || false}
                          onClose={() => closeSelect("model_provider")}
                          onOpen={() => openSelect("model_provider")}
                          variant="outlined"
                          margin="none"
                          sx={{ height: 43.5 }}
                          IconComponent={() =>
                            HelpToolTip(
                              lang()("CHAT_ASSISTANTS_FORM_TIP_PROVIDER"),
                              darkMode,
                              "select",
                              (event) => handleIconClick("model_provider", event)
                            )
                          }
                        >
                          {providerOptions}
                        </Select>
                      </FormControl>
                    </MDBox>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={6}>
                    <MDBox mb={1} mt={1} sx={{ opacity: 0.8 }}>
                      <MDInput
                        fullWidth
                        name="model_model"
                        label={`${lang("ucfirst")("G_MODEL")}*`}
                        value={formValues.model_model}
                        onChange={(e) => handleInputChange(e)}
                        InputProps={{
                          endAdornment:
                            !formErrors.includes("model_model") &&
                            HelpToolTip(lang()("G_FORM_TIP_FIELD_MODEL"), darkMode),
                        }}
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <MDBox
                      mb={1}
                      mt={0}
                      display="flex"
                      alignItems="center"
                      className="assistant-temperature-padding"
                    >
                      <MDTypography
                        variant="body1"
                        component="label"
                        mr={2}
                        style={{
                          color: darkMode ? "white" : "black",
                          fontSize: "14px",
                          opacity: 0.6,
                        }}
                      >
                        Temperature:
                      </MDTypography>
                      <InputRange
                        name="model_temperature"
                        aria-labelledby="Temperature"
                        maxValue={20}
                        minValue={0}
                        value={formValues.model_temperature}
                        step={1}
                        onChange={(value) =>
                          handleTemperatureChange({ name: "model_temperature", value })
                        }
                        formatLabel={(value) => ""}
                      />
                      <MDTypography
                        variant="body1"
                        component="label"
                        ml={1}
                        style={{ color: darkMode ? "white" : "black", fontSize: "14px" }}
                      >
                        {formValues.model_correctTemperatureValue}
                      </MDTypography>
                      <Tooltip
                        title={lang()("CHAT_ASSISTANTS_FORM_TIP_TEMPERATURE")}
                        arrow
                        placement="top"
                        enterDelay={500}
                        leaveDelay={200}
                        PopperProps={{
                          sx: {
                            "& .MuiTooltip-tooltip": {
                              textAlign: "left", // Align text to the left
                              //maxWidth: "none", // Optional: adjust max width if needed
                            },
                          },
                        }}
                      >
                        <Icon
                          sx={{
                            color: darkMode ? "#fff" : "black",
                            fontSize: "18px !important",
                            margin: "0 10px !important",
                            //paddingRight: "5px !important",
                            //opacity: 0.6,
                          }}
                        >
                          help_outline
                        </Icon>
                      </Tooltip>
                    </MDBox>
                  </Grid>
                </Grid>
                <MDBox mb={0} mt={1} sx={{ opacity: 0.8 }}>
                  {defaultConfigElement()}
                  <MDBox
                    component="span"
                    sx={{
                      position: "absolute",
                      bottom: "90px",
                      left: "50%",
                      transform: "translateX(-50%)",
                      color: darkMode ? "lightgrey !important" : "white !important",
                      cursor: "pointer",
                      textDecoration: "none",
                      padding: "2px 10px",
                      borderRadius: "16px",
                      transition: "background-color 0.3s ease, color 0.3s ease",
                      backgroundColor: "black !important",
                      fontSize: "12px",
                      "&:hover": {
                        backgroundColor: "#2C2C2C !important",
                      },
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenTextareaFullscreen(true);
                    }}
                  >
                    <MDTypography
                      variant="body2"
                      fontWeight="regular"
                      color="inherit"
                      sx={{ fontSize: "14px" }}
                    >
                      <Icon sx={{ fontSize: "14px" }}>fullscreen</Icon>{" "}
                      {lang("ucfirst")("G_FULLSCREEN")}
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </TabPanel>
              <TabPanel>
                <MDBox mt={1}>
                  <MDBox display="flex" alignItems="center">
                    <label
                      htmlFor="default-config-textarea"
                      style={{
                        color: darkMode ? "white" : "black",
                        display: "block",
                        opacity: 0.6,
                        marginRight: "8px",
                        fontSize: "0.875rem",
                        flex: "1",
                      }}
                    >
                      {lang("ucfirst")("CHAT_ASSISTANTS_TOOLS_PLACEHOLDER")}
                    </label>
                    {HelpToolTip(lang()("CHAT_ASSISTANTS_TIP_FIELD_TOOLS"), darkMode, "textarea")}
                  </MDBox>
                  <TextareaAutosize
                    name="model_tools"
                    minRows={8}
                    maxRows={8}
                    aria-label="Model Tools"
                    placeholder={lang()("CHAT_ASSISTANTS_LABEL_FIELD_TOOLS")}
                    value={formValues.model_tools}
                    onChange={handleInputChange}
                    style={{
                      outline: "none",
                      width: "100%",
                      height: "350px !important",
                      padding: "12px",
                      fontSize: "0.9rem",
                      resize: "none",
                      overflow: "auto",
                      scrollbarWidth: "thin",
                      //minHeight: "80px",
                      color: darkMode ? "white" : "black",
                      backgroundColor: "transparent",
                      borderRadius: "8px",
                      opacity: 0.8,
                      borderColor: formErrors.includes("model_tools")
                        ? "red"
                        : darkMode
                          ? "rgba(255, 255, 255, 0.5)"
                          : "rgb(12 10 10 / 12%)",
                    }}
                  />
                </MDBox>
              </TabPanel>
              <TabPanel>
                <MDBox mt={10} sx={{ height: 251 }}>
                  <MDBox display="flex" alignItems="center">
                    <label
                      htmlFor="default-config-textarea"
                      style={{
                        color: darkMode ? "white" : "black",
                        display: "block",
                        opacity: 0.6,
                        marginRight: "8px", // Space between label and icon
                        fontSize: "0.875rem", // Adjust font size as needed
                        flex: "1", // Ensures label takes available space
                      }}
                    >
                      {lang("ucfirst")("CHAT_ASSISTANTS_KNOWLEDGE_BASE_FILES_PLACEHOLDER")}
                    </label>
                    {HelpToolTip(
                      lang()("CHAT_ASSISTANTS_TIP_FIELD_KNOWLEDGE_BASE"),
                      darkMode,
                      "textarea"
                    )}
                  </MDBox>
                  <ReactSelect
                    name="knowledge_base_files"
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    value={formValues.knowledge_base_files}
                    onChange={handleKnowledgeBaseSelectorChange}
                    options={knowledgeBaseFilesOptions}
                    isMulti={true}
                    menuPortalTarget={document.body}
                    placeholder={lang("ucfirst")("G_CHOOSE_FILES")}
                    noOptionsMessage={(obj) => lang("ucfirst")("G_NOFILES")}
                    classNamePrefix="react-select"
                    styles={{
                      menu: (baseStyles, state) => ({
                        ...baseStyles,
                        backgroundColor: darkMode
                          ? myTheme.palette.background.card
                          : myTheme.palette.background.default,
                        color: darkMode ? "white" : "black",
                        zIndex: "9999 !important",
                        fontSize: 18,
                      }),
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        backgroundColor: "transparent",
                        color: "grey",
                        borderRadius: "8px",
                        opacity: 0.8,
                        fontSize: 18,
                        padding: 5,
                      }),
                      option: (baseStyles, state) => ({
                        ...baseStyles,
                        backgroundColor: state.isFocused
                          ? darkMode
                            ? myTheme.palette.dark.main
                            : "lightgrey"
                          : "transparent", // Hover color
                        color: darkMode ? "#fff" : "#000", // Text color
                        ":hover": {
                          backgroundColor: darkMode ? myTheme.palette.dark.main : "lightgrey", // Hover effect
                        },
                        margin: "5px",
                        opacity: darkMode ? 0.8 : 0.5,
                        borderRadius: "8px",
                        width: "auto",
                      }),
                      multiValue: (baseStyles, state) => ({
                        ...baseStyles,
                        backgroundColor: darkMode ? myTheme.palette.dark.main : "black",
                        borderRadius: "8px",
                      }),
                      multiValueLabel: (baseStyles, state) => ({
                        ...baseStyles,
                        color: "white",
                      }),
                    }}
                  />
                </MDBox>
              </TabPanel>
              <TabPanel>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    height: { xs: "auto", sm: "auto", md: "auto", lg: "347px", xl: "347px" },
                  }}
                >
                  <Grid item xs={12} lg={6}>
                    <MDBox mb={0} mt={2} sx={{ opacity: 0.8 }}>
                      <MDInput
                        fullWidth
                        name="model_max_history"
                        label={`${lang("ucwords")("G_MAX_HISTORY")}*`}
                        type="number"
                        value={formValues.model_max_history}
                        onChange={handleInputChange}
                        /*onBlur={() => {
                          // Log the current value when the input field loses focus
                          console.log(
                            "Current model_max_history value:",
                            formValues.model_max_history
                          );
                        }}*/
                        error={formErrors.includes("model_max_history")}
                        InputProps={{
                          endAdornment: (
                            <MDBox display="flex" alignItems="center">
                              <MDBox
                                display="flex"
                                flexDirection="column"
                                mr={1}
                                sx={{ height: "100%", justifyContent: "space-between" }}
                              >
                                <IconButton
                                  size="small"
                                  onClick={() =>
                                    handleInputChange({
                                      target: {
                                        name: "model_max_history",
                                        value: Math.min(
                                          parseInt(formValues.model_max_history) + 1,
                                          assistantFormDefaults.max_history
                                        ),
                                      },
                                    })
                                  }
                                  sx={{ padding: 0, color: "secondary.main" }}
                                >
                                  <Icon fontSize="small">arrow_drop_up</Icon>
                                </IconButton>
                                <IconButton
                                  size="small"
                                  onClick={() =>
                                    handleInputChange({
                                      target: {
                                        name: "model_max_history",
                                        value: Math.max(
                                          parseInt(formValues.model_max_history) - 1,
                                          0
                                        ),
                                      },
                                    })
                                  }
                                  sx={{ padding: 0, color: "secondary.main" }}
                                >
                                  <Icon fontSize="small">arrow_drop_down</Icon>
                                </IconButton>
                              </MDBox>
                              {/* Help Tooltip */}
                              {!formErrors.includes("model_max_history") &&
                                HelpToolTip(lang()("G_FORM_TIP_FIELD_MAX_HISTORY"), darkMode)}
                            </MDBox>
                          ),
                          inputProps: {
                            min: 0,
                            max: assistantFormDefaults.max_tokens,
                            style: { MozAppearance: "textfield" }, // Hides arrows in Firefox
                          },
                        }}
                        sx={{
                          ...inputErrorSX(darkMode, formErrors.includes("model_max_history")),
                          "& .MuiBox-root": {
                            marginRight: 0,
                          },
                          "& button": {
                            margin: "-4px",
                          },
                          "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                            {
                              WebkitAppearance: "none", // Hides arrows in Chrome, Safari
                            },
                        }}
                        InputLabelProps={{
                          shrink:
                            formValues.model_max_history !== null &&
                            formValues.model_max_history !== "", // Force label to shrink when value is set
                        }}
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <MDBox mb={2} mt={2} sx={{ opacity: 0.8 }}>
                      <MDInput
                        fullWidth
                        name="model_max_tokens"
                        label={`${lang("ucwords")("G_MAX_TOKENS")}`}
                        type="number"
                        value={formValues.model_max_tokens}
                        onChange={handleInputChange}
                        error={formErrors.includes("model_max_tokens")}
                        InputProps={{
                          endAdornment: (
                            <MDBox display="flex" alignItems="center">
                              <MDBox
                                display="flex"
                                flexDirection="column"
                                mr={1}
                                sx={{ height: "100%", justifyContent: "space-between" }}
                              >
                                <IconButton
                                  size="small"
                                  onClick={() =>
                                    handleInputChange({
                                      target: {
                                        name: "model_max_tokens",
                                        value: Math.min(
                                          parseInt(formValues.model_max_tokens) + 1,
                                          assistantFormDefaults.max_tokens
                                        ),
                                      },
                                    })
                                  }
                                  sx={{ padding: 0, color: "secondary.main" }} // Set custom color using `sx`
                                >
                                  <Icon fontSize="small">arrow_drop_up</Icon>
                                </IconButton>
                                <IconButton
                                  size="small"
                                  onClick={() =>
                                    handleInputChange({
                                      target: {
                                        name: "model_max_tokens",
                                        value: Math.max(
                                          parseInt(formValues.model_max_tokens) - 1,
                                          0
                                        ),
                                      },
                                    })
                                  }
                                  sx={{ padding: 0, color: "secondary.main" }} // Different color for down button
                                >
                                  <Icon fontSize="small">arrow_drop_down</Icon>
                                </IconButton>
                              </MDBox>
                              {!formErrors.includes("model_max_tokens") &&
                                HelpToolTip(lang()("G_FORM_TIP_FIELD_MAX_TOKEN"), darkMode)}
                            </MDBox>
                          ),
                          inputProps: {
                            min: 0,
                            max: assistantFormDefaults.max_tokens,
                            style: { MozAppearance: "textfield" },
                          },
                        }}
                        sx={{
                          ...inputErrorSX(darkMode, formErrors.includes("model_max_tokens")),
                          "& .MuiBox-root": {
                            marginRight: 0,
                          },
                          "& button": {
                            margin: "-4px",
                          },
                          "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                            {
                              WebkitAppearance: "none",
                            },
                        }}
                        InputLabelProps={{
                          shrink:
                            formValues.model_max_tokens !== null &&
                            formValues.model_max_tokens !== "",
                        }}
                      />
                    </MDBox>
                  </Grid>
                </Grid>
              </TabPanel>
              <MDBox mt={2}>
                <MDButton
                  type="submit"
                  color={mainColor}
                  variant="gradient"
                  fullWidth
                  disabled={
                    !areRequiredFieldsFilled(requiredFields, formValues) ||
                    !hasChanges(formValues, initialValues) ||
                    formErrors.length > 0
                  }
                >
                  {lang()("G_SAVE")}
                </MDButton>
              </MDBox>
            </MDBox>
          </CardContent>
        </LoadingOverlay>
      </Panel>
    </Tabs>
  );
};

LoadingOverlay.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  children: PropTypes.node,
};

RecordForm.propTypes = {
  record: PropTypes.shape({
    name: PropTypes.string,
    model: PropTypes.shape({
      provider: PropTypes.shape({
        sid: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }),
      model: PropTypes.string,
      temperature: PropTypes.number,
      system_prompt: PropTypes.string,
      knowledge_base: PropTypes.arrayOf(PropTypes.string),
      tools: PropTypes.arrayOf(PropTypes.object),
      max_tokens: PropTypes.number,
      max_history: PropTypes.number,
    }),
    sid: PropTypes.string,
  }),
  onEdit: PropTypes.func.isRequired,
  darkMode: PropTypes.bool.isRequired,
  mainColor: PropTypes.string.isRequired,
  promptTemplate: PropTypes.number,
};

export default RecordForm;
