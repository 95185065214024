import React, { useState } from "react";
import { useMaterialUIController } from "context";
import { useSnackbar, lang } from "hooks";
import { useApiKeyServices } from "services";
import { Icon } from "@mui/material";
import { DashboardLayout, DashboardNavbar, MDButton } from "components";
import ListRecords from "./vi.list";
import RecordDetails from "./vi.details";

const ApiKeys = () => {
  const [controller] = useMaterialUIController();
  const { sidenavColor } = controller;
  const [currentView, setCurrentView] = useState("list");
  const [currentRecord, setCurrentRecord] = useState(null);
  const [records, setRecords] = useState([]);
  const { showSnackbar, SnackbarComponent } = useSnackbar();
  const { deleteApiKey } = useApiKeyServices();

  const handleGridEditBtn = (sid) => {
    if (sid) {
      const record = records.find((p) => p.sid === sid);
      setCurrentRecord(record);
    } else {
      setCurrentRecord(null);
    }
    setCurrentView("details");
  };

  const handleDelete = {
    action: async ({ sid }) => {
      await deleteApiKey({ sid });
    },
    callback: () => {
      setCurrentView("list");
      showSnackbar("success", lang()("G_GENERIC_FORM_DELETE_SUCCESS"));
    },
  };

  const handleEdit = (isNew = false) => {
    setCurrentView("list");
    if (isNew) {
      showSnackbar("success", lang()("G_GENRIC_FORM_ADD_SUCCESS"));
    } else {
      showSnackbar("success", lang()("G_GENRIC_FORM_EDIT_SUCCESS"));
    }
  };

  const goBack = () => {
    setCurrentView("list");
    setCurrentRecord(null); // Clear selected provider ID when going back
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {SnackbarComponent}
      {currentView === "list" ? (
        <div>
          <MDButton
            color={sidenavColor}
            variant="gradient"
            onClick={() => handleGridEditBtn(null)}
            style={{ display: "flex", alignItems: "center", margin: "15px 0px" }}
          >
            {lang()("API_KEY_ADD_BTN")}
          </MDButton>
          <ListRecords onEdit={handleGridEditBtn} records={records} setRecords={setRecords} />
        </div>
      ) : (
        <div>
          <MDButton
            color="error"
            variant="text"
            onClick={goBack}
            style={{
              display: "flex",
              alignItems: "center",
              margin: "15px 0px",
              paddingLeft: "10px",
            }}
          >
            <Icon style={{ marginRight: "4px" }}>arrow_back</Icon>
            {lang()("G_GO_BACK")}
          </MDButton>
          <RecordDetails record={currentRecord} onDelete={handleDelete} onEdit={handleEdit} />
        </div>
      )}
    </DashboardLayout>
  );
};

export default ApiKeys;
