import React from "react";
import { Grid, Dialog, DialogContent, DialogTitle, IconButton, Icon } from "@mui/material";
import PropTypes from "prop-types";
import theme from "assets/theme";
import themeDark from "assets/theme-dark";
import { MDBox } from "components";

const EnlargeTextareaDialog = ({ open, onClose, darkMode, element, elementParam }) => {
  const myTheme = darkMode ? themeDark : theme;

  return (
    <Dialog
      fullWidth={true}
      maxWidth="xxl"
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: {
          backgroundColor: myTheme.palette.background.card,
          color: darkMode ? myTheme.palette.dark.main : myTheme.palette.white.main,
          //padding: "5px 20px",
          borderRadius: "16px",
        },
      }}
    >
      <DialogTitle sx={{ padding: "2px 5px" }}>
        <MDBox display="flex" alignItems="center">
          <MDBox flexGrow={1}> </MDBox>
          <MDBox>
            <IconButton onClick={onClose} color={darkMode ? "white" : "black"}>
              <Icon>close</Icon>
            </IconButton>
          </MDBox>
        </MDBox>
      </DialogTitle>
      <DialogContent>
        <Grid item xs={12}>
          {element && React.cloneElement(element, elementParam)}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

EnlargeTextareaDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  darkMode: PropTypes.bool.isRequired,
  element: PropTypes.element.isRequired,
  elementParam: PropTypes.any,
};

export default EnlargeTextareaDialog;
