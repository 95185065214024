import React, { useEffect, useMemo, useState, useRef } from "react";
import { useLocation, NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import { MDBox, MDButton } from "components";
import MDTypography from "components/MDTypography";
import SidenavCollapse from "components/Sidenav/SidenavCollapse";
import SidenavRoot from "components/Sidenav/SidenavRoot";
import sidenavLogoLabel from "components/Sidenav/styles/sidenav";
import {
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
} from "context";
import { useUserContext } from "context/UserContext";
import theme from "assets/theme";
import themeDark from "assets/theme-dark";

import { useChat } from "context/ChatContext";

function Sidenav({ color, brand, brandName, routes, ...rest }) {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, sidenavColor, darkMode } = controller;
  const location = useLocation();
  const collapseName = location.pathname.replace("/", "");
  const { userData, isSuperAdmin } = useUserContext();
  const myTheme = darkMode ? themeDark : theme;
  const [isSidenavOpen, setSidenavOpen] = useState(true);
  const [isMiniSideBar, setIsMiniSideBar] = useState(true);
  const isSuperAdminUser = useMemo(() => isSuperAdmin(), [userData]);
  const isChildRouteRef = useRef(false);
  const [openSection, setOpenSection] = useState("");
  const sidenavRootRef = useRef(null);

  const { openChatEl } = useChat();

  const matchRoute = (routes) => {
    const currentPath = location.pathname;
    for (let route of routes) {
      if (route && typeof route === "object") {
        if ("route" in route) {
          if (route.route === currentPath) {
            console.log("Exact route match:", route.key);
            return route.key;
          }

          if (route.children) {
            const childMatch = matchRoute(route.children);
            if (childMatch) {
              console.log("Child route match:", route.key);
              setOpenSection(route.key); // This sets the section as open
              isChildRouteRef.current = true;
              return route.key;
            }
          }
        }
      }
    }
    return null;
  };

  useEffect(() => {
    const matchedRouteKey = matchRoute(routes);
  }, [routes]);

  useEffect(() => {
    const sidenavRootElement = sidenavRootRef.current;

    if (sidenavRootElement && openSection) {
      console.log("SidenavRoot is available in the DOM", openSection);
      const activeLink = document.querySelector(".MuiList-root");
      const element = document.querySelector(`.${openSection}-key`);

      if (element) {
        element.click();
        console.log("activeLink", activeLink, element);
      }
    }
  }, [sidenavRootRef.current]);

  let textColor = "white";
  let iconColor = "white";

  useEffect(() => {
    function handleMiniSidenav() {
      const shouldMiniSidenav = window.innerWidth < 1200;
      setIsMiniSideBar(shouldMiniSidenav);
      console.log("shouldMiniSidenav", shouldMiniSidenav);
      setMiniSidenav(dispatch, shouldMiniSidenav);
      setTransparentSidenav(dispatch, !shouldMiniSidenav && transparentSidenav);
      setWhiteSidenav(dispatch, !shouldMiniSidenav && whiteSidenav);
    }

    handleMiniSidenav();

    window.addEventListener("resize", handleMiniSidenav);

    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, transparentSidenav, whiteSidenav]);

  const closeSidenav = () => {
    setSidenavOpen(false);
    setMiniSidenav(dispatch, true);
  };

  const handleClick = (event, disabled) => {
    if (disabled) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }
    if (isMiniSideBar) {
      closeSidenav();
    }
  };

  // Define your functions
  const getBackgroundColor = (isActive, isHovered, colorType) => {
    const color =
      {
        success: myTheme.palette.success.main,
        info: myTheme.palette.info.main,
        error: myTheme.palette.error.main,
        warning: myTheme.palette.warning.main,
        primary: myTheme.palette.primary.main,
        secondary: myTheme.palette.secondary.main,
        light: myTheme.palette.dark.main,
        dark: myTheme.palette.light.main,
      }[colorType] || "transparent";

    return isActive ? color : isHovered ? `${color}1a` : "transparent";
  };

  const renderRoutes = (routes, isChild = false) =>
    routes.map(
      ({
        type,
        name,
        icon,
        title,
        noCollapse,
        key,
        href,
        route,
        disabled,
        children,
        external = false,
        onClick = null,
      }) => {
        if (key === "legacy" && !isSuperAdminUser) {
          return null;
        }

        if (type === "collapse") {
          return (
            <SidenavCollapse
              key={key}
              name={<div className="collapse-menu-section">{name}</div>}
              //name={React.cloneElement(name, { className: "collapse-menu-section" })}
              icon={icon}
              active={key === collapseName}
              noCollapse={noCollapse}
              className={`collapse-menu-section ${key}-key`}
              style={{
                listStyle: "none",
                margin: "3px 10px",
                padding: "10px",
                fontSize: "1.5rem",
                //fontWeight: "bold",
              }}
            >
              {children && renderRoutes(children, true)}
            </SidenavCollapse>
          );
        } else if (type === "title") {
          return (
            <MDTypography
              key={key}
              color={textColor}
              display="block"
              variant="caption"
              fontWeight="bold"
              textTransform="uppercase"
              pl={3}
              mt={2}
              mb={1}
              ml={1}
            >
              {title}
            </MDTypography>
          );
        } else if (type === "divider") {
          return (
            <Divider
              key={key}
              light={
                (!darkMode && !whiteSidenav && !transparentSidenav) ||
                (darkMode && !transparentSidenav && whiteSidenav)
              }
            />
          );
        } else if (type === "link") {
          const [isHovered, setIsHovered] = useState(false);

          return (
            <li key={key} style={{ listStyle: "none", margin: 0, padding: 0 }}>
              <NavLink
                to={route || href}
                onClick={
                  onClick
                    ? (event) => {
                        event.preventDefault();
                        if (onClick === "openChatEl") {
                          openChatEl();
                        } else {
                          console.error(`onClick ${onClick} is not a function`);
                        }
                      }
                    : (event) => handleClick(event, disabled)
                }
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                target={external ? "_blank" : undefined}
                style={({ isActive }) => ({
                  textDecoration: "none",
                  color: textColor,
                  backgroundColor: getBackgroundColor(isActive, isHovered, sidenavColor),
                  display: "block",
                  padding: "8px 16px",
                  margin: isChild ? "0 10px 5px 10px" : "0 10px",
                  borderRadius: "8px",
                  transition: "background-color 0.3s ease",
                })}
                className={external || onClick ? "external-link" : undefined}
              >
                <MDBox display="flex" alignItems="center">
                  {icon && (
                    <MDBox mr={2}>
                      <Icon
                        sx={{ color: "white !important" }}
                        className={external || onClick ? "external-link" : undefined}
                      >
                        {React.cloneElement(icon, {
                          className: external || onClick ? "external-link" : undefined,
                        })}
                      </Icon>
                    </MDBox>
                  )}
                  <MDTypography
                    variant="body2"
                    sx={{ color: "white !important", fontSize: "0.9rem" }}
                    className={external || onClick ? "external-link" : undefined}
                  >
                    {name}
                  </MDTypography>
                </MDBox>
              </NavLink>
            </li>
          );
        }

        return null;
      }
    );

  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
      ref={sidenavRootRef}
    >
      <MDBox pt={3} pb={1} px={4} textAlign="center">
        <MDBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <MDTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          </MDTypography>
        </MDBox>
        <MDBox component={NavLink} to="/" display="flex" alignItems="center">
          {brand && <MDBox component="img" src={brand} alt="Brand" width="2rem" />}
          <MDBox
            width={!brandName && "100%"}
            sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
          >
            <MDTypography component="h6" variant="button" fontWeight="medium" color={textColor}>
              {brandName}
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
      <Divider
        light={
          (!darkMode && !whiteSidenav && !transparentSidenav) ||
          (darkMode && !transparentSidenav && whiteSidenav)
        }
      />
      <List>
        {renderRoutes(routes).map((item, index) => (
          <React.Fragment key={index}>{item}</React.Fragment>
        ))}
      </List>
    </SidenavRoot>
  );
}

Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(["collapse", "title", "divider", "link"]),
      name: PropTypes.string,
      icon: PropTypes.node,
      title: PropTypes.string,
      noCollapse: PropTypes.bool,
      key: PropTypes.string.isRequired,
      href: PropTypes.string,
      route: PropTypes.string,
      disabled: PropTypes.bool,
      children: PropTypes.array,
      external: PropTypes.bool,
      onClick: PropTypes.func,
    })
  ).isRequired,
};

export default Sidenav;
