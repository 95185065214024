import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { Call } from "@mui/icons-material";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { testConversation, backendAddress } from "configDefaults";
import axios from "axios";
import { useMaterialUIController } from "context";

import io from "socket.io-client";

const GreenRoundButton = styled(Button)(({ theme }) => ({
  backgroundColor: "green",
  color: "white",
  borderRadius: "50%",
  width: "100px",
  height: "100px",
  padding: theme.spacing(2),
  "&:hover": {
    backgroundColor: "darkgreen",
  },
}));

const RedRoundButton = styled(Button)(({ theme }) => ({
  backgroundColor: "red",
  color: "white",
  borderRadius: "50%",
  width: "100px",
  height: "100px",
  padding: theme.spacing(2),
  "&:hover": {
    backgroundColor: "darkred",
  },
}));

const StyledCard = styled(Card)(({ theme }) => ({
  margin: theme.spacing(2),
  padding: theme.spacing(2),
}));

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const TestCall = () => {
  const outputRef = useRef(null);
  const [isCalling, setIsCalling] = useState(false);
  const [combinedAudioUrl, setCombinedAudioUrl] = useState("");
  const [formVisible, setFormVisible] = useState(false);
  const [debugLevel, setDebugLevel] = useState(0);
  const formDefaults = testConversation.formDefaults;
  const enginesDefaults = testConversation.enginesDefaults;
  const [formData, setFormData] = useState({
    systemMessage: formDefaults.systemMessage,
    maxHistory: formDefaults.maxHistory,
    selectSTTSource: formDefaults.selectSTTSource,
    selectLLMSource: formDefaults.selectLLMSource,
    selectTTSSource: formDefaults.selectTTSSource,
    selectVCSource: formDefaults.selectVCSource,
    llmConfig: formDefaults.llmConfig,
    fcConfig: formDefaults.fcConfig,
    ttsConfig: formDefaults.ttsConfig,
    sttConfig: formDefaults.sttConfig,
    vcConfig: formDefaults.vcConfig,
    debugLevel: testConversation.debugLevel,
  });
  const [, forceUpdate] = useState(false);
  const [selectedSTTSource, setSelectedSTTSource] = useState(formDefaults.selectSTTSource);
  const [selectedLLMSource, setSelectedLLMSource] = useState(formDefaults.selectLLMSource);
  const [selectedTTSSource, setSelectedTTSSource] = useState(formDefaults.selectTTSSource);
  const [selectedVCSource, setSelectedVCSource] = useState(formDefaults.selectVCSource);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [downloadButton, setDownloadButton] = useState(false);

  const handleChangeSTTSource = (event) => {
    setSelectedSTTSource(event.target.value);
    handleInputChange(event);
  };
  const handleChangeLLMSource = (event) => {
    setSelectedLLMSource(event.target.value);
    handleInputChange(event);
  };
  const handleChangeTTSSource = (event) => {
    setSelectedTTSSource(event.target.value);
    handleInputChange(event);
  };

  const handleChangeVCSource = (event) => {
    setSelectedVCSource(event.target.value);
    handleInputChange(event);
  };

  const handleClearOutput = () => {
    if (outputRef.current) {
      outputRef.current.value = "";
    }
    setCombinedAudioUrl("");
    setDownloadButton(false);
  };

  const handleDownload = () => {
    if (combinedAudioUrl) {
      const link = document.createElement("a");
      link.href = combinedAudioUrl;
      link.download = "combined_audio.webm"; // Adjust filename and extension as needed
      link.click();
    }
  };

  useEffect(() => {
    if (combinedAudioUrl) {
      handleDownload();
      setCombinedAudioUrl("");
    }
  }, [combinedAudioUrl]);

  useEffect(() => {
    const socket = io();

    socket.on("connect", () => {
      console.log("Connected to server");
    });

    socket.on("event_log", (data) => {
      updateOutput(data.message, data.debugLevel);
      if (data.ended) {
        setIsCalling(false);
      }
    });

    socket.on("disconnect", () => {
      console.log("Disconnected from server");
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    /*if (name == "systemMessage") {
      debounceUpdateConversationHistory(value);
    } else*/ if (name == "debugLevel") {
      setDebugLevel(parseInt(value));
    } else if (name == "selectSTTSource") {
      let sttConfig = null;
      switch (value) {
        case "openai":
          sttConfig = enginesDefaults.openAISTTConfig;
          break;
        case "fast-whisper":
          sttConfig = enginesDefaults.fwSTTConfig;
          break;
        default:
          sttConfig = enginesDefaults.openAISTTConfig;
      }
      setFormData((prevFormData) => ({
        ...prevFormData,
        sttConfig: sttConfig,
      }));
    } else if (name == "selectLLMSource") {
      let llmConfig = null;
      let fcConfig = null;
      switch (value) {
        case "openai":
          llmConfig = enginesDefaults.openAILLMConfig;
          fcConfig = enginesDefaults.openAIFCConfig;
          break;
        case "ollama":
          llmConfig = enginesDefaults.ollamaLLMConfig;
          fcConfig = enginesDefaults.ollamaFCConfig;
          break;
        case "lmstudio":
          llmConfig = enginesDefaults.lmStudioLLMConfig;
          fcConfig = enginesDefaults.lmStudioFCConfig;
          break;
        default:
          llmConfig = enginesDefaults.openAILLMConfig;
          fcConfig = enginesDefaults.openAIFCConfig;
      }
      setFormData((prevFormData) => ({
        ...prevFormData,
        llmConfig: llmConfig,
        fcConfig: fcConfig,
      }));
    } else if (name == "selectTTSSource") {
      let ttsConfig = null;
      switch (value) {
        case "openai":
          ttsConfig = enginesDefaults.openAITTSConfig;
          break;
        case "openvoicev1":
          ttsConfig = enginesDefaults.openVoiceV1Config;
          break;
        case "openvoicev2":
          ttsConfig = enginesDefaults.openVoiceV2Config;
          break;
        case "elevenlabs":
          ttsConfig = enginesDefaults.elevenLabsTTSConfig;
          break;
        default:
          ttsConfig = enginesDefaults.openAITTSConfig;
      }
      setFormData((prevFormData) => ({
        ...prevFormData,
        ttsConfig: ttsConfig,
      }));
    } else if (name == "selectVCSource") {
      let vcConfig = null;
      switch (value) {
        case "openvoicev1":
          vcConfig = enginesDefaults.openVoiceV1VCConfig;
          break;
        case "openvoicev2":
          vcConfig = enginesDefaults.openVoiceV2VCConfig;
          break;
        //case "elevenlabs":
        //  vcConfig = enginesDefaults.elevenLabsTTSConfig;
        //  break;
        default:
          vcConfig = enginesDefaults.defaultVCConfig;
      }
      setFormData((prevFormData) => ({
        ...prevFormData,
        vcConfig: vcConfig,
      }));
    }
  };

  const toggleFormVisibility = () => setFormVisible(!formVisible);
  const toggleCall = () => setIsCalling(!isCalling);

  useEffect(() => {
    console.log(isCalling);
    if (isCalling) {
      startCall();
    } else {
      //stopMic();
      setIsCalling(false);
      console.log("STOPPED");
    }
  }, [isCalling]);

  const startCall = async () => {
    const number = prompt("Enter phone number, Ex. +34626456473");
    if (number) {
      setIsCalling(true);
      updateOutput("Testing configuration params", 0);
      try {
        updateOutput("Testing STT data", 1);
        const sendData = new FormData();
        //sendData.append("audio", blob, "test_audio.webm"); // Adjust file name and type as needed
        const sttSource = formData.selectSTTSource;
        const sttConfigObject = JSON.parse(formData.sttConfig);
        const url = backendAddress + "/speech-to-text/" + sttSource;
        const params = new URLSearchParams(sttConfigObject);
        params.forEach((value, key) => {
          sendData.append(key, value);
        });
        sendData.append("test", true);
        const response = await fetch(url, {
          method: "POST",
          body: sendData,
        });
        const elapsed_time = response.headers.get("X-Elapsed-Time");
        const formatted_time = parseFloat(elapsed_time).toFixed(4);
        const data = await response.json();
        let message = "";
        if (response.status === 200) {
          message = data["result"]["data"].text;
          updateOutput("(" + formatted_time + "ms) STT result: " + message, 2);
        } else {
          message = response.status + " " + data["result"].message;
          setIsCalling(false);
          updateOutput("(" + formatted_time + "ms) STT result: " + message, 0);
          return;
        }
      } catch (error) {
        let errorMessage = "Error getting STT data";
        if (error.response) {
          const elapsed_time = error.response.headers.get("X-Elapsed-Time");
          const formatted_time = parseFloat(elapsed_time).toFixed(4);
          errorMessage = "(" + formatted_time + "ms) " + errorMessage;
          errorMessage += ": " + error.response.data.result.message;
        } else {
          errorMessage += ": " + error.message;
        }
        updateOutput(errorMessage, 0);
        setIsCalling(false);
        return;
      }
      try {
        updateOutput("Testing LLM data", 1);
        const llmSource = formData.selectLLMSource;
        const llmConfigObject = JSON.parse(formData.llmConfig);
        const url = backendAddress + "/chat/" + llmSource;
        const params = {
          __conversation_history: [{ role: "system", content: formData.systemMessage }],
          __message: "Hi, how are you?",
          __max_conversation_history: 10,
          ...llmConfigObject,
        };
        if (formData.fcConfig) {
          const fcConfig = JSON.parse(formData.fcConfig);
          params["tools"] = fcConfig;
        }
        const response = await axios.post(url, params);
        const elapsed_time = response.headers.get("X-Elapsed-Time");
        const formatted_time = parseFloat(elapsed_time).toFixed(4);
        const data = await response.data;
        let response_message = "";
        if (response.status == 200) {
          response_message = data["result"]["data"].response_message;
          updateOutput("(" + formatted_time + "ms) LLM result: " + response_message, 2);
        } else {
          response_message = response.status + " " + data["result"].message;
          updateOutput("(" + formatted_time + "ms) LLM result: " + response_message, 0);
          setIsCalling(false);
          return;
        }
      } catch (error) {
        let errorMessage = "Error getting LLM data";
        if (error.response) {
          const elapsed_time = error.response.headers.get("X-Elapsed-Time");
          const formatted_time = parseFloat(elapsed_time).toFixed(4);
          errorMessage = "(" + formatted_time + "ms) " + errorMessage;
          errorMessage += ": " + error.response.data.result.message;
        } else {
          errorMessage += ": " + error.message;
        }
        updateOutput(errorMessage, 0);
        setIsCalling(false);
        return;
      }
      try {
        updateOutput("Testing TTS data", 1);
        const url = backendAddress + "/text-to-speech/" + formData.selectTTSSource;
        const params = {
          input: "Hi, how are you?",
          ...JSON.parse(formData.ttsConfig),
        };
        const response = await axios.post(url, params);
        const elapsed_time = response.headers.get("X-Elapsed-Time");
        const formatted_time = parseFloat(elapsed_time).toFixed(4);
        const data = await response.data;
        let response_message = "";
        if (response.status == 200) {
          response_message = response.status + " " + data["result"].message;
          updateOutput("(" + formatted_time + "ms) TTS result: " + response_message, 2);
        } else {
          response_message = response.status + " " + data["result"].message;
          updateOutput("(" + formatted_time + "ms) TTS result: " + response_message, 0);
          setIsCalling(false);
          return;
        }
      } catch (error) {
        let errorMessage = "Error getting TTS data";
        if (error.response) {
          const elapsed_time = error.response.headers.get("X-Elapsed-Time");
          const formatted_time = parseFloat(elapsed_time).toFixed(4);
          errorMessage = "(" + formatted_time + "ms) " + errorMessage;
          errorMessage += ": " + error.response.data.result.message;
        } else {
          errorMessage += ": " + error.message;
        }
        updateOutput(errorMessage, 0);
        setIsCalling(false);
        return;
      }
      if (formData.selectVCSource != "none") {
        try {
          updateOutput("Testing SV data", 2);
          const sendData = new FormData();
          const vcSource = formData.selectVCSource;
          const vcConfigObject = JSON.parse(formData.vcConfig);
          const url = backendAddress + "/convert-voice/" + vcSource;
          const params = new URLSearchParams(vcConfigObject);
          params.forEach((value, key) => {
            sendData.append(key, value);
          });
          sendData.append("test", true);
          const response = await fetch(url, {
            method: "POST",
            body: sendData,
          });
          const elapsed_time = response.headers.get("X-Elapsed-Time");
          const formatted_time = parseFloat(elapsed_time).toFixed(4);
          const data = await response.json();
          let message = "";
          if (response.status === 200) {
            message = "Returned converted audio";
            updateOutput("(" + formatted_time + "ms) VC result: " + message, 1);
          } else {
            message = response.status + " " + data["result"].message;
            updateOutput("(" + formatted_time + "ms) VC result: " + message, 0);
            setIsCalling(false);
            return;
          }
        } catch (error) {
          let errorMessage = "Error getting VC data";
          if (error.response) {
            const elapsed_time = error.response.headers.get("X-Elapsed-Time");
            const formatted_time = parseFloat(elapsed_time).toFixed(4);
            errorMessage = "(" + formatted_time + "ms) " + errorMessage;
            errorMessage += ": " + error.response.data.result.message;
          } else {
            errorMessage += ": " + error.message;
          }
          updateOutput(errorMessage, 0);
          setIsCalling(false);
          return;
        }
      }
      try {
        const params = {
          to: number,
          ttsConfig: JSON.parse(formData.ttsConfig),
          sttConfig: JSON.parse(formData.sttConfig),
          llmConfig: JSON.parse(formData.llmConfig),
          ttsSource: formData.selectTTSSource,
          sttSource: formData.selectSTTSource,
          llmSource: formData.selectLLMSource,
          vcSource: formData.selectVCSource,
          systemMessage: formData.systemMessage,
          maxHistory: formData.maxHistory,
        };
        if (formData.selectVCSource != "none") {
          params["vcConfig"] = JSON.parse(formData.vcConfig);
        }
        const response = await axios.post(backendAddress + "/call", params);
        if (response.status == 200) {
          updateOutput("Call in progress", 0);
        }
      } catch (error) {
        let errorMessage = "Error making call";
        if (error.response) {
          const elapsed_time = error.response.headers.get("X-Elapsed-Time");
          const formatted_time = parseFloat(elapsed_time).toFixed(4);
          errorMessage = "(" + formatted_time + "ms) " + errorMessage;
          errorMessage += ": " + error.response.data.result.message;
        } else {
          errorMessage += ": " + error.message;
        }
        updateOutput(errorMessage, 0);
        setIsCalling(false);
        return;
      }
    } else {
      setIsCalling(false);
      return;
    }
  };

  const updateOutput = (text, debug = 0, output = "all") => {
    if (outputRef.current && debugLevel >= debug) {
      switch (debug) {
        case 0:
          text = "--- " + text + " ---\n";
          break;
        case 1:
          text = "=== " + text + " ===\n";
          break;
        case 2:
          text = ">>> " + text + " <<<\n";
          break;
        default:
          text = text + "\n";
          break;
      }
      if (output === "view" || output === "all") {
        outputRef.current.value += text;
        outputRef.current.scrollTop = outputRef.current.scrollHeight;
      }
      if (output === "console" || output === "all") {
        console.log(text);
      }
    }
  };

  const [value, setValue] = useState(0);

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container justifyContent="center" style={{ minHeight: "100vh" }}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <StyledCard>
            <Button onClick={toggleFormVisibility}>
              {formVisible ? "Hide Config" : "Show Config"}
            </Button>
            {formVisible && (
              <Box mt={2}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                  <Tab label="General" {...a11yProps(0)} />
                  <Tab label="Speech to Text" {...a11yProps(1)} sx={{ display: "none" }} disabled />
                  <Tab label="Language Model" {...a11yProps(2)} />
                  <Tab label="Text to Speech" {...a11yProps(3)} />
                  <Tab
                    label="Voice Converter"
                    {...a11yProps(4)}
                    sx={{ display: "none" }}
                    disabled
                  />
                </Tabs>
                <form id="conversationForm">
                  <CustomTabPanel value={value} index={0}>
                    <TextField
                      fullWidth
                      id="systemMessage"
                      name="systemMessage"
                      label="System Message"
                      value={formData.systemMessage}
                      onChange={handleInputChange}
                      multiline
                      rows={3}
                      variant="outlined"
                      margin="normal"
                    />
                    <TextField
                      fullWidth
                      id="debugLevel"
                      name="debugLevel"
                      label="Output Level"
                      type="number"
                      value={formData.debugLevel}
                      InputProps={{ inputProps: { min: 0, max: 2 } }}
                      onChange={handleInputChange}
                      variant="outlined"
                      margin="normal"
                    />
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={1}>
                    <InputLabel htmlFor="selectSTTSource">STT Source</InputLabel>
                    <Select
                      fullWidth
                      id="selectSTTSource"
                      name="selectSTTSource"
                      value={selectedSTTSource}
                      onChange={handleChangeSTTSource}
                      variant="outlined"
                      margin="none"
                      sx={{ minHeight: 40 }}
                    >
                      <MenuItem value="openai">OpenAI</MenuItem>
                      <MenuItem value="fast-whisper">Fast Whisper</MenuItem>
                      {/* Add more options as needed */}
                    </Select>
                    <TextField
                      fullWidth
                      id="sttConfig"
                      name="sttConfig"
                      label="STT Config"
                      value={formData.sttConfig}
                      onChange={handleInputChange}
                      multiline
                      rows={3}
                      variant="outlined"
                      margin="normal"
                    />
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={2}>
                    <InputLabel htmlFor="selectLLMSource">LLM Source</InputLabel>
                    <Select
                      fullWidth
                      id="selectLLMSource"
                      name="selectLLMSource"
                      value={selectedLLMSource}
                      onChange={handleChangeLLMSource}
                      variant="outlined"
                      margin="none"
                      sx={{ minHeight: 40 }}
                    >
                      <MenuItem value="openai">OpenAI</MenuItem>
                      <MenuItem value="ollama">Ollama</MenuItem>
                      <MenuItem value="lmstudio">LM Studio</MenuItem>
                      {/* Add more options as needed */}
                    </Select>
                    <TextField
                      fullWidth
                      id="llmConfig"
                      name="llmConfig"
                      label="LLM Config"
                      value={formData.llmConfig}
                      onChange={handleInputChange}
                      multiline
                      rows={3}
                      variant="outlined"
                      margin="normal"
                    />
                    <TextField
                      fullWidth
                      id="maxHistory"
                      name="maxHistory"
                      label="Conversation History"
                      type="number"
                      value={formData.maxHistory}
                      InputProps={{ inputProps: { min: 1, max: 1000 } }}
                      onChange={handleInputChange}
                      variant="outlined"
                      margin="normal"
                    />
                    <TextField
                      fullWidth
                      id="fcConfig"
                      name="fcConfig"
                      label="Functions"
                      value={formData.fcConfig}
                      onChange={handleInputChange}
                      multiline
                      minRows={3}
                      maxRows={20}
                      variant="outlined"
                      margin="normal"
                    />
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={3}>
                    <InputLabel htmlFor="selectTTSSource">TTS Source</InputLabel>
                    <Select
                      fullWidth
                      id="selectTTSSource"
                      name="selectTTSSource"
                      value={selectedTTSSource}
                      onChange={handleChangeTTSSource}
                      variant="outlined"
                      margin="none"
                      sx={{ minHeight: 40 }}
                    >
                      <MenuItem value="openai">OpenAI</MenuItem>
                      <MenuItem value="elevenlabs">ElevenLabs</MenuItem>
                      <MenuItem value="openvoicev1">OpenVoice V1</MenuItem>
                      <MenuItem value="openvoicev2">OpenVoice V2</MenuItem>
                    </Select>
                    <TextField
                      fullWidth
                      id="ttsConfig"
                      name="ttsConfig"
                      label="TTS Config"
                      value={formData.ttsConfig}
                      onChange={handleInputChange}
                      multiline
                      rows={3}
                      variant="outlined"
                      margin="normal"
                    />
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={4}>
                    <InputLabel htmlFor="selectTTSSource">Voice Converter</InputLabel>
                    <Select
                      fullWidth
                      id="selectVCSource"
                      name="selectVCSource"
                      value={formData.selectVCSource}
                      onChange={handleChangeVCSource}
                      variant="outlined"
                      margin="none"
                      sx={{ minHeight: 40 }}
                    >
                      <MenuItem value="none">None</MenuItem>
                      {/*<MenuItem value="elevenlabs">ElevenLabs</MenuItem>*/}
                      <MenuItem value="openvoicev1">OpenVoice V1</MenuItem>
                      <MenuItem value="openvoicev2">OpenVoice V2</MenuItem>
                    </Select>
                    <TextField
                      fullWidth
                      id="vcConfig"
                      name="vcConfig"
                      label="Voice Converter Config"
                      value={formData.vcConfig}
                      onChange={handleInputChange}
                      multiline
                      rows={3}
                      variant="outlined"
                      margin="normal"
                    />
                  </CustomTabPanel>
                </form>
              </Box>
            )}
            <Box textAlign="center">
              <TextareaAutosize
                ref={outputRef}
                minRows={10}
                aria-label="Output"
                placeholder="Output"
                style={{
                  border: "none",
                  outline: "none",
                  width: "100%",
                  height: "80px",
                  padding: "8px",
                  fontSize: "1rem",
                  resize: "vertical",
                  overflow: "auto",
                  minHeight: "80px",
                  color: darkMode ? "white" : "black",
                  backgroundColor: darkMode ? "#333" : "#fff",
                  //maxHeight: "600px",
                }}
                readOnly
              />
              <Button onClick={handleClearOutput}>Clear Output</Button>
            </Box>
          </StyledCard>
          <Grid container justifyContent="center" style={{ marginTop: "20px" }}>
            {isCalling ? (
              <RedRoundButton onClick={toggleCall}>
                <Call
                  style={{ fontSize: 50, color: "white" }}
                  ref={(el) => {
                    if (el) {
                      el.style.setProperty("font-size", "50px", "important");
                    }
                  }}
                />
              </RedRoundButton>
            ) : (
              <GreenRoundButton onClick={toggleCall}>
                <Call
                  style={{ fontSize: 50, color: "white" }}
                  ref={(el) => {
                    if (el) {
                      el.style.setProperty("font-size", "50px", "important");
                    }
                  }}
                />
              </GreenRoundButton>
            )}
          </Grid>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default TestCall;
