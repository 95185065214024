// configDefaults.js

export const backendAddress = "/api";

export const assistantFormDefaults = {
  max_tokens: 100000,
  max_history: 20,
};

export const validFileTypes = [
  "text/plain",
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];

export const providersConfigDefaults = {
  model: {
    default: { model: "please add your model here" },
    openai: { model: "gpt-4o-mini", temperature: 0.7 },
    anthropic: { model: "claude-3.5", temperature: 0.7 },
    ollama: { model: "llama3.1", temperature: 0.7 },
    cohere: { model: "embed-english-v3.0", temperature: 0.7 },
  },
  transcriber: {
    default: { model: "please add your transcriber here" },
    openai: { model: "whisper-1" },
  },
  addresses: {
    default: "",
    openai: "https://api.openai.com/v1",
    anthropic: "https://api.anthropic.com",
    cohere: "https://api.cohere.ai/v1",
  },
};

export const testConversationEnginesDefaults = {
  openAISTTConfig: `{ "model": "whisper-1" }`,
  fwSTTConfig: `{ "model": "small", "language": "en" }`,
  openAILLMConfig: `{ "model": "gpt-4o" }`,
  ollamaLLMConfig: `{ "model": "dolphin-llama3" }`,
  lmStudioLLMConfig: `{ "model": "local-model" }`,
  openAIFCConfig:
    '[{"type": "function", "function": {"name": "end_conversation", "description": "Execute this function when user says goodbye."}}, {"type": "function", "function": {"name": "get_current_weather", "description": "Get the current weather", "parameters": {"type": "object", "properties": {"location": {"type": "string", "description": "The city and state, e.g. San Francisco"}, "unit": {"type": "string", "enum": ["celsius", "fahrenheit"], "description": "The temperature unit to use. Infer this from the users location."}}, "required": ["location", "unit"]}}}]',
  ollamaFCConfig: "",
  lmStudioFCConfig: "",
  openAITTSConfig: `{ "voice": "alloy", "speed":"1.0", "model": "tts-1" }`,
  openVoiceV1Config: `{ "model": "en", "voice": "mark", "style": "default", "speed": "1.0" }`,
  openVoiceV2Config: `{ "model": "en_newest","voice": "mark", "accent": "en-newest", "speed": "1.0" }`,
  elevenLabsTTSConfig: `{ "model": "eleven_monolingual_v1", "voice": "Rachel" , "voice_settings": { "stability": 0.0, "similarity_boost": 1.0, "style": 0.0, "use_speaker_boost": false } }`,
  defaultVCConfig: "",
  openVoiceV1VCConfig: `{ "voice": "mark" }`,
  openVoiceV2VCConfig: `{ "voice": "mark" }`,
};

export const testConversationFormDefaults = {
  systemMessage: "Always talk in rhymes, use a maximum of 30 words with each reply.",
  systemMessageBotTwo: "Tell short poems, use a maximum of 30 words for each poem.",
  maxHistory: 10,
  selectSTTSource: "openai",
  selectLLMSource: "openai",
  selectTTSSource: "openai",
  selectVCSource: "none",
  sttConfig: testConversationEnginesDefaults.openAISTTConfig,
  llmConfig: testConversationEnginesDefaults.openAILLMConfig,
  fcConfig: testConversationEnginesDefaults.openAIFCConfig,
  ttsConfig: testConversationEnginesDefaults.openAITTSConfig,
  vcConfig: testConversationEnginesDefaults.defaultVCConfig,
};

export const testConversation = {
  debugLevel: 0,
  enginesDefaults: testConversationEnginesDefaults,
  formDefaults: testConversationFormDefaults,
};

export const embedDefaultMessages = {
  embed_assistant_id: "r9vYzZMAxHPu4PbqMa4WDEaguhx9RcuTc0weOikD0FJ7wVJLKw",
  start_message: "Hi, how can I help you?",
  placeholder_text: "Type your message here",
  error_message: "I am very sorry, but the network seems to be having problems at the moment.",
  position: "right",
  background_color: "None",
  icon_color: "None",
  el_prefix: "None",
  keep_session: true,
};

export const embedOptions = {
  iframe:
    '<iframe src="{{ base_url }}/iframe/{{ embed_sid }}?theme={{ theme }}" allow="clipboard-write; clipboard-read; *;microphone *" width="100%" height="100%"></iframe>',
  link: "{{ base_url }}/chat/{{ embed_sid }}?theme={{ theme }}",
  bubble: `<script>
    window.agentEmbed = {
        id: "{{ embed_sid }}", 
        theme: "{{ theme }}",
    }
</script>
<script src="{{ base_url }}/js/v1/bubble-embed.js"></script>`,
};

export const apiCodeBlocks = {
  curl: `curl -X 'POST' \\
  '{{ base_url }}/v1/assistants/completions/{{ assistant_id }}' \\
  -H 'accept: application/json' \\
  -H 'X-API-Key: {{ api_key }}' \\
  -H 'Content-Type: application/json' \\
  -d '{
  "message": "YOUR MESSAGE HERE"
}'`,
  python: `import requests

url = "{{ base_url }}/v1/assistants/completions/{{ assistant_id }}"
headers = {
    "accept": "application/json",
    "X-API-Key": "{{ api_key }}",
    "Content-Type": "application/json"
}
data = {
    "message": "YOUR MESSAGE HERE"
}

response = requests.post(url, headers=headers, json=data)
print(response.json())`,
  javascript: `const url = "{{ base_url }}/v1/assistants/completions/{{ assistant_id }}";

const headers = {
  "accept": "application/json",
  "X-API-Key": "{{ api_key }}",
  "Content-Type": "application/json"
};

const data = {
  "message": "YOUR MESSAGE HERE"
};

fetch(url, {
  method: "POST",
  headers: headers,
  body: JSON.stringify(data)
})
  .then(response => response.json())
  .then(data => console.log(data))
  .catch(error => console.error("Error:", error));
`,
  nodejs: `const fetch = require('node-fetch');

const url = "{{ base_url }}/v1/assistants/completions/{{ assistant_id }}";

const headers = {
  "accept": "application/json",
  "X-API-Key": "{{ api_key }}",
  "Content-Type": "application/json"
};

const data = {
  "message": "YOUR MESSAGE HERE"
};

fetch(url, {
  method: "POST",
  headers: headers,
  body: JSON.stringify(data)
})
  .then(response => response.json())
  .then(data => console.log(data))
  .catch(error => console.error("Error:", error));`,
  react: `import React, { useState, useEffect } from "react";

const ApiRequestComponent = () => {
  const [responseData, setResponseData] = useState(null);

  const sendRequest = async () => {
    const url = "{{ base_url }}/v1/assistants/completions/{{ assistant_id }}";
    const headers = {
      "accept": "application/json",
      "X-API-Key": "{{ api_key }}",
      "Content-Type": "application/json",
    };
    const data = {
      "message": "YOUR MESSAGE HERE",
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(data),
      });

      const result = await response.json();
      setResponseData(result);
      console.log("Response:", result);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    // Call sendRequest when the component mounts
    sendRequest();
  }, []);

  return (
    <div>
      <h1>API Response</h1>
      <pre>{JSON.stringify(responseData, null, 2)}</pre>
    </div>
  );
};

export default ApiRequestComponent;
`,
  php: `<?php

  $url = "{{ base_url }}/v1/assistants/completions/{{ assistant_id }}";

  $headers = [
      "accept: application/json",
      "X-API-Key: "{{ api_key }}",
      "Content-Type: application/json"
  ];

  $data = [
      "message" => "YOUR MESSAGE HERE"
  ];

  // Initialize cURL
  $ch = curl_init($url);

  // Set options for cURL
  curl_setopt($ch, CURLOPT_RETURNTRANSFER, true);
  curl_setopt($ch, CURLOPT_HTTPHEADER, $headers);
  curl_setopt($ch, CURLOPT_POST, true);
  curl_setopt($ch, CURLOPT_POSTFIELDS, json_encode($data));

  // Execute the request
  $response = curl_exec($ch);

  // Check for errors
  if ($response === false) {
      echo 'cURL Error: ' . curl_error($ch);
  } else {
      // Decode the JSON response
      $responseData = json_decode($response, true);
      print_r($responseData);
  }

  // Close cURL
  curl_close($ch);`,
};
