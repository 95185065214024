import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import theme from "assets/theme";
import themeDark from "assets/theme-dark";
import { useMaterialUIController } from "context";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

const TabPanel = ({ children, value, index }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};

const DialogWorkSpaces = ({ open, onClose, title }) => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const myTheme = darkMode ? themeDark : theme;
  const [tabIndex, setTabIndex] = useState(0);
  const [formValue, setFormValue] = useState("");

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleInputChange = (event) => {
    setFormValue(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission logic here
    console.log("Form submitted with:", formValue);
    setFormValue(""); // Clear input after submission
  };

  return (
    <Dialog
      open={open}
      onClose={onClose} // Handles closing of dialog including backdrop clicks
      aria-labelledby="dialog-title"
      PaperProps={{
        sx: {
          backgroundColor: darkMode ? myTheme.palette.dark.main : myTheme.palette.white.main,
          color: darkMode ? myTheme.palette.white.main : myTheme.palette.dark.main,
        },
      }}
    >
      <DialogTitle
        id="workspace-dialog"
        sx={{
          color: darkMode ? myTheme.palette.white.main : myTheme.palette.dark.main,
        }}
      >
        {title}
      </DialogTitle>
      <DialogContent
        sx={{
          color: darkMode ? myTheme.palette.white.main : myTheme.palette.dark.main,
        }}
      >
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={tabIndex} onChange={handleTabChange} aria-label="workspace tabs">
            <Tab label="Create" id="tab-0" aria-controls="tabpanel-0" />
            <Tab label="Edit" id="tab-1" aria-controls="tabpanel-1" />
            <Tab label="Delete" id="tab-2" aria-controls="tabpanel-2" />
            <Tab label="Users" id="tab-3" aria-controls="tabpanel-3" />
          </Tabs>
        </Box>
        <TabPanel value={tabIndex} index={0}>
          <Typography variant="h6">Create a new workspace</Typography>
          <Typography sx={{ mb: 2 }}>
            Please provide the necessary details to create a new workspace:
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Workspace Name"
              variant="outlined"
              fullWidth
              value={formValue}
              onChange={handleInputChange}
              sx={{ mb: 2 }}
            />
            <Button
              type="submit"
              variant="contained"
              sx={{
                color: darkMode ? myTheme.palette.white.main : myTheme.palette.dark.main,
              }}
            >
              Submit
            </Button>
          </form>
        </TabPanel>
        <TabPanel value={tabIndex} index={1}>
          <Typography>Content for Tab 2</Typography>
        </TabPanel>
        <TabPanel value={tabIndex} index={2}>
          <Typography>Content for Tab 3</Typography>
        </TabPanel>
        <TabPanel value={tabIndex} index={3}>
          <Typography>Content for Tab 4</Typography>
        </TabPanel>
      </DialogContent>
    </Dialog>
  );
};

// Define PropTypes for the component
DialogWorkSpaces.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default DialogWorkSpaces;
