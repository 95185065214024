import React, { useState, useEffect, useRef } from "react";
import axios from "axiosConfig";
import { MDBox, MDTypography } from "components";
import { getSubdomainAddress, getBackendUrl } from "services";

const EnvironmentBox = () => {
  const [currentEnv, setCurrentEnv] = useState("");
  const [apiEnv, setApiEnv] = useState("");
  const [backendEnv, setBackendEnv] = useState("");
  const [cdnEnv, setCdnEnv] = useState("");
  const [embedEnv, setEmbedEnv] = useState("");
  const [mysqlHostEnv, setMysqlHostEnv] = useState("");
  const [isProd, setIsProd] = useState(true);
  const initialized = useRef(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const currentEnv = await axios.get(`${getSubdomainAddress("dashboard")}/env.txt`);
        if (
          currentEnv.data != "react-dev" &&
          currentEnv.data != "apache-dev" &&
          currentEnv.data != "react-staging" &&
          currentEnv.data != "staging" &&
          currentEnv.data != "apache-staging"
        ) {
          setIsProd(true);
          return;
        }
        if (currentEnv.data === "react-dev") {
          currentEnv.data += process.env.REACT_APP_NODE_ENV === "production" ? " serve" : " npm";
        }
        setCurrentEnv(currentEnv.data);
      } catch (error) {
        console.error("Error fetching current environment:", error);
      }
      try {
        const cdnEnv = await axios.get(`${getSubdomainAddress("cdn")}/env.txt`);
        setCdnEnv(cdnEnv.data);
      } catch (error) {
        console.error("Error fetching cdn environment:", error);
      }
      try {
        const embedEnv = await axios.get(`${getSubdomainAddress("embed")}/env.txt`);
        setEmbedEnv(embedEnv.data);
      } catch (error) {
        console.error("Error fetching embed environment:", error);
      }
      try {
        const mysqlHostEnv = await axios.get(
          `${getSubdomainAddress("dashboard")}${getBackendUrl()}/mysql-host.txt`
        );
        setMysqlHostEnv(mysqlHostEnv.data);
      } catch (error) {
        console.error("Error fetching mysql host:", error);
      }
      try {
        const backendEnv = await axios.get(
          `${getSubdomainAddress("dashboard")}${getBackendUrl()}/environment`
        );
        setBackendEnv(backendEnv.data.data.environment);
      } catch (error) {
        console.error("Error fetching backend environment:", error);
      }
      try {
        const apiEnv = await axios.get(`${getSubdomainAddress("api")}/api/v1/environment`);
        setApiEnv(apiEnv.data.data.environment);
      } catch (error) {
        console.error("Error fetching api environment:", error);
      }
      setIsProd(false);
    };
    if (!initialized.current) {
      initialized.current = true;
      fetchData();
    }
  }, []);

  return (
    !isProd && (
      <>
        <MDBox
          sx={{
            position: "fixed",
            borderRadius: "8px",
            zIndex: 9999,
            bottom: 20,
            left: 10,
            display: "flex",
            padding: "10px",
            backgroundColor:
              currentEnv.includes("react-dev") || currentEnv.includes("apache-dev")
                ? "yellow"
                : "orange",
            flexDirection: "column",
          }}
        >
          <MDTypography style={{ color: "black" }} fontSize="14px" variant="body2">
            environment: <b>{currentEnv}</b>
          </MDTypography>
          <MDTypography style={{ color: "black" }} fontSize="14px" variant="body2">
            backend environment: <b>{backendEnv}</b>
          </MDTypography>
          <MDTypography style={{ color: "black" }} fontSize="14px" variant="body2">
            cdn environment: <b>{cdnEnv}</b>
          </MDTypography>
          <MDTypography style={{ color: "black" }} fontSize="14px" variant="body2">
            api environment: <b>{apiEnv}</b>
          </MDTypography>
          <MDTypography style={{ color: "black" }} fontSize="14px" variant="body2">
            embed environment: <b>{embedEnv}</b>
          </MDTypography>
          <MDTypography style={{ color: "black" }} fontSize="14px" variant="body2">
            mysql host: <b>{mysqlHostEnv}</b>
          </MDTypography>
          <MDTypography style={{ color: "black" }} fontSize="14px" variant="body2">
            dashboard version: <b>{process.env.REACT_APP_VERSION}</b>
          </MDTypography>
        </MDBox>
      </>
    )
  );
};

export default EnvironmentBox;
