import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { useMaterialUIController } from "context";
import { useApiKeyServices } from "services";
import { CircularProgress } from "@mui/material";
import { MDBox } from "components";
import { RecordsGrid } from "./components";
import theme from "assets/theme";
import MoveRecordDialog from "components/Dialogs/MoveRecordDialog";
import { useSnackbar, lang } from "hooks";

const RecordsList = ({ onEdit, records, setRecords }) => {
  const { getApiKeys, moveApiKey } = useApiKeyServices();
  const [loading, setLoading] = useState(true);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [moveDialogOpen, setMoveDialogOpen] = useState(false);
  const [moveDialogTitle, setMoveDialogTitle] = useState("");
  const [currentSid, setCurrentSid] = useState(null);
  const { showSnackbar, SnackbarComponent } = useSnackbar();

  const fetchRecords = useCallback(async () => {
    try {
      const recordsArray = await getApiKeys();
      setRecords(recordsArray.data);
    } catch (error) {
      console.error("Error fetching records:", error);
    } finally {
      setLoading(false);
    }
  }, [setRecords]);

  useEffect(() => {
    fetchRecords();
  }, [fetchRecords]);

  const handleViewDetails = (sid) => {
    onEdit(sid);
  };

  const handleRecordMove = (sid) => {
    setMoveDialogTitle(`${lang("ucwords")("API_KEY_MOVE_TITLE")} (sid: ${sid})`);
    setCurrentSid(sid);
    setMoveDialogOpen(true);
  };

  const handleDoMoveRecord = async (newWorkspace) => {
    try {
      const result = await moveApiKey({ sid: currentSid, new_workspace: newWorkspace });
      if (result.status_code === 200) {
        fetchRecords();
        showSnackbar("success", lang("ucfirst")("API_KEY_MOVE_SUCCESS"));
      } else if ((errorMsg = formatErrorMsg(response.data))) {
        setFormErrors(collectKeys(response.data.details));
        showSnackbar("error", errorMsg);
      } else {
        showSnackbar("error", lang("ucfirst")("G_FORM_ERROR"));
      }
      setMoveDialogOpen(false);
    } catch (error) {
      showSnackbar("error", lang("ucfirst")("API_KEY_MOVE_FAILED"));
      console.error("Error moving record:", error);
      setMoveDialogOpen(false);
    }
  };

  const handleMoveDialogClose = () => {
    setMoveDialogOpen(false);
  };

  return (
    <>
      <MoveRecordDialog
        open={moveDialogOpen}
        onClose={handleMoveDialogClose}
        title={moveDialogTitle}
        onButtonClick={handleDoMoveRecord}
        buttonText={lang("ucwords")("G_MOVE")}
        closeOnBackdropClick={true}
        alertText={lang("ucfirst")("API_KEY_MOVE_ALERT_MSG")}
      />
      {SnackbarComponent}
      {loading ? (
        <MDBox
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress
            sx={{
              color: darkMode ? theme.palette.white.main : theme.palette.dark.main,
            }}
          />
        </MDBox>
      ) : (
        <RecordsGrid
          records={records}
          onViewDetails={handleViewDetails}
          moveAction={handleRecordMove}
        />
      )}
    </>
  );
};

RecordsList.propTypes = {
  onEdit: PropTypes.func.isRequired,
  records: PropTypes.array.isRequired,
  setRecords: PropTypes.func.isRequired,
};

export default RecordsList;
