import React, { useState } from "react";
import { useMaterialUIController, useUserContext } from "context";
import { useSnackbar, lang } from "hooks";
import { useChatAssistantService } from "services";
import { Icon } from "@mui/material";
import { DashboardLayout, DashboardNavbar, MDButton, AddChatAssistantDialog } from "components";
import ListRecords from "./vi.list";
import RecordDetails from "./vi.details";
import { MDTypography } from "components";

const ChatAssistants = () => {
  const [controller] = useMaterialUIController();
  const { sidenavColor, darkMode } = controller;
  const [currentView, setCurrentView] = useState("list");
  const [currentRecord, setCurrentRecord] = useState(null);
  const [records, setRecords] = useState([]);
  const { showSnackbar, SnackbarComponent } = useSnackbar();
  const { providers } = useUserContext();
  const { deleteChatAssistant } = useChatAssistantService();
  const [openChatAssistantDialog, setOpenChatAssistantDialog] = useState(false);
  const [promptTemplate, setPromptTemplate] = useState(null);

  const handleChatAssistantDialogOpen = () => {
    setOpenChatAssistantDialog(true);
  };

  const handleChatAssistantDialogClose = () => {
    setOpenChatAssistantDialog(false);
  };

  const gotoChatAssistantForm = (selectedOption) => {
    setPromptTemplate(selectedOption);
    handleChatAssistantDialogClose();
    setCurrentView("details");
  };

  const handleGridEditBtn = (sid) => {
    if (sid) {
      const record = records.find((p) => p.sid === sid);
      setCurrentRecord(record);
    } else {
      setCurrentRecord(null);
    }
    setCurrentView("details");
  };

  const handleDelete = {
    action: async ({ sid }) => {
      await deleteChatAssistant({ sid });
    },
    callback: () => {
      setCurrentView("list");
      showSnackbar("success", lang()("G_FORM_DELETE_SUCCESS"));
    },
  };

  const handleEdit = (isNew = false) => {
    //setCurrentView("list");
    if (isNew) {
      setCurrentView("list");
      showSnackbar("success", lang("ucfirst")("G_FORM_ADD_SUCCESS"));
    } else {
      showSnackbar("success", lang("ucfirst")("G_FORM_EDIT_SUCCESS"));
    }
  };

  const goBack = () => {
    setCurrentView("list");
    setCurrentRecord(null); // Clear selected provider ID when going back
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <AddChatAssistantDialog
        open={openChatAssistantDialog}
        onClose={handleChatAssistantDialogClose}
        onSubmit={gotoChatAssistantForm}
        btnColor={sidenavColor}
        darkMode={darkMode}
      />
      {SnackbarComponent}
      {currentView === "list" ? (
        <div style={{ margin: "15px 0px" }}>
          <div style={{ display: "flex", alignItems: "center", marginBottom: 18 }}>
            <MDButton
              color={sidenavColor}
              variant="gradient"
              //onClick={() => handleGridEditBtn(null)}
              onClick={handleChatAssistantDialogOpen}
              style={{ marginRight: "15px" }}
              disabled={providers.length === 0}
            >
              {lang()("CHAT_ASSISTANTS_ADD_BTN")}
            </MDButton>
            {providers.length === 0 && (
              <MDTypography sx={{ fontSize: 16 }} fontWeight="regular" color="error">
                {lang("span", {
                  "@providers_link": `${process.env.REACT_APP_BASE_PATH}/settings/providers`,
                })("CHAT_ASSISTANTS_ADD_PROVIDER_ALERT_TEXT")}
              </MDTypography>
            )}
          </div>
          <ListRecords onEdit={handleGridEditBtn} records={records} setRecords={setRecords} />
        </div>
      ) : (
        <div>
          <MDButton
            color="error"
            variant="text"
            onClick={goBack}
            style={{
              display: "flex",
              alignItems: "center",
              margin: "15px 0px",
              paddingLeft: "10px",
            }}
          >
            <Icon style={{ marginRight: "4px" }}>arrow_back</Icon>
            {lang()("G_GO_BACK")}
          </MDButton>
          <RecordDetails
            record={currentRecord}
            onDelete={handleDelete}
            onEdit={handleEdit}
            promptTemplate={promptTemplate}
          />
        </div>
      )}
    </DashboardLayout>
  );
};

export default ChatAssistants;
