import React, { useState } from "react";
import PropTypes from "prop-types";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Icon, InputAdornment, Tooltip, IconButton } from "@mui/material";
import { MDBox } from "components";
//import theme from "assets/theme";

export const HelpToolTip = (
  tooltipTitle,
  darkMode = false,
  type = "input",
  handleIconClick = null
) => {
  if (type === "select") {
    return (
      <MDBox sx={{ display: "flex", alignItems: "center" }}>
        <IconButton edge="end" onClick={handleIconClick}>
          <Icon
            sx={{
              color: darkMode ? "#fff" : "black",
              fontSize: "18px !important",
            }}
          >
            expand_more
          </Icon>
        </IconButton>
        <Tooltip
          title={tooltipTitle}
          arrow
          placement="top"
          enterDelay={500}
          leaveDelay={200}
          PopperProps={{
            sx: {
              "& .MuiTooltip-tooltip": {
                textAlign: "left", // Align text to the left
              },
            },
          }}
        >
          <IconButton edge="end" sx={{ marginRight: "3px" }}>
            <Icon
              sx={{
                color: darkMode ? "#fff" : "black",
                fontSize: "18px !important",
              }}
            >
              help_outline
            </Icon>
          </IconButton>
        </Tooltip>
      </MDBox>
    );
  } else if (type === "textarea") {
    return (
      <Tooltip
        title={tooltipTitle}
        arrow
        placement="top"
        enterDelay={500}
        leaveDelay={200}
        PopperProps={{
          sx: {
            "& .MuiTooltip-tooltip": {
              textAlign: "left", // Align text to the left
              //maxWidth: "none", // Optional: adjust max width if needed
            },
          },
        }}
      >
        <IconButton edge="end" sx={{ marginRight: "6px" }}>
          <Icon
            sx={{
              color: darkMode ? "#fff" : "black",
              fontSize: "18px !important",
              marginRight: "-3px !important",
              //opacity: 0.6,
            }}
          >
            help_outline
          </Icon>
        </IconButton>
      </Tooltip>
    );
  } else {
    return (
      <InputAdornment position="end">
        <Tooltip
          title={tooltipTitle}
          arrow
          placement="top"
          enterDelay={500}
          leaveDelay={200}
          PopperProps={{
            sx: {
              "& .MuiTooltip-tooltip": {
                textAlign: "left", // Align text to the left
                //maxWidth: "none", // Optional: adjust max width if needed
              },
            },
          }}
        >
          <Icon
            sx={{
              color: darkMode ? "#fff" : "black",
              fontSize: "18px !important",
              marginRight: "-3px !important",
              //opacity: 0.6,
            }}
          >
            help_outline
          </Icon>
        </Tooltip>
      </InputAdornment>
    );
  }
};

// Works only with string fields
export const areRequiredFieldsFilled = (requiredFields, formValues) => {
  console.log("areRequiredFieldsFilled", formValues);
  return requiredFields.every((field) => {
    const value = formValues[field];
    // Convert value to string and trim for validation
    return String(value).trim() !== "";
  });
};

export const hasChanges = (formValues, initialValues) => {
  return JSON.stringify(formValues) !== JSON.stringify(initialValues);
};

export const collectKeys = (obj, parentKey = "") => {
  let result = [];

  Object.entries(obj).forEach(([key, value]) => {
    // Skip numeric keys
    if (!isNaN(Number(key))) {
      console.log(`Skipping numeric key: ${key}`);
      return;
    }

    // Create the chained key
    const chainedKey = parentKey ? `${parentKey}_${key}` : key;

    console.log(`Processing key: ${key}, Chained key: ${chainedKey}`);

    if (typeof value === "object" && value !== null) {
      if (Array.isArray(value)) {
        // Process each item in the array
        value.forEach((item) => {
          if (typeof item === "object" && item !== null) {
            console.log(`Processing array item at key: ${chainedKey}`);
            result = result.concat(collectKeys(item, chainedKey));
          } else {
            // Handle non-object items in arrays (e.g., strings or numbers)
            console.log(`Non-object item in array at key: ${chainedKey}`);
            result.push(chainedKey);
          }
        });
      } else {
        // Recursively process nested objects
        result = result.concat(collectKeys(value, chainedKey));
      }
    } else {
      // Add the chained key to the result array
      console.log(`Adding chained key to result: ${chainedKey}`);
      result.push(chainedKey);
    }
  });

  return result;
};

export const formatErrorMsg = (data) => {
  const formatDetails = (details, depth = 0) => {
    let errorMsg = "";
    const indent = "  ".repeat(depth); // Indentation for nested levels

    for (const key in details) {
      if (typeof details[key] === "object" && details[key] !== null) {
        // Recursively format nested objects
        errorMsg += `${indent}<strong>${key}</strong>:<br>${formatDetails(
          details[key],
          depth + 1
        )}<br>`;
      } else {
        // Format simple key-value pairs
        //errorMsg += `${indent}<strong>${key}</strong>: ${details[key]}<br>`;
        errorMsg += `${indent} ${details[key]}<br>`;
      }
    }

    return errorMsg;
  };

  let errorMsg = "";
  if ("details" in data) {
    errorMsg = formatDetails(data.details);
  }
  return errorMsg;
};

export const TextareaSX = {
  "& .MuiInputBase-root": {
    resize: "vertical",
    overflow: "auto",
    minHeight: "200px",
    maxHeight: "500px",
    height: "auto",
  },
  "& textarea": {
    boxSizing: "border-box",
  },
};

export const inputErrorSX = (darkMode = false, error = false) => {
  return {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: error
          ? "red !important"
          : darkMode
            ? "rgba(255, 255, 255, 0.5)"
            : "rgb(12 10 10 / 12%)",
      },
    },
  };
};

export const scrollBarStyle = (darkMode = false) => {
  return {
    "&::-webkit-scrollbar": {
      width: "6px", // Thin scrollbar width
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: darkMode ? "#333" : "#f1f1f1", // Track color based on dark mode
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: darkMode ? "#888" : "#ccc", // Thumb color based on dark mode
      borderRadius: "4px", // Rounded edges for scrollbar thumb
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: darkMode ? "#555" : "#999", // Hover color effect
    },
  };
};

export const inputReadOnlySX = (darkMode = false) => {
  return {
    "& .MuiOutlinedInput-root": {
      backgroundColor: `${darkMode ? "rgba(0, 0, 0, 0.23)" : "#f5f5f5"} !important`,
      "& fieldset": {
        color: "grey !important",
        border: "none !important",
      },
    },
    "& .MuiFormLabel-root": {
      color: "grey !important", // Label color
      "&.Mui-focused": {
        color: "grey !important", // Label color on focus
      },
    },
    cursor: "not-allowed", // Cursor style
    "& .MuiInputBase-input": {
      color: "grey !important", // Input text color
      pointerEvents: "none", // Prevent click on read-only fields
    },
  };
};

export const inputDisabledSX = (darkMode = false) => {
  return {};
};

export const CustomBadge = ({ text, lang, darkMode }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
  };

  return (
    <MDBox display="flex" alignItems="center">
      <MDBox
        component="span"
        display="flex"
        alignItems="center"
        bgColor={darkMode ? "dark" : "none"}
        color={darkMode ? "light" : "none"}
        variant="gradient"
        sx={{
          borderRadius: "8px",
          padding: "4px 8px",
          border: "1px solid",
          borderColor: "divider",
          minWidth: "fit-content",
          display: "inline-flex",
          alignItems: "center",
          fontSize: "12px",
        }}
      >
        <span>{text}</span>
        <CopyToClipboard text={text} onCopy={handleCopy}>
          <Tooltip
            title={copied ? lang("ucfirst")("G_COPIED") : lang("ucfirst")("G_COPY_TO_CLIPBOARD")}
            arrow
          >
            <Icon fontSize="small" sx={{ ml: 1, cursor: "pointer" }}>
              content_copy
            </Icon>
          </Tooltip>
        </CopyToClipboard>
      </MDBox>
    </MDBox>
  );
};

CustomBadge.propTypes = {
  text: PropTypes.string.isRequired,
  lang: PropTypes.func.isRequired,
  darkMode: PropTypes.bool.isRequired,
};
