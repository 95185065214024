import { Card } from "@mui/material";
import { styled } from "@mui/material/styles";

const Panel = styled(Card, {
  shouldForwardProp: (prop) => prop !== "darkMode",
})(({ theme, darkMode }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "stretch",
  //color: darkMode ? theme.palette.common.white : theme.palette.text.primary,
  overflow: "hidden",
  border: `1px solid ${darkMode ? theme.palette.grey[700] : theme.palette.grey[300]}`,
  boxShadow: "none",
  height: "100%",
}));

const getBroswerTheme = () => {
  return window.matchMedia("(prefers-color-scheme: dark)").matches;
};

export { Panel, getBroswerTheme };
