import i18n from "i18n";

// Utility function to capitalize the first letter of a string
const ucfirst = (str) => str.charAt(0).toUpperCase() + str.slice(1);

// Utility function to capitalize the first letter of each word in a string
const ucwords = (str) => str.replace(/\b\w/g, (char) => char.toUpperCase());

// Utility function to convert a string to uppercase
const uppercase = (str) => str.toUpperCase();

// Function to check if a string contains HTML tags
const containsHtml = (str) => /<\/?[a-z][\s\S]*>/i.test(str);

const isNotEmptyObject = (obj) => Object.keys(obj).length > 0;

export const replacePlaceholdersWithTranslations = (text, transformation = null) => {
  const regex = /{([^}]+)}/g;
  return text.replace(regex, (_, key) => {
    return lang(transformation)(key) || key;
  });
};

export const replacePlaceholders = (text, values) => {
  const regex = /{([^}]+)}/g;
  return text.replace(regex, (_, key) => {
    return values[key] || key;
  });
};

// Define the translation function
let translate;

export const lang = (transformation = "", data = {}) => {
  if (!translate) {
    translate = i18n.t.bind(i18n);
  }
  return (key) => {
    // Get the translated string
    let translated = translate(key);

    if (isNotEmptyObject(data)) {
      translated = replacePlaceholders(translated, data);
    }

    if (containsHtml(translated)) {
      // Return the string as HTML inside the specified tag
      const Tag = transformation ? transformation : "span";
      return <Tag dangerouslySetInnerHTML={{ __html: translated }} />;
    }

    // Apply transformations based on the provided transformation type
    if (transformation === "ucfirst") {
      return ucfirst(translated);
    }
    if (transformation === "ucwords") {
      return ucwords(translated);
    }
    if (transformation === "uppercase" || transformation === "upper") {
      return uppercase(translated);
    }
    return translated;
  };
};
