import React, { useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { lang } from "hooks";
import { CardContent, Icon } from "@mui/material";
import { MDTypography, MDBox, CustomHidden, Panel } from "components";
import PropTypes from "prop-types";
import TestConnection from "./TestConnection";

const TipsPanel = ({ darkMode, firstGridHeight = 200, sidenavColor }) => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <Tabs
      forceRenderTabPanel
      selectedIndex={activeTab}
      onSelect={(index) => setActiveTab(index)}
      selectedTabClassName={
        darkMode ? "react-tabs-dark__tab--selected" : "react-tabs-dark__tab--selected"
      }
    >
      <CustomHidden only={["xs", "sm", "md"]}>
        <TabList>
          <Tab>
            <MDTypography variant="h6" fontWeight="regular" color="inherit">
              <Icon fontSize="small">help_outlined</Icon> {lang("ucfirst")("G_HELP")}
            </MDTypography>
          </Tab>
          <Tab>
            <MDTypography variant="h6" fontWeight="regular" color="inherit">
              <Icon fontSize="small">cloud_upload_outlined</Icon>{" "}
              {lang("ucfirst")("G_TEST_CONNECTION")}
            </MDTypography>
          </Tab>
        </TabList>
      </CustomHidden>
      <Panel darkMode={darkMode} sx={{ minHeight: firstGridHeight }}>
        <CardContent>
          <TabPanel>
            <MDBox mt={1}>
              {lang("span", {
                "@opacity": darkMode ? "0.6" : "0.8",
                "@color": darkMode ? "lightgrey" : "grey",
              })("PROVIDERS_TIPS")}
            </MDBox>
          </TabPanel>
          <TabPanel>
            <TestConnection darkMode={darkMode} sidenavColor={sidenavColor} />
          </TabPanel>
        </CardContent>
      </Panel>
    </Tabs>
  );
};

TipsPanel.propTypes = {
  darkMode: PropTypes.bool.isRequired,
  firstGridHeight: PropTypes.number,
  sidenavColor: PropTypes.string.isRequired,
};

export default TipsPanel;
