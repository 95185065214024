import React, { useState, useRef, useEffect } from "react";
import { validFileTypes } from "configDefaults";
import { useMaterialUIController } from "context";
import { useSnackbar, lang } from "hooks";
import { useKnowledgeBaseServices } from "services/knowledgeBaseService";
import { useUserContext } from "context/UserContext";
import { DashboardLayout, DashboardNavbar, MDButton, MDTypography } from "components";
import RecordsList from "./vi.list";

const Files = () => {
  const [controller] = useMaterialUIController();
  const { sidenavColor } = controller;
  const [records, setRecords] = useState([]);
  const [refreshKey, setRefreshKey] = useState(0); // Key to trigger re-render
  const { showSnackbar, SnackbarComponent } = useSnackbar();
  const { uploadKnowledgeBaseFile, getKnowledgeBaseFiles } = useKnowledgeBaseServices();
  const { knowledgeBaseFiles, setKnowledgeBaseFiles } = useUserContext();
  const fileInputRef = useRef(null);
  const validTypesString = validFileTypes.map((type) => type.split("/")[1]).join(", ");

  useEffect(() => {
    const fetchRecords = async () => {
      try {
        const response = await getKnowledgeBaseFiles();
        if (response.status_code === 200) {
          setRecords(response.data || []);
          setKnowledgeBaseFiles(response.data);
        } else {
          showSnackbar("error", lang("ucfirst")("FILES_FETCH_FAILED_MSG"));
        }
      } catch (error) {
        showSnackbar("error", lang("ucfirst")("FILES_FETCH_FAILED_MSG"));
      }
    };
    fetchRecords();
  }, [refreshKey]);

  const handleAddFileBtn = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = async (event) => {
    const files = event.target.files;
    if (files.length > 0) {
      for (let file of files) {
        const fileType = file.type;
        if (!validFileTypes.includes(fileType)) {
          showSnackbar(
            "error",
            lang("", {
              "@fileType": fileType,
              "@validTypesString": validTypesString
                .replace("plain", "txt")
                .replace("msword", "doc")
                .replace("vnd.openxmlformats-officedocument.wordprocessingml.document", "docx"),
            })("FILES_INVALID_FILE_TYPE")
          );
          return;
        }
        try {
          const response = await uploadKnowledgeBaseFile({ file });
          if (response.status_code == 200) {
            showSnackbar("success", lang("ucfirst")("FILES_UPLOAD_SUCCESS_MSG"));
            setRefreshKey((prevKey) => prevKey + 1);
          } else {
            showSnackbar("error", lang("ucfirst")("FILES_UPLOAD_FAILED_MSG"));
          }
        } catch (error) {
          console.error(error);
          showSnackbar("error", lang("ucfirst")("FILES_UPLOAD_FAILED_MSG"));
        }
      }
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {SnackbarComponent}
      <div style={{ margin: "15px 0px" }}>
        <div style={{ display: "flex", alignItems: "center", marginBottom: 18 }}>
          <MDButton
            color={sidenavColor}
            variant="gradient"
            onClick={handleAddFileBtn}
            style={{ marginRight: "15px" }}
          >
            {lang()("FILES_ADD_BTN_TXT")}
          </MDButton>
          <MDTypography sx={{ fontSize: 16 }} fontWeight="regular" color="secondary">
            {lang("", {
              "@validTypesString": validTypesString
                .replace("plain", "txt")
                .replace("plain", "txt")
                .replace("msword", "doc")
                .replace("vnd.openxmlformats-officedocument.wordprocessingml.document", "docx"),
            })("FILES_ADD_HINT_TEXT")}
          </MDTypography>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileChange}
            multiple
          />
        </div>
        <RecordsList records={records} setRecords={setRecords} />{" "}
      </div>
    </DashboardLayout>
  );
};

export default Files;
