import React from "react";
import PropTypes from "prop-types";
import MDSnackbar from "components/MDSnackbar";

const Snackbar = ({ open, onClose, type, message, title, dateTime }) => {
  const snackbarProps = {
    success: {
      color: "success",
      icon: "check",
      defaultTitle: "Success",
    },
    error: {
      color: "error",
      icon: "error",
      defaultTitle: "Error",
    },
    info: {
      color: "info",
      icon: "info",
      defaultTitle: "Information",
    },
  };

  const { color, icon, defaultTitle } = snackbarProps[type] || snackbarProps.info;

  const handleClose = () => {
    if (onClose) {
      onClose();
    } else {
      setOpen(false); // Note: setOpen might not be defined. Remove this if not needed.
    }
  };

  const formattedMessage = message.replace(/\n/g, "<br />");

  return (
    <MDSnackbar
      color={color}
      icon={icon}
      title={title || defaultTitle} // Use the passed title or the default one
      content={<div dangerouslySetInnerHTML={{ __html: formattedMessage }} />} // Use HTML content
      open={open}
      onClose={handleClose}
      close={handleClose}
      dateTime={dateTime}
      bgWhite
    />
  );
};

// Define prop types for the Snackbar component
Snackbar.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  type: PropTypes.oneOf(["success", "error", "info"]),
  message: PropTypes.string.isRequired,
  title: PropTypes.string, // Add title as a prop type
  dateTime: PropTypes.string,
};

// Set default props for the Snackbar component
Snackbar.defaultProps = {
  onClose: null,
  type: "info",
  title: "", // Default to an empty string if not provided
  dateTime: "",
};

export default Snackbar;
