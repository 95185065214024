import React, { useState, useEffect, useCallback, useRef } from "react";
import PropTypes from "prop-types";
import { useMaterialUIController } from "context";
import { CircularProgress } from "@mui/material";
import { MDBox } from "components";
import { RecordsGrid } from "./components";
import theme from "assets/theme";
import { useUserContext } from "context/UserContext";
import { useSnackbar, lang } from "hooks";
import DuplicateRecordDialog from "components/Dialogs/DuplicateRecordDialog";
import MoveRecordDialog from "components/Dialogs/MoveRecordDialog";
import { useGetProviders } from "services/providerService";

const RecordsList = ({ onEdit, records, setRecords }) => {
  const [loading, setLoading] = useState(true);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const { providers, getProviders, setProviders } = useUserContext();
  const { duplicateProvider, moveProvider } = useGetProviders();
  const hasFetched = useRef(false);
  const [duplicateDialogOpen, setDuplicateDialogOpen] = useState(false);
  const [duplicateDialogTitle, setDuplicateDialogTitle] = useState("");
  const [moveDialogOpen, setMoveDialogOpen] = useState(false);
  const [moveDialogTitle, setMoveDialogTitle] = useState("");
  const [currentSid, setCurrentSid] = useState(null);
  const [currentName, setCurrentName] = useState("");
  const { showSnackbar, SnackbarComponent } = useSnackbar();

  const handleViewDetails = (sid) => {
    onEdit(sid);
  };

  const getNameBySid = (sidToMatch) => {
    const matchedAgent = records.find((agent) => agent.sid === sidToMatch);
    return matchedAgent ? matchedAgent.name : null;
  };

  const handleRecordDuplicate = (sid) => {
    setDuplicateDialogTitle(`${lang("ucwords")("PROVIDER_DUPLICATE_TITLE")} (sid: ${sid})`);
    setCurrentSid(sid);
    const name = getNameBySid(sid);
    setCurrentName(name);
    setDuplicateDialogOpen(true);
  };

  const handleDoDuplicateRecord = async (name) => {
    try {
      const result = await duplicateProvider({ sid: currentSid, name: name });
      if (result.status_code === 200) {
        hasFetched.current = false;
        fetchRecords();
        showSnackbar("success", lang("ucfirst")("PROVIDER_DUPLICATE_SUCCESS"));
      } else if ((errorMsg = formatErrorMsg(response.data))) {
        setFormErrors(collectKeys(response.data.details));
        showSnackbar("error", errorMsg);
      } else {
        showSnackbar("error", lang("ucfirst")("G_FORM_ERROR"));
      }
      setDuplicateDialogOpen(false);
    } catch (error) {
      showSnackbar("error", lang("ucfirst")("PROVIDER_DUPLICATE_FAILED"));
      console.error("Error duplicating record:", error);
    }
  };

  const handleDuplicateDialogClose = () => {
    setDuplicateDialogOpen(false);
  };

  const handleRecordMove = (sid) => {
    setMoveDialogTitle(`${lang("ucwords")("PROVIDER_MOVE_TITLE")} (sid: ${sid})`);
    setCurrentSid(sid);
    setMoveDialogOpen(true);
  };

  const handleDoMoveRecord = async (newWorkspace) => {
    try {
      const result = await moveProvider({ sid: currentSid, new_workspace: newWorkspace });
      if (result.status_code === 200) {
        hasFetched.current = false;
        fetchRecords();
        showSnackbar("success", lang("ucfirst")("PROVIDER_MOVE_SUCCESS"));
      } else if (result.status_code === 409) {
        showSnackbar(
          "error",
          lang("", { "@assistants": result.data.join(", ") })("PROVIDER_MOVE_FAILED_ASSISTANTS_MSG")
        );
      } else {
        showSnackbar("error", lang("ucfirst")("G_FORM_ERROR"));
      }
      setMoveDialogOpen(false);
    } catch (error) {
      showSnackbar("error", lang("ucfirst")("ASSISTANT_MOVE_FAILED"));
      console.error("Error moving record:", error);
      setMoveDialogOpen(false);
    }
  };

  const handleMoveDialogClose = () => {
    setMoveDialogOpen(false);
  };

  const fetchRecords = useCallback(async () => {
    if (!hasFetched.current) {
      try {
        const recordsArray = await getProviders();
        console.log(recordsArray);
        setRecords(recordsArray);
        setProviders(recordsArray);
        hasFetched.current = true;
      } catch (error) {
        console.error("Error fetching records:", error);
      } finally {
        setLoading(false);
      }
    }
  }, [setRecords, getProviders, setProviders]);

  useEffect(() => {
    fetchRecords();
  }, [fetchRecords]);

  return (
    <>
      <DuplicateRecordDialog
        open={duplicateDialogOpen}
        onClose={handleDuplicateDialogClose}
        title={duplicateDialogTitle}
        onButtonClick={handleDoDuplicateRecord}
        buttonText={lang("ucwords")("G_DUPLICATE")}
        closeOnBackdropClick={true}
        name={currentName}
      />
      <MoveRecordDialog
        open={moveDialogOpen}
        onClose={handleMoveDialogClose}
        title={moveDialogTitle}
        onButtonClick={handleDoMoveRecord}
        buttonText={lang("ucwords")("G_MOVE")}
        closeOnBackdropClick={true}
        alertText={lang("ucfirst")("PROVIDER_MOVE_ALERT_MSG")}
      />
      {SnackbarComponent}
      {loading ? (
        <MDBox
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress
            sx={{
              color: darkMode ? theme.palette.white.main : theme.palette.dark.main,
            }}
          />
        </MDBox>
      ) : (
        <RecordsGrid
          records={records}
          onViewDetails={handleViewDetails}
          duplicateAction={handleRecordDuplicate}
          moveAction={handleRecordMove}
        />
      )}
    </>
  );
};

RecordsList.propTypes = {
  onEdit: PropTypes.func.isRequired,
  records: PropTypes.array.isRequired,
  setRecords: PropTypes.func.isRequired,
};

export default RecordsList;
