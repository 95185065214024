import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import { useMaterialUIController } from "context";
import theme from "assets/theme";
import themeDark from "assets/theme-dark";
import TextField from "@mui/material/TextField";
import { lang } from "hooks";
import { Icon } from "@mui/material";

const DuplicateAssistantDialog = ({
  open,
  onClose,
  title,
  onButtonClick,
  buttonText,
  name,
  closeOnBackdropClick = true, // Default to true
}) => {
  const [internalOpen, setInternalOpen] = useState(open);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const myTheme = darkMode ? themeDark : theme;
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    setInternalOpen(open); // Sync internal state with the open prop
    setInputValue(name);
  }, [open]);

  const handleClose = (event, reason) => {
    if (reason === "backdropClick" && !closeOnBackdropClick) {
      // Do nothing if clicking on the backdrop and closeOnBackdropClick is false
      return;
    }
    setInternalOpen(false); // Close the dialog by setting the internal state
    if (onClose && typeof onClose === "function") {
      onClose(event, reason); // Call the onClose prop if it exists
    }
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value); // Update input value state
  };

  return (
    <Dialog
      open={internalOpen}
      onClose={handleClose} // Use the custom handleClose function
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: {
          backgroundColor: darkMode ? myTheme.palette.dark.main : myTheme.palette.white.main,
          color: darkMode ? myTheme.palette.dark.main : myTheme.palette.white.main,
          minWidth: "350px",
        },
      }}
      disableEscapeKeyDown={!closeOnBackdropClick} // Prevent closing with the Escape key if closeOnBackdropClick is false
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{
          color: darkMode ? myTheme.palette.white.main : myTheme.palette.dark.main,
        }}
      >
        <Icon
          sx={{
            color: darkMode ? theme.palette.white.main : theme.palette.dark.main,
            fontSize: "18px !important",
            paddingRight: "10px",
          }}
        >
          file_copy_outlined
        </Icon>
        {title || "Dialog Title"}
      </DialogTitle>
      <DialogContent
        sx={{
          color: darkMode ? myTheme.palette.white.main : myTheme.palette.dark.main,
        }}
      >
        <TextField
          label={lang("ucwords")("G_NAME")} // Customize your label
          variant="outlined"
          fullWidth
          value={inputValue} // Bind state to input value
          onChange={handleInputChange} // Handle input changes
          sx={{
            marginTop: "16px", // Space between text and input
            color: darkMode ? myTheme.palette.white.main : myTheme.palette.dark.main,
          }}
        />
      </DialogContent>
      <DialogActions
        sx={{
          color: darkMode ? myTheme.palette.white.main : myTheme.palette.dark.main,
        }}
      >
        <Button
          onClick={() => {
            if (onButtonClick) {
              onButtonClick(inputValue);
            }
            setInternalOpen(false);
          }}
          color="secondary"
          //autoFocus
          sx={{
            color: darkMode ? myTheme.palette.white.main : myTheme.palette.white.main,
          }}
          variant={darkMode ? "outlined" : "contained"}
        >
          {buttonText || "Close"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

// Define PropTypes for the component
DuplicateAssistantDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  name: PropTypes.string,
  onClose: PropTypes.func,
  title: PropTypes.string,
  onButtonClick: PropTypes.func,
  buttonText: PropTypes.string,
  closeOnBackdropClick: PropTypes.bool, // Ensure this is included
};

export default DuplicateAssistantDialog;
