import React, { useState, useEffect, useRef } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useMediaQuery } from "react-responsive";
import PropTypes from "prop-types";
import { lang } from "hooks";
import { useMaterialUIController, FormValuesProvider } from "context";
import { Grid, Card, CardContent, Icon, Hidden } from "@mui/material";
import { RecordForm, TipsPanel, TestConnection } from "./components";
import { MDTypography, CustomHidden, DangerZone, MDBox, Panel } from "components";

const RecordDetails = ({ record, onDelete, onEdit, providerType = null }) => {
  const [controller] = useMaterialUIController();
  const { darkMode, sidenavColor: mainColor } = controller;
  const [activeTab, setActiveTab] = useState(0);
  const isLgOrUp = useMediaQuery({ query: "(min-width: 992px)" });
  const firstGridRef = useRef(null);
  const [firstGridHeight, setFirstGridHeight] = useState(0);
  const tabPanels = [
    { id: 0, isPermanent: true },
    { id: 1, isPermanent: false },
    { id: 2, isPermanent: false },
  ];
  const [defectHeight, setDefectHeight] = useState(15);

  /*useEffect(() => {
    if (firstGridRef.current) {
      setFirstGridHeight(firstGridRef.current.clientHeight - 15);
    }
  }, []);*/

  useEffect(() => {
    if (isLgOrUp) {
      setFirstGridHeight(firstGridRef.current.clientHeight - defectHeight);
      setDefectHeight(55);
      const currentPanel = tabPanels.find((panel) => panel.id === activeTab);
      if (currentPanel && !currentPanel.isPermanent) {
        setActiveTab(0);
      }
    }
  }, [isLgOrUp, activeTab]);

  return (
    <Tabs
      forceRenderTabPanel
      selectedIndex={activeTab}
      onSelect={(index) => setActiveTab(index)}
      selectedTabClassName={
        darkMode ? "react-tabs-dark__tab--selected" : "react-tabs-dark__tab--selected"
      }
    >
      <CustomHidden only={["lg", "xl", "xxl"]}>
        <TabList>
          <Tab>
            <MDTypography variant="h6" fontWeight="regular" color="inherit">
              <Icon fontSize="small">assignment_outline</Icon> {lang("ucfirst")("G_DETAILS")}
            </MDTypography>
          </Tab>

          <Tab>
            <MDTypography variant="h6" fontWeight="regular" color="inherit">
              <Icon fontSize="small">help_outline</Icon> {lang("ucfirst")("G_HELP")}
            </MDTypography>
          </Tab>
          <Tab>
            <MDTypography variant="h6" fontWeight="regular" color="inherit">
              <Icon fontSize="small">cloud_upload_outlined</Icon>{" "}
              {lang("ucfirst")("G_TEST_CONNECTION")}
            </MDTypography>
          </Tab>
        </TabList>
      </CustomHidden>
      <Grid container justifyContent="center" spacing={2} item xs={12}>
        <Grid item xs={12}>
          <Card
            sx={{
              borderRadius: "16px",
              height: isLgOrUp ? (darkMode ? "82vh" : "81.4vh") : "auto",
              minHeight: isLgOrUp
                ? record
                  ? firstGridHeight + 260
                  : firstGridHeight + 90
                : "auto",
            }}
          >
            <FormValuesProvider>
              <CardContent>
                <MDBox mt={2}>
                  <TabPanel>
                    <Grid container spacing={2}>
                      <Grid item xs={12} lg={6} ref={firstGridRef}>
                        <RecordForm
                          record={record}
                          onEdit={onEdit}
                          mainColor={mainColor}
                          darkMode={darkMode}
                          providerType={providerType}
                        />
                      </Grid>
                      <Hidden only={["xs", "sm", "md"]}>
                        <Grid item xs={12} lg={6}>
                          <TipsPanel
                            darkMode={darkMode}
                            firstGridHeight={firstGridHeight}
                            sidenavColor={mainColor}
                          />
                        </Grid>
                      </Hidden>
                      {record && (
                        <DangerZone
                          record={record}
                          onDelete={onDelete}
                          deleteText={lang("ucfirst")("API_KEY_DELETE_TEXT")}
                          dialogTitle={lang("span", { "@sid": record.sid })(
                            "PROVIDER_DELETE_DIALOG_TITLE"
                          )}
                          dangerText={lang("ucfirst")("PROVIDER_DELETE_TEXT")}
                          dialogMsg={lang("span", { "@name": record.name })(
                            "PROVIDER_DELETE_DIALOG_TEXT"
                          )}
                        />
                      )}
                    </Grid>
                  </TabPanel>
                  <TabPanel>
                    <Grid item xs={12} lg={6}>
                      <TipsPanel
                        darkMode={darkMode}
                        firstGridHeight={firstGridHeight}
                        sidenavColor={mainColor}
                      />
                    </Grid>
                  </TabPanel>
                  <TabPanel>
                    <Panel darkMode={darkMode}>
                      <CardContent>
                        <Grid item xs={12} lg={6}>
                          <TestConnection darkMode={darkMode} sidenavColor={mainColor} />
                        </Grid>
                      </CardContent>
                    </Panel>
                  </TabPanel>
                </MDBox>
              </CardContent>
            </FormValuesProvider>
          </Card>
        </Grid>
      </Grid>
    </Tabs>
  );
};

// Define PropTypes
RecordDetails.propTypes = {
  record: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
    sid: PropTypes.string,
  }),
  onDelete: PropTypes.shape({
    action: PropTypes.func.isRequired,
    callback: PropTypes.func,
  }).isRequired,
  onEdit: PropTypes.func.isRequired,
  providerType: PropTypes.string,
};

export default RecordDetails;
