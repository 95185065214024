import React from "react";
import { createRoot } from "react-dom/client";
import { Auth0Provider } from "@auth0/auth0-react";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import { MaterialUIControllerProvider } from "context";
import { UserProvider } from "context/UserContext";
import "./customConsole";
import "./i18n";
import { ChatProvider } from "context/ChatContext";

const container = document.getElementById("app");
const root = createRoot(container);

// Construct the redirect URI
const basePath = process.env.REACT_APP_BASE_PATH || "/";
const redirectUri = `${window.location.origin}${basePath}`;

root.render(
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
    authorizationParams={{
      redirect_uri: redirectUri,
      audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
      //scope: "read:current_user update:current_user_metadata",
    }}
  >
    <BrowserRouter basename={process.env.REACT_APP_BASE_PATH}>
      <MaterialUIControllerProvider>
        <UserProvider>
          <ChatProvider>
            <App />
          </ChatProvider>
        </UserProvider>
      </MaterialUIControllerProvider>
    </BrowserRouter>
  </Auth0Provider>
);
