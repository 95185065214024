import { useGetServices, getBackendUrl } from "services";
import axios from "../axiosConfig";

export const useHelpAssistantServices = () => {
  const { getToken } = useGetServices();

  const completionRequest = async ({ token, message } = {}) => {
    console.log("Fetching completion...");
    const authToken = token || getToken();
    const backendUrl = getBackendUrl();
    const response = await axios.post(
      `${backendUrl}/help-assistant/`,
      {
        message,
      },
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  };

  return { completionRequest };
};
