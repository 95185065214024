import React from "react";
import PropTypes from "prop-types";
import { MDBox, DataTable } from "components";
import { ActionsCellV2 } from "utils";
import { useMaterialUIController } from "context";

const bytesToReadableSize = (bytes) => {
  if (bytes < 1024) return `${bytes} B`; // Less than 1 KB
  const kb = bytes / 1024;
  if (kb < 1024) return `${kb.toFixed(2)} KB`; // Less than 1 MB
  const mb = kb / 1024;
  if (mb < 1024) return `${mb.toFixed(2)} MB`; // Less than 1 GB
  const gb = mb / 1024;
  return `${gb.toFixed(2)} GB`; // 1 GB or more
};

// Update CellComponent to accept darkMode and deleteAction
const CellComponent = ({ row, darkMode, deleteAction }) => (
  <ActionsCellV2 sid={row.original.filename} darkMode={darkMode} deleteAction={deleteAction} />
);

CellComponent.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      filename: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  darkMode: PropTypes.bool.isRequired,
  deleteAction: PropTypes.func.isRequired,
};

function RecordsGrid({ records, deleteAction }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const columns = [
    { Header: "Name", accessor: "name" },
    { Header: "Type", accessor: "mime_type" },
    { Header: "Size", accessor: "size" },
    {
      Header: "Actions",
      accessor: "actions",
      Cell: (props) => <CellComponent {...props} darkMode={darkMode} deleteAction={deleteAction} />,
      width: 100,
    },
  ];

  const rows = Array.isArray(records)
    ? records.map((record) => ({
        name: record.filename,
        mime_type: record.mime_type,
        size: bytesToReadableSize(record.size), // Convert size to readable format
        filename: record.filename, // Ensure filename is included for actions
      }))
    : [];

  return (
    <MDBox>
      <DataTable
        table={{ columns, rows }}
        showTotalEntries={false}
        isSorted={true}
        noEndBorder={true}
        entriesPerPage={{ defaultValue: 10 }}
        canSearch={true}
        pagination={{ variant: "contained" }}
      />
    </MDBox>
  );
}

RecordsGrid.propTypes = {
  records: PropTypes.arrayOf(
    PropTypes.shape({
      filename: PropTypes.string.isRequired,
      mime_type: PropTypes.string.isRequired,
      size: PropTypes.number.isRequired, // Ensure size is a number
    })
  ).isRequired,
  deleteAction: PropTypes.func.isRequired,
};

export default RecordsGrid;
