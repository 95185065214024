import React, { useState, useEffect } from "react";
import { DialogTitle, DialogActions, DialogContent, Icon, Grid, Dialog } from "@mui/material";
import { MDButton, MDTypography } from "components";
import { lang } from "hooks";
import PropTypes from "prop-types";
import theme from "assets/theme";
import themeDark from "assets/theme-dark";

const AddProviderDialog = ({ open, onClose, onSubmit, btnColor, darkMode }) => {
  const myTheme = darkMode ? themeDark : theme;
  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  useEffect(() => {
    setSelectedOption("model");
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: {
          backgroundColor: myTheme.palette.background.card,
          color: darkMode ? myTheme.palette.dark.main : myTheme.palette.white.main,
          padding: "5px 20px",
          borderRadius: "16px",
        },
      }}
    >
      <DialogTitle
        sx={{
          color: darkMode ? "lightgrey" : "black",
          fontWeight: "regular",
          fontSize: "20px",
          opacity: 0.8,
          paddingBottom: "30px",
        }}
      >
        <Icon fontSize="medium" style={{ verticalAlign: "sub", marginRight: "3px" }}>
          public
        </Icon>
        {lang("ucwords")("G_SELECT_PROVIDER_TYPE")}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <div
              style={{
                border: selectedOption === "model" ? "1px solid grey" : "none",
                backgroundColor: myTheme.palette.background.default,
                padding: "20px",
                textAlign: "center",
                opacity: 0.8,
                minWidth: "130px",
                borderRadius: "16px",
                cursor: "pointer",
              }}
              onClick={() => handleOptionClick("model")}
            >
              <Icon fontSize="large">psychology_outlined</Icon>
              <MDTypography variant="body2">{lang("ucfirst")("G_MODEL")}</MDTypography>
            </div>
          </Grid>
          <Grid item xs={12} sm={4}>
            <div
              style={{
                border: selectedOption === "transcriber" ? "1px solid grey" : "none",
                backgroundColor: myTheme.palette.background.default,
                padding: "20px",
                textAlign: "center",
                opacity: 0.4,
                minWidth: "130px",
                borderRadius: "16px",
                //cursor: "pointer",
              }}
              //onClick={() => handleOptionClick("transcriber")}
            >
              <Icon fontSize="large">edit_note_outlined</Icon>
              <MDTypography variant="body2">{lang("ucfirst")("G_TRANSCRIBER")}</MDTypography>
            </div>
          </Grid>
          <Grid item xs={12} sm={4}>
            <div
              style={{
                border: selectedOption === "voice" ? "1px solid grey" : "none",
                backgroundColor: myTheme.palette.background.default,
                padding: "20px",
                textAlign: "center",
                opacity: 0.4,
                minWidth: "130px",
                borderRadius: "16px",
                //cursor: "pointer",
              }}
              //onClick={() => handleOptionClick("voice")}
            >
              <Icon fontSize="large"> graphic_eq_outlined</Icon>
              <MDTypography variant="body2">{lang("ucfirst")("G_VOICE")}</MDTypography>
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <MDButton onClick={() => onSubmit(selectedOption)} color={btnColor} autoFocus>
          Next
        </MDButton>
      </DialogActions>
    </Dialog>
  );
};

AddProviderDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  btnColor: PropTypes.string.isRequired,
  darkMode: PropTypes.bool.isRequired,
};

export default AddProviderDialog;
