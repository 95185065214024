import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { useMaterialUIController } from "context";
import { useChatAssistantService } from "services";
import { CircularProgress } from "@mui/material";
import { MDBox } from "components";
import DuplicateRecordDialog from "components/Dialogs/DuplicateRecordDialog";
import MoveRecordDialog from "components/Dialogs/MoveRecordDialog";
import { useSnackbar, lang } from "hooks";
import { RecordsGrid } from "./components";
import theme from "assets/theme";
import { formatErrorMsg } from "utils";
import { useUserContext } from "context/UserContext";

const RecordsList = ({ onEdit, records, setRecords }) => {
  const { getChatAssistants, duplicateChatAssistant, moveChatAssistant } =
    useChatAssistantService();
  const [loading, setLoading] = useState(true);
  const [duplicateDialogOpen, setDuplicateDialogOpen] = useState(false);
  const [duplicateDialogTitle, setDuplicateDialogTitle] = useState("");
  const [moveDialogOpen, setMoveDialogOpen] = useState(false);
  const [moveDialogTitle, setMoveDialogTitle] = useState("");
  const [currentSid, setCurrentSid] = useState(null);
  const [currentName, setCurrentName] = useState("");
  const { workspaces } = useUserContext();
  const { showSnackbar, SnackbarComponent } = useSnackbar();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const fetchRecords = useCallback(async () => {
    try {
      const recordsArray = await getChatAssistants();
      setRecords(recordsArray.data);
      console.log(recordsArray.data);
    } catch (error) {
      console.error("Error fetching records:", error);
    } finally {
      setLoading(false);
    }
  }, [setRecords]);

  useEffect(() => {
    fetchRecords();
  }, [fetchRecords]);

  const handleViewDetails = (sid) => {
    onEdit(sid);
  };

  const getNameBySid = (sidToMatch) => {
    const matchedAgent = records.find((agent) => agent.sid === sidToMatch);
    return matchedAgent ? matchedAgent.name : null;
  };

  const handleRecordDuplicate = (sid) => {
    setDuplicateDialogTitle(`${lang("ucwords")("CHAT_ASSISTANT_DUPLICATE_TITLE")} (sid: ${sid})`);
    setCurrentSid(sid);
    const name = getNameBySid(sid);
    setCurrentName(name);
    setDuplicateDialogOpen(true);
  };

  const handleDoDuplicateRecord = async (name) => {
    try {
      const result = await duplicateChatAssistant({ sid: currentSid, name: name });
      if (result.status_code === 200) {
        fetchRecords();
        showSnackbar("success", lang("ucfirst")("ASSISTANT_DUPLICATE_SUCCESS"));
      } else if ((errorMsg = formatErrorMsg(response.data))) {
        setFormErrors(collectKeys(response.data.details));
        showSnackbar("error", errorMsg);
      } else {
        showSnackbar("error", lang("ucfirst")("G_FORM_ERROR"));
      }
      setDuplicateDialogOpen(false);
    } catch (error) {
      showSnackbar("error", lang("ucfirst")("ASSISTANT_DUPLICATE_FAILED"));
      console.error("Error duplicating record:", error);
      setDuplicateDialogOpen(false);
    }
  };

  const handleDuplicateDialogClose = () => {
    setDuplicateDialogOpen(false);
  };

  const handleRecordMove = (sid) => {
    setMoveDialogTitle(`${lang("ucwords")("CHAT_ASSISTANT_MOVE_TITLE")} (sid: ${sid})`);
    setCurrentSid(sid);
    setMoveDialogOpen(true);
  };

  const handleDoMoveRecord = async (newWorkspace) => {
    try {
      const result = await moveChatAssistant({ sid: currentSid, new_workspace: newWorkspace });
      if (result.status_code === 200) {
        fetchRecords();
        showSnackbar("success", lang("ucfirst")("ASSISTANT_MOVE_SUCCESS"));
      } else if ((errorMsg = formatErrorMsg(response.data))) {
        setFormErrors(collectKeys(response.data.details));
        showSnackbar("error", errorMsg);
      } else {
        showSnackbar("error", lang("ucfirst")("G_FORM_ERROR"));
      }
      setMoveDialogOpen(false);
    } catch (error) {
      showSnackbar("error", lang("ucfirst")("ASSISTANT_MOVE_FAILED"));
      console.error("Error moving record:", error);
      setMoveDialogOpen(false);
    }
  };

  const handleMoveDialogClose = () => {
    setMoveDialogOpen(false);
  };

  return (
    <>
      <DuplicateRecordDialog
        open={duplicateDialogOpen}
        onClose={handleDuplicateDialogClose}
        title={duplicateDialogTitle}
        onButtonClick={handleDoDuplicateRecord}
        buttonText={lang("ucwords")("G_DUPLICATE")}
        closeOnBackdropClick={true}
        name={currentName}
      />
      <MoveRecordDialog
        open={moveDialogOpen}
        onClose={handleMoveDialogClose}
        title={moveDialogTitle}
        onButtonClick={handleDoMoveRecord}
        buttonText={lang("ucwords")("G_MOVE")}
        closeOnBackdropClick={true}
        alertText={lang("ucfirst")("ASSISTANT_MOVE_ALERT_MSG")}
      />
      {SnackbarComponent}
      {loading ? (
        <MDBox
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress
            sx={{
              color: darkMode ? theme.palette.white.main : theme.palette.dark.main,
            }}
          />
        </MDBox>
      ) : (
        <RecordsGrid
          records={records}
          onViewDetails={handleViewDetails}
          duplicateAction={handleRecordDuplicate}
          moveAction={Object.keys(workspaces).length > 1 ? handleRecordMove : undefined}
        />
      )}
    </>
  );
};

RecordsList.propTypes = {
  onEdit: PropTypes.func.isRequired,
  records: PropTypes.array.isRequired,
  setRecords: PropTypes.func.isRequired,
};

export default RecordsList;
