import React, { useState, useEffect } from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { Icon } from "@mui/material";
import theme from "assets/theme";
import themeDark from "assets/theme-dark";
import { getBroswerTheme } from "components/Misc";
import { useMaterialUIController, setDarkMode } from "context";
import { getUiConfig, setUiConfig } from "localStorage";

const TripleToggleSwitch = () => {
  const [selected, setSelected] = useState("");
  const [controller, dispatch] = useMaterialUIController();
  const { darkMode, sidenavColor } = controller;
  const myTheme = darkMode ? themeDark : theme;

  const handleChange = (event, mode) => {
    if (mode !== null) {
      if (mode === "auto") {
        const darkMode = getBroswerTheme();
        setDarkMode(dispatch, darkMode);
      } else {
        setDarkMode(dispatch, mode === "dark");
      }
      setUiConfig({ theme_mode: mode });
      setSelected(mode);
    }
  };

  useEffect(() => {
    const theme_mode = getUiConfig("theme_mode");
    const themeMode = theme_mode ? theme_mode : "auto";

    if (themeMode === "auto") {
      const browserMode = getBroswerTheme();
      setDarkMode(dispatch, browserMode);
      setSelected("auto");
    } else {
      setDarkMode(dispatch, themeMode === "dark");
      setSelected(themeMode);
    }
    if (!theme_mode) {
      setUiConfig({ theme_mode: themeMode });
    }
  }, [dispatch]);

  const getColor = () => {
    if (darkMode) {
      return "white";
    }
    return "black";
  };

  return (
    <ToggleButtonGroup
      value={selected}
      exclusive
      onChange={handleChange}
      aria-label="triple toggle switch"
      sx={{ display: "flex", justifyContent: "center" }}
    >
      <ToggleButton
        value="light"
        aria-label="Light Mode"
        sx={{
          padding: "4px",
          width: "20px",
          height: "20px",
          marginTop: "2px",
          color: selected === "light" ? "white" : "#746b6b",
          backgroundColor:
            selected === "light"
              ? `${myTheme.palette[sidenavColor].main} !important`
              : "transparent",
          borderRadius: "8px 0 0 8px  !important",
          borderTop: `1px solid ${darkMode ? "white" : "inherit"} !important`,
          borderRight: `0px solid ${darkMode ? "white" : "inherit"} !important`,
          borderBottom: `1px solid ${darkMode ? "white" : "inherit"} !important`,
          borderLeft: `1px solid ${darkMode ? "white" : "inherit"} !important`,
        }}
      >
        <Icon
          sx={{
            fontSize: "16px",
            color: `${selected === "light" ? "white" : "#bbb0b0"} !important`,
          }}
        >
          light_mode
        </Icon>
      </ToggleButton>
      <ToggleButton
        value="dark"
        aria-label="Dark Mode"
        sx={{
          padding: "4px",
          width: "20px",
          height: "20px",
          marginTop: "2px",
          color: selected === "dark" ? "white" : "#746b6b",
          backgroundColor:
            selected === "dark"
              ? `${myTheme.palette[sidenavColor].main} !important`
              : "transparent",
          borderRadius: "0  !important",
          borderTop: `1px solid ${darkMode ? "white" : "inherit"} !important`,
          borderRight: `0px solid ${darkMode ? "white" : "inherit"} !important`,
          borderBottom: `1px solid ${darkMode ? "white" : "inherit"} !important`,
          borderLeft: `0px solid ${darkMode ? "white" : "inherit"} !important`,
        }}
      >
        <Icon
          sx={{
            fontSize: "16px",
            color: `${selected === "dark" ? "white" : "#bbb0b0"} !important`,
          }}
        >
          dark_mode
        </Icon>
      </ToggleButton>
      <ToggleButton
        value="auto"
        aria-label="Auto"
        sx={{
          padding: "4px",
          width: "20px",
          height: "20px",
          marginTop: "2px",
          color: selected === "auto" ? "white" : "#746b6b",
          backgroundColor:
            selected === "auto"
              ? `${myTheme.palette[sidenavColor].main} !important`
              : "transparent",
          borderRadius: "0 8px 8px 0  !important",
          borderTop: `1px solid ${darkMode ? "white" : "inherit"} !important`,
          borderRight: `1px solid ${darkMode ? "white" : "inherit"} !important`,
          borderBottom: `1px solid ${darkMode ? "white" : "inherit"} !important`,
          borderLeft: `0px solid ${darkMode ? "white" : "inherit"} !important`,
        }}
      >
        <Icon
          sx={{
            fontSize: "16px",
            color: `${selected === "auto" ? "white" : "#bbb0b0"} !important`,
          }}
        >
          hdr_auto
        </Icon>
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default TripleToggleSwitch;
