import React, { useState, useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { Mic, StopCircleOutlined, CloudDownload } from "@mui/icons-material";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { LiveAudioVisualizer } from "react-audio-visualize";
import { testConversation, backendAddress } from "configDefaults";
import axios from "axios";
import { useMaterialUIController } from "context";
//import { testConversationEnginesDefaults } from "configDefaults";

let playEndTimestamp = null;

const GreenRoundButton = styled(Button)(({ theme }) => ({
  backgroundColor: "green",
  color: "white",
  borderRadius: "50%",
  width: "100px",
  height: "100px",
  padding: theme.spacing(2),
  "&:hover": {
    backgroundColor: "darkgreen",
  },
}));

const RedRoundButton = styled(Button)(({ theme }) => ({
  backgroundColor: "red",
  color: "white",
  borderRadius: "50%",
  width: "100px",
  height: "100px",
  padding: theme.spacing(2),
  "&:hover": {
    backgroundColor: "darkred",
  },
}));

const StyledCard = styled(Card)(({ theme }) => ({
  margin: theme.spacing(2),
  padding: theme.spacing(2),
}));

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const TestConversation = () => {
  const [formVisible, setFormVisible] = useState(false);
  const [isMicOn, setIsMicOn] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [conversationHistory, setConversationHistory] = useState([]);
  const [debugLevel, setDebugLevel] = useState(0);
  const micStreamRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const recordedChunksRef = useRef([]);
  const formDefaults = testConversation.formDefaults;
  const enginesDefaults = testConversation.enginesDefaults;
  const outputRef = useRef(null);
  const [formData, setFormData] = useState({
    systemMessage: formDefaults.systemMessage,
    maxHistory: formDefaults.maxHistory,
    selectSTTSource: formDefaults.selectSTTSource,
    selectLLMSource: formDefaults.selectLLMSource,
    selectTTSSource: formDefaults.selectTTSSource,
    selectVCSource: formDefaults.selectVCSource,
    llmConfig: formDefaults.llmConfig,
    fcConfig: formDefaults.fcConfig,
    ttsConfig: formDefaults.ttsConfig,
    sttConfig: formDefaults.sttConfig,
    vcConfig: formDefaults.vcConfig,
    debugLevel: testConversation.debugLevel,
  });
  const [, forceUpdate] = useState(false);
  const currentMediaRecorder = mediaRecorderRef.current;
  const [selectedSTTSource, setSelectedSTTSource] = useState(formDefaults.selectSTTSource);
  const [selectedLLMSource, setSelectedLLMSource] = useState(formDefaults.selectLLMSource);
  const [selectedTTSSource, setSelectedTTSSource] = useState(formDefaults.selectTTSSource);
  const [selectedVCSource, setSelectedVCSource] = useState(formDefaults.selectVCSource);
  const [voiceSpeaking, setVoiceSpeaking] = useState(null);
  const [audio, setAudio] = useState(null);
  const audioRef = useRef(null);
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const canvasRef = useRef();
  const source = useRef();
  const analyzer = useRef();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [timestamps, setTimestamps] = useState([]);
  const [audioBlobs, setAudioBlobs] = useState([]);
  const [combinedAudioUrl, setCombinedAudioUrl] = useState("");
  const [downloadButton, setDownloadButton] = useState(false);

  const handleAudioPlay = () => {
    if (playEndTimestamp) {
      const silenceDuration = Date.now() - playEndTimestamp;
      setTimestamps((prevTimestamps) => [...prevTimestamps, silenceDuration]);
    }
    playEndTimestamp = null;
    setIsAudioPlaying(true);
    let audioContext = new AudioContext();
    source.current = audioContext.createMediaElementSource(audioRef.current);
    analyzer.current = audioContext.createAnalyser();
    source.current.connect(analyzer.current);
    analyzer.current.connect(audioContext.destination);
    visualizeData();
  };

  let animationController;

  const visualizeData = () => {
    animationController = window.requestAnimationFrame(visualizeData);
    if (audioRef.current.paused) {
      return cancelAnimationFrame(animationController);
    }
    const songData = new Uint8Array(140);
    analyzer.current.getByteFrequencyData(songData);
    const bar_width = 1;
    let start = 0;
    const ctx = canvasRef.current.getContext("2d");
    ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    for (let i = 0; i < songData.length; i++) {
      // compute x coordinate where we would draw
      start = i * 4;
      //create a gradient for the  whole canvas
      let gradient = ctx.createLinearGradient(
        0,
        0,
        canvasRef.current.width,
        canvasRef.current.height
      );
      gradient.addColorStop(0.2, "#2392f5");
      gradient.addColorStop(0.2, "#2392f5");
      gradient.addColorStop(1.0, "#2392f5");
      //gradient.addColorStop(0.5, "#fe0095");
      //gradient.addColorStop(1.0, "purple");
      ctx.fillStyle = gradient;
      ctx.fillRect(start, canvasRef.current.height, bar_width, -songData[i]);
    }
  };

  const stopAudio = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      setAudio(null);
      setVoiceSpeaking(null); // Ensure voiceSpeaking state is cleared
      // Additional cleanup for canvas if needed
      const ctx = canvasRef.current.getContext("2d");
      ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    }
  };

  const handleAudioEnded = () => {
    cancelAnimationFrame(animationController);
    setVoiceSpeaking(null);
    setAudio(null);
    setTimestamps((prevTimestamps) => [...prevTimestamps, "2"]);
  };

  const handleChangeSTTSource = (event) => {
    setSelectedSTTSource(event.target.value);
    handleInputChange(event);
  };
  const handleChangeLLMSource = (event) => {
    setSelectedLLMSource(event.target.value);
    handleInputChange(event);
  };
  const handleChangeTTSSource = (event) => {
    setSelectedTTSSource(event.target.value);
    handleInputChange(event);
  };

  const handleChangeVCSource = (event) => {
    setSelectedVCSource(event.target.value);
    handleInputChange(event);
  };

  const handleClearOutput = () => {
    if (outputRef.current) {
      outputRef.current.value = "";
    }
    setConversationHistory([{ role: "system", content: formData.systemMessage }]);
    updateOutput("Started new conversation", 0);
    setAudioBlobs([]);
    setTimestamps([]);
    setCombinedAudioUrl("");
    setDownloadButton(false);
  };

  const addAudioBlob = (blob) => {
    setAudioBlobs((prevBlobs) => [...prevBlobs, blob]);
  };

  const handleDownload = () => {
    if (combinedAudioUrl) {
      const link = document.createElement("a");
      link.href = combinedAudioUrl;
      link.download = "combined_audio.webm"; // Adjust filename and extension as needed
      link.click();
    }
  };

  useEffect(() => {
    if (combinedAudioUrl) {
      handleDownload();
      setCombinedAudioUrl("");
    }
  }, [combinedAudioUrl]);

  function createSilenceBlob(seconds = 1) {
    const sampleRate = 8000;
    const numChannels = 1;
    const bitsPerSample = 8;
    const blockAlign = (numChannels * bitsPerSample) / 8;
    const byteRate = sampleRate * blockAlign;
    const dataSize = Math.ceil(seconds * sampleRate) * blockAlign;
    const chunkSize = 36 + dataSize;
    const byteLength = 8 + chunkSize;
    const buffer = new ArrayBuffer(byteLength);
    const view = new DataView(buffer);

    view.setUint32(0, 0x52494646, false); // Chunk ID 'RIFF'
    view.setUint32(4, chunkSize, true); // File size
    view.setUint32(8, 0x57415645, false); // Format 'WAVE'
    view.setUint32(12, 0x666d7420, false); // Sub-chunk 1 ID 'fmt '
    view.setUint32(16, 16, true); // Sub-chunk 1 size
    view.setUint16(20, 1, true); // Audio format
    view.setUint16(22, numChannels, true); // Number of channels
    view.setUint32(24, sampleRate, true); // Sample rate
    view.setUint32(28, byteRate, true); // Byte rate
    view.setUint16(32, blockAlign, true); // Block align
    view.setUint16(34, bitsPerSample, true); // Bits per sample
    view.setUint32(36, 0x64617461, false); // Sub-chunk 2 ID 'data'
    view.setUint32(40, dataSize, true); // Sub-chunk 2 size

    for (let offset = 44; offset < byteLength; offset++) {
      view.setUint8(offset, 128);
    }
    const blob = new Blob([view], { type: "audio/wav" });
    return blob;
  }

  const sendCombinedBlobsToBackend = async (combinedBlobs) => {
    try {
      let formData = new FormData();
      for (let i = 0; i < combinedBlobs.length; i++) {
        formData.append("audio", combinedBlobs[i], `audio${i}.wav`);
      }
      const response = await fetch(backendAddress + "/concatenate-audio", {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`Failed to upload audio: ${response.status} ${response.statusText}`);
      }
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      setCombinedAudioUrl(url, response.data);
      updateOutput("Debug Blob URL created: " + url, 2);
    } catch (error) {
      updateOutput("Error uploading combined audio blobs:", error);
    }
  };

  const combineAudioBlobs = async () => {
    updateOutput("Combining audio blobs", 2);
    try {
      let combinedBlobs = [];
      for (let i = 0; i < audioBlobs.length; i++) {
        combinedBlobs.push(audioBlobs[i]);
        if (i < audioBlobs.length - 1) {
          let silenceDuration = timestamps[i];
          if (isNaN(silenceDuration)) {
            console.warn(`Silence Duration: NaN at index ${i}`);
            continue;
          }
          if (typeof silenceDuration === "string") {
            silenceDuration = parseFloat(silenceDuration);
          } else {
            silenceDuration /= 1000;
          }
          const silenceBlob = createSilenceBlob(silenceDuration);
          combinedBlobs.push(silenceBlob);
        }
      }
      sendCombinedBlobsToBackend(combinedBlobs);
    } catch (error) {
      updateOutput("Error combining audio blobs:" + error, 0);
    }
  };

  function useDebounce(callback, delay) {
    const [args, setArgs] = useState(null);

    useEffect(() => {
      if (args === null) return;
      const handler = setTimeout(() => {
        callback(...args);
        setArgs(null);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    }, [args, callback, delay]);

    return useCallback((...args) => {
      setArgs(args);
    }, []);
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    if (name == "systemMessage") {
      debounceUpdateConversationHistory(value);
    } else if (name == "debugLevel") {
      setDebugLevel(parseInt(value));
    } else if (name == "selectSTTSource") {
      let sttConfig = null;
      switch (value) {
        case "openai":
          sttConfig = enginesDefaults.openAISTTConfig;
          break;
        case "fast-whisper":
          sttConfig = enginesDefaults.fwSTTConfig;
          break;
        default:
          sttConfig = enginesDefaults.openAISTTConfig;
      }
      setFormData((prevFormData) => ({
        ...prevFormData,
        sttConfig: sttConfig,
      }));
    } else if (name == "selectLLMSource") {
      let llmConfig = null;
      let fcConfig = null;
      switch (value) {
        case "openai":
          llmConfig = enginesDefaults.openAILLMConfig;
          fcConfig = enginesDefaults.openAIFCConfig;
          break;
        case "ollama":
          llmConfig = enginesDefaults.ollamaLLMConfig;
          fcConfig = enginesDefaults.ollamaFCConfig;
          break;
        case "lmstudio":
          llmConfig = enginesDefaults.lmStudioLLMConfig;
          fcConfig = enginesDefaults.lmStudioFCConfig;
          break;
        default:
          llmConfig = enginesDefaults.openAILLMConfig;
          fcConfig = enginesDefaults.openAIFCConfig;
      }
      setFormData((prevFormData) => ({
        ...prevFormData,
        llmConfig: llmConfig,
        fcConfig: fcConfig,
      }));
    } else if (name == "selectTTSSource") {
      let ttsConfig = null;
      switch (value) {
        case "openai":
          ttsConfig = enginesDefaults.openAITTSConfig;
          break;
        case "openvoicev1":
          ttsConfig = enginesDefaults.openVoiceV1Config;
          break;
        case "openvoicev2":
          ttsConfig = enginesDefaults.openVoiceV2Config;
          break;
        case "elevenlabs":
          ttsConfig = enginesDefaults.elevenLabsTTSConfig;
          break;
        default:
          ttsConfig = enginesDefaults.openAITTSConfig;
      }
      setFormData((prevFormData) => ({
        ...prevFormData,
        ttsConfig: ttsConfig,
      }));
    } else if (name == "selectVCSource") {
      let vcConfig = null;
      switch (value) {
        case "openvoicev1":
          vcConfig = enginesDefaults.openVoiceV1VCConfig;
          break;
        case "openvoicev2":
          vcConfig = enginesDefaults.openVoiceV2VCConfig;
          break;
        //case "elevenlabs":
        //  vcConfig = enginesDefaults.elevenLabsTTSConfig;
        //  break;
        default:
          vcConfig = enginesDefaults.defaultVCConfig;
      }
      setFormData((prevFormData) => ({
        ...prevFormData,
        vcConfig: vcConfig,
      }));
    }
  };

  const debounceUpdateConversationHistory = useDebounce((value) => {
    setConversationHistory([{ role: "system", content: value }]);
    updateOutput("Started new conversation", 0);
  }, 2000);

  useEffect(() => {
    setConversationHistory([{ role: "system", content: formDefaults.systemMessage }]);
    setDebugLevel(testConversation.debugLevel);
  }, []);

  useEffect(() => {
    if (isMicOn) {
      startMic();
    } else {
      stopMic();
    }
  }, [isMicOn]);

  useEffect(() => {
    if (!voiceSpeaking && audioBlobs.length > 0) {
      setDownloadButton(true);
    } else {
      setDownloadButton(false);
    }
  }, [voiceSpeaking, audioBlobs]);

  const toggleFormVisibility = () => setFormVisible(!formVisible);
  const toggleMic = () => setIsMicOn(!isMicOn);

  const startMic = async () => {
    try {
      setDownloadButton(false);
      updateOutput("Starting microphone", 2);
      micStreamRef.current = await navigator.mediaDevices.getUserMedia({ audio: true });
      updateOutput("Microphone started", 1);
      mediaRecorderRef.current = new MediaRecorder(micStreamRef.current);
      recordedChunksRef.current = [];
      mediaRecorderRef.current.ondataavailable = (e) => {
        if (e.data.size > 0) {
          recordedChunksRef.current.push(e.data);
        }
      };
      mediaRecorderRef.current.start();
      updateOutput("Started media recorder", 1);
      setIsRecording(true);
    } catch (error) {
      updateOutput("Error accessing microphone", 0);
      updateOutput(error, 0, "console");
    }
  };

  const stopMic = () => {
    playEndTimestamp = Date.now();
    if (mediaRecorderRef.current && mediaRecorderRef.current.state === "recording") {
      mediaRecorderRef.current.stop();
      updateOutput("Stopped media recorder", 1);
      setTimeout(() => {
        const blob = new Blob(recordedChunksRef.current, { type: "audio/webm" });
        if (debugLevel > 1) {
          const blobUrl = URL.createObjectURL(blob);
          updateOutput("Debug Blob URL created: " + blobUrl, 2);
        }
        addAudioBlob(blob);
        sendBlobToServer(blob);
        recordedChunksRef.current = []; // Clear recorded chunks for next recording
        setIsRecording(false);
      }, 50);
    }
    if (micStreamRef.current) {
      updateOutput("Stopping microphone", 2);
      micStreamRef.current.getTracks().forEach((track) => {
        track.stop();
        updateOutput("Microphone stopped", 1);
      });
      micStreamRef.current = null; // Reset or clear micStreamRef after stopping
    }
  };

  const sendBlobToServer = async (blob) => {
    try {
      updateOutput("Getting STT data", 2);
      const sendData = new FormData();
      sendData.append("audio", blob, "recorded_audio.webm"); // Adjust file name and type as needed
      const form = document.getElementById("conversationForm");
      const maxHistory = formData.maxHistory;
      const sttSource = formData.selectSTTSource;
      const sttConfigObject = JSON.parse(formData.sttConfig);
      const url = backendAddress + "/speech-to-text/" + sttSource;
      const params = new URLSearchParams(sttConfigObject);
      params.forEach((value, key) => {
        sendData.append(key, value);
      });
      const response = await fetch(url, {
        method: "POST",
        body: sendData,
      });
      const elapsed_time = response.headers.get("X-Elapsed-Time");
      const formatted_time = parseFloat(elapsed_time).toFixed(4);
      const data = await response.json();
      let message = "";
      if (response.status === 200) {
        message = data["result"]["data"].text;
      } else {
        message = response.status + " " + data["result"].message;
      }
      updateOutput("(" + formatted_time + "ms) STT result: " + message, 0);
      if (message) {
        sendSTTToServer(message, maxHistory);
      }
    } catch (error) {
      let errorMessage = "Error getting STT data";
      if (error.response) {
        const elapsed_time = error.response.headers.get("X-Elapsed-Time");
        const formatted_time = parseFloat(elapsed_time).toFixed(4);
        errorMessage = "(" + formatted_time + "ms) " + errorMessage;
        errorMessage += ": " + error.response.data.result.message;
      } else {
        errorMessage += ": " + error.message;
      }
      updateOutput(errorMessage, 0);
    }
  };

  const sendSTTToServer = async (message, maxHistory) => {
    try {
      updateOutput("Getting LLM data", 2);
      const form = document.getElementById("conversationForm");
      const llmSource = formData.selectLLMSource;
      const llmConfigObject = JSON.parse(formData.llmConfig);
      const url = backendAddress + "/chat/" + llmSource;
      const params = {
        __conversation_history: conversationHistory,
        __message: message,
        __max_conversation_history: maxHistory, // Replace with your desired max history value
        ...llmConfigObject,
      };
      if (formData.fcConfig) {
        const fcConfig = JSON.parse(formData.fcConfig);
        params["tools"] = fcConfig;
      }
      const response = await axios.post(url, params);
      const elapsed_time = response.headers.get("X-Elapsed-Time");
      const formatted_time = parseFloat(elapsed_time).toFixed(4);
      const data = await response.data;
      let response_message = "";
      let conversation_history = "";
      if (response.status == 200) {
        response_message = data["result"]["data"].response_message;
        conversation_history = data["result"]["data"].conversation_history;
        setConversationHistory(conversation_history);
        //sendLLMToServer(response_message);
      } else {
        response_message = response.status + " " + data["result"].message;
      }
      updateOutput("(" + formatted_time + "ms) LLM result: " + response_message, 0);
      if (response.status == 200) {
        sendLLMToServer(response_message);
      }
    } catch (error) {
      let errorMessage = "Error getting LLM data";
      if (error.response) {
        const elapsed_time = error.response.headers.get("X-Elapsed-Time");
        const formatted_time = parseFloat(elapsed_time).toFixed(4);
        errorMessage = "(" + formatted_time + "ms) " + errorMessage;
        errorMessage += ": " + error.response.data.result.message;
      } else {
        errorMessage += ": " + error.message;
      }
      updateOutput(errorMessage, 0);
    }
  };

  const sendLLMToServer = async (message) => {
    try {
      updateOutput("Getting TTS data", 2);
      const form = document.getElementById("conversationForm");
      const url = backendAddress + "/text-to-speech/" + formData.selectTTSSource;
      const params = {
        input: message,
        ...JSON.parse(formData.ttsConfig),
      };
      const response = await axios.post(url, params);
      const elapsed_time = response.headers.get("X-Elapsed-Time");
      const formatted_time = parseFloat(elapsed_time).toFixed(4);
      const data = await response.data;
      let response_message = "";
      let blobUrl = "";
      let audioBlob = null;
      if (response.status == 200) {
        const audio = data["result"]["data"].audio_base64;
        const byteCharacters = atob(audio);
        const byteArrays = [];
        for (let i = 0; i < byteCharacters.length; i++) {
          byteArrays.push(byteCharacters.charCodeAt(i));
        }
        const binaryData = new Uint8Array(byteArrays);
        response_message = "Returned audio " + binaryData.length + " bytes, playing now";
        audioBlob = new Blob([binaryData], { type: "audio/wav" });
        blobUrl = URL.createObjectURL(audioBlob);
        if (formData.selectVCSource == "none") {
          const audioElement = new Audio(blobUrl);
          setVoiceSpeaking(audioBlob);
          addAudioBlob(audioBlob);
          audioRef.current = audioElement; // Store the audio element in the ref
          audioElement.addEventListener("ended", handleAudioEnded);
          audioElement.addEventListener("play", handleAudioPlay);
          audioElement.addEventListener("pause", () => setIsAudioPlaying(false));
          setAudio(audioElement);
          audioElement.play();
        }
      } else {
        response_message = response.status + " " + data["result"].message;
      }
      updateOutput("(" + formatted_time + "ms) TTS result: " + response_message, 0);
      if (blobUrl) {
        updateOutput("Debug Blob URL created: " + blobUrl, 2);
      }
      if (formData.selectVCSource != "none") {
        sendVCBlobToServer(audioBlob);
      }
    } catch (error) {
      let errorMessage = "Error getting TTS data";
      if (error.response) {
        const elapsed_time = error.response.headers.get("X-Elapsed-Time");
        const formatted_time = parseFloat(elapsed_time).toFixed(4);
        errorMessage = "(" + formatted_time + "ms) " + errorMessage;
        errorMessage += ": " + error.response.data.result.message;
      } else {
        errorMessage += ": " + error.message;
      }
      updateOutput(errorMessage, 0);
    }
  };

  const sendVCBlobToServer = async (blob) => {
    try {
      updateOutput("Getting SV data", 2);
      const sendData = new FormData();
      sendData.append("audio", blob, "recorded_audio.wav");
      const form = document.getElementById("conversationForm");
      const maxHistory = formData.maxHistory;
      const vcSource = formData.selectVCSource;
      const vcConfigObject = JSON.parse(formData.vcConfig);
      const url = backendAddress + "/convert-voice/" + vcSource;
      const params = new URLSearchParams(vcConfigObject);
      params.forEach((value, key) => {
        sendData.append(key, value);
      });
      const response = await fetch(url, {
        method: "POST",
        body: sendData,
      });
      const elapsed_time = response.headers.get("X-Elapsed-Time");
      const formatted_time = parseFloat(elapsed_time).toFixed(4);
      const data = await response.json();
      let message = "";
      let blobUrl = "";
      let audioBlob = null;
      if (response.status === 200) {
        const audio = data["result"]["data"].audio_base64;
        const byteCharacters = atob(audio);
        const byteArrays = [];
        for (let i = 0; i < byteCharacters.length; i++) {
          byteArrays.push(byteCharacters.charCodeAt(i));
        }
        const binaryData = new Uint8Array(byteArrays);
        message = "Returned converted audio " + binaryData.length + " bytes, playing now";
        audioBlob = new Blob([binaryData], { type: "audio/wav" });
        blobUrl = URL.createObjectURL(audioBlob);
        const audioElement = new Audio(blobUrl);
        setVoiceSpeaking(audioBlob);
        addAudioBlob(audioBlob);
        audioRef.current = audioElement; // Store the audio element in the ref
        audioElement.addEventListener("ended", handleAudioEnded);
        audioElement.addEventListener("play", handleAudioPlay);
        audioElement.addEventListener("pause", () => setIsAudioPlaying(false));
        setAudio(audioElement);
        audioElement.play();
      } else {
        message = response.status + " " + data["result"].message;
      }
      updateOutput("(" + formatted_time + "ms) VC result: " + message, 0);
      if (blobUrl) {
        updateOutput("Debug Blob URL created: " + blobUrl, 2);
      }
    } catch (error) {
      let errorMessage = "Error getting VC data";
      if (error.response) {
        const elapsed_time = error.response.headers.get("X-Elapsed-Time");
        const formatted_time = parseFloat(elapsed_time).toFixed(4);
        errorMessage = "(" + formatted_time + "ms) " + errorMessage;
        errorMessage += ": " + error.response.data.result.message;
      } else {
        errorMessage += ": " + error.message;
      }
      updateOutput(errorMessage, 0);
    }
  };

  const updateOutput = (text, debug = 0, output = "all") => {
    if (outputRef.current && debugLevel >= debug) {
      switch (debug) {
        case 0:
          text = "--- " + text + " ---\n";
          break;
        case 1:
          text = "=== " + text + " ===\n";
          break;
        case 2:
          text = ">>> " + text + " <<<\n";
          break;
        default:
          text = text + "\n";
          break;
      }
      if (output === "view" || output === "all") {
        outputRef.current.value += text;
        outputRef.current.scrollTop = outputRef.current.scrollHeight;
      }
      if (output === "console" || output === "all") {
        console.log(text);
      }
    }
  };

  const [value, setValue] = useState(0);

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container justifyContent="center" style={{ minHeight: "100vh" }}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <StyledCard>
            <Button onClick={toggleFormVisibility}>
              {formVisible ? "Hide Config" : "Show Config"}
            </Button>
            {formVisible && (
              <Box mt={2}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                  <Tab label="General" {...a11yProps(0)} />
                  <Tab label="Speech to Text" {...a11yProps(1)} />
                  <Tab label="Language Model" {...a11yProps(2)} />
                  <Tab label="Text to Speech" {...a11yProps(3)} />
                  <Tab label="Voice Converter" {...a11yProps(4)} />
                </Tabs>

                <form id="conversationForm">
                  <CustomTabPanel value={value} index={0}>
                    <TextField
                      fullWidth
                      id="systemMessage"
                      name="systemMessage"
                      label="System Message"
                      value={formData.systemMessage}
                      onChange={handleInputChange}
                      multiline
                      rows={3}
                      variant="outlined"
                      margin="normal"
                    />
                    <TextField
                      fullWidth
                      id="debugLevel"
                      name="debugLevel"
                      label="Output Level"
                      type="number"
                      value={formData.debugLevel}
                      InputProps={{ inputProps: { min: 0, max: 2 } }}
                      onChange={handleInputChange}
                      variant="outlined"
                      margin="normal"
                    />
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={1}>
                    <InputLabel htmlFor="selectSTTSource">STT Source</InputLabel>
                    <Select
                      fullWidth
                      id="selectSTTSource"
                      name="selectSTTSource"
                      value={selectedSTTSource}
                      onChange={handleChangeSTTSource}
                      variant="outlined"
                      margin="none"
                      sx={{ minHeight: 40 }}
                    >
                      <MenuItem value="openai">OpenAI</MenuItem>
                      <MenuItem value="fast-whisper">Fast Whisper</MenuItem>
                      {/* Add more options as needed */}
                    </Select>
                    <TextField
                      fullWidth
                      id="sttConfig"
                      name="sttConfig"
                      label="STT Config"
                      value={formData.sttConfig}
                      onChange={handleInputChange}
                      multiline
                      rows={3}
                      variant="outlined"
                      margin="normal"
                    />
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={2}>
                    <InputLabel htmlFor="selectLLMSource">LLM Source</InputLabel>
                    <Select
                      fullWidth
                      id="selectLLMSource"
                      name="selectLLMSource"
                      value={selectedLLMSource}
                      onChange={handleChangeLLMSource}
                      variant="outlined"
                      margin="none"
                      sx={{ minHeight: 40 }}
                    >
                      <MenuItem value="openai">OpenAI</MenuItem>
                      <MenuItem value="ollama">Ollama</MenuItem>
                      <MenuItem value="lmstudio">LM Studio</MenuItem>
                      {/* Add more options as needed */}
                    </Select>
                    <TextField
                      fullWidth
                      id="llmConfig"
                      name="llmConfig"
                      label="LLM Config"
                      value={formData.llmConfig}
                      onChange={handleInputChange}
                      multiline
                      rows={3}
                      variant="outlined"
                      margin="normal"
                    />
                    <TextField
                      fullWidth
                      id="maxHistory"
                      name="maxHistory"
                      label="Conversation History"
                      type="number"
                      value={formData.maxHistory}
                      InputProps={{ inputProps: { min: 1, max: 1000 } }}
                      onChange={handleInputChange}
                      variant="outlined"
                      margin="normal"
                    />
                    <TextField
                      fullWidth
                      id="fcConfig"
                      name="fcConfig"
                      label="Functions"
                      value={formData.fcConfig}
                      onChange={handleInputChange}
                      multiline
                      minRows={3}
                      maxRows={20}
                      variant="outlined"
                      margin="normal"
                    />
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={3}>
                    <InputLabel htmlFor="selectTTSSource">TTS Source</InputLabel>
                    <Select
                      fullWidth
                      id="selectTTSSource"
                      name="selectTTSSource"
                      value={selectedTTSSource}
                      onChange={handleChangeTTSSource}
                      variant="outlined"
                      margin="none"
                      sx={{ minHeight: 40 }}
                    >
                      <MenuItem value="openai">OpenAI</MenuItem>
                      <MenuItem value="elevenlabs">ElevenLabs</MenuItem>
                      <MenuItem value="openvoicev1">OpenVoice V1</MenuItem>
                      <MenuItem value="openvoicev2">OpenVoice V2</MenuItem>
                    </Select>
                    <TextField
                      fullWidth
                      id="ttsConfig"
                      name="ttsConfig"
                      label="TTS Config"
                      value={formData.ttsConfig}
                      onChange={handleInputChange}
                      multiline
                      rows={3}
                      variant="outlined"
                      margin="normal"
                    />
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={4}>
                    <InputLabel htmlFor="selectTTSSource">Voice Converter</InputLabel>
                    <Select
                      fullWidth
                      id="selectVCSource"
                      name="selectVCSource"
                      value={formData.selectVCSource}
                      onChange={handleChangeVCSource}
                      variant="outlined"
                      margin="none"
                      sx={{ minHeight: 40 }}
                    >
                      <MenuItem value="none">None</MenuItem>
                      {/*<MenuItem value="elevenlabs">ElevenLabs</MenuItem>*/}
                      <MenuItem value="openvoicev1">OpenVoice V1</MenuItem>
                      <MenuItem value="openvoicev2">OpenVoice V2</MenuItem>
                    </Select>
                    <TextField
                      fullWidth
                      id="vcConfig"
                      name="vcConfig"
                      label="Voice Converter Config"
                      value={formData.vcConfig}
                      onChange={handleInputChange}
                      multiline
                      rows={3}
                      variant="outlined"
                      margin="normal"
                    />
                  </CustomTabPanel>
                </form>
              </Box>
            )}
            <Box textAlign="center">
              <TextareaAutosize
                ref={outputRef}
                minRows={10}
                aria-label="Output"
                placeholder="Output"
                style={{
                  border: "none",
                  outline: "none",
                  width: "100%",
                  height: "80px",
                  padding: "8px",
                  fontSize: "1rem",
                  resize: "vertical",
                  overflow: "auto",
                  minHeight: "80px",
                  color: darkMode ? "white" : "black",
                  backgroundColor: darkMode ? "#333" : "#fff",
                  //maxHeight: "600px",
                }}
                readOnly
              />
              <Button onClick={handleClearOutput}>Clear Output & Reset Conversation</Button>
            </Box>
          </StyledCard>
          <Grid container justifyContent="center" style={{ marginTop: "20px" }}>
            {isMicOn ? (
              <Grid container direction="column" alignItems="center" justifyContent="center">
                <Typography
                  variant="body1"
                  style={{ color: "red", marginBottom: 8, fontWeight: "bold" }}
                >
                  Press when finished talking
                </Typography>
                <RedRoundButton onClick={toggleMic}>
                  <Mic
                    style={{ fontSize: 50, color: "white" }}
                    ref={(el) => {
                      if (el) {
                        el.style.setProperty("font-size", "50px", "important");
                      }
                    }}
                  />
                </RedRoundButton>
                <Grid item style={{ height: 10 }} />
                {currentMediaRecorder && (
                  <LiveAudioVisualizer
                    key={currentMediaRecorder.state}
                    mediaRecorder={currentMediaRecorder}
                    width={250}
                    height={20}
                  />
                )}
              </Grid>
            ) : (
              <Grid container direction="column" alignItems="center" justifyContent="center">
                <Typography
                  variant="body1"
                  style={{
                    color: darkMode ? "white" : "black",
                    marginBottom: 8,
                    fontWeight: "bold",
                  }}
                >
                  Press to talk
                </Typography>
                {isAudioPlaying ? (
                  <GreenRoundButton onClick={stopAudio}>
                    <StopCircleOutlined
                      style={{ fontSize: 50, color: "white" }}
                      ref={(el) => {
                        if (el) {
                          el.style.setProperty("font-size", "50px", "important");
                        }
                      }}
                    />
                  </GreenRoundButton>
                ) : (
                  <GreenRoundButton onClick={toggleMic}>
                    <Mic
                      style={{ fontSize: 50, color: "white" }}
                      ref={(el) => {
                        if (el) {
                          el.style.setProperty("font-size", "50px", "important");
                        }
                      }}
                    />
                  </GreenRoundButton>
                )}
                <Grid item style={{ height: 10 }} />
                {voiceSpeaking && (
                  <>
                    <canvas ref={canvasRef} width={250} height={20} />
                  </>
                )}
                {downloadButton && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={combineAudioBlobs}
                    style={{ color: "white", marginTop: "10px" }}
                    startIcon={<CloudDownload />}
                  >
                    Download Conversation
                  </Button>
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default TestConversation;
