import React from "react";
import { lang } from "hooks";
import { CardContent, Icon } from "@mui/material";
import { MDTypography, MDBox, CustomHidden } from "components";
import { Panel } from "components";
import PropTypes from "prop-types";

const TipsPanel = ({ darkMode, firstGridHeight = 200 }) => {
  return (
    <Panel darkMode={darkMode} sx={{ minHeight: firstGridHeight }}>
      <CardContent>
        <MDBox mt={1}>
          <CustomHidden only={["xs", "sm", "md"]}>
            <MDTypography variant="h5" fontWeight="regular" sx={{ opacity: 0.6 }}>
              <Icon fontSize="medium" sx={{ transform: "translateY(4px)" }}>
                help_outline
              </Icon>{" "}
              {lang("ucwords")("G_HELP")}
            </MDTypography>
          </CustomHidden>
          {lang("span", {
            "@opacity": darkMode ? "0.6" : "0.8",
            "@color": darkMode ? "lightgrey" : "grey",
          })("API_KEY_TIPS")}
        </MDBox>
      </CardContent>
    </Panel>
  );
};

TipsPanel.propTypes = {
  darkMode: PropTypes.bool.isRequired,
  firstGridHeight: PropTypes.number,
};

export default TipsPanel;
