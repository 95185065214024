import React, { useState, useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { StopCircleOutlined, PlayCircle, CloudDownload } from "@mui/icons-material";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { testConversation, backendAddress } from "configDefaults";
import axios from "axios";
import { useMaterialUIController } from "context";

const GreenRoundButton = styled(Button)(({ theme }) => ({
  backgroundColor: "green",
  color: "white",
  borderRadius: "50%",
  width: "100px",
  height: "100px",
  padding: theme.spacing(2),
  "&:hover": {
    backgroundColor: "darkgreen",
  },
}));

const RedRoundButton = styled(Button)(({ theme }) => ({
  backgroundColor: "red",
  color: "white",
  borderRadius: "50%",
  width: "100px",
  height: "100px",
  padding: theme.spacing(2),
  "&:hover": {
    backgroundColor: "darkred",
  },
}));

const StyledCard = styled(Card)(({ theme }) => ({
  margin: theme.spacing(2),
  padding: theme.spacing(2),
}));

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const BotVsBot = () => {
  const [formVisible, setFormVisible] = useState(false);
  const [isRunning, setIsRunning] = useState(false);
  const [conversationHistory, setConversationHistory] = useState([]);
  const [conversationHistoryBotTwo, setConversationHistoryBotTwo] = useState([]);
  const [debugLevel, setDebugLevel] = useState(0);
  const formDefaults = testConversation.formDefaults;
  const enginesDefaults = testConversation.enginesDefaults;
  const outputRef = useRef(null);
  const defaultSystemMessage = "Keep asking for poems and make short comments.";
  const [formData, setFormData] = useState({
    firstBotName: "Bot 1",
    secondBotName: "Bot 2",
    startMessage: "Tell me a poem!",
    systemMessage: defaultSystemMessage,
    systemMessageBotTwo: formDefaults.systemMessageBotTwo,
    maxHistory: formDefaults.maxHistory,
    maxHistoryBotTwo: formDefaults.maxHistory,
    selectSTTSource: formDefaults.selectSTTSource,
    selectSTTSourceBotTwo: formDefaults.selectTTSSource,
    selectLLMSource: formDefaults.selectLLMSource,
    selectLLMSourceBotTwo: formDefaults.selectLLMSource,
    selectTTSSource: formDefaults.selectTTSSource,
    selectTTSSourceBotTwo: formDefaults.selectTTSSource,
    selectVCSource: formDefaults.selectVCSource,
    selectVCSourceBotTwo: formDefaults.selectVCSource,
    sttConfig: formDefaults.sttConfig,
    sttConfigBotTwo: formDefaults.sttConfig,
    llmConfig: formDefaults.llmConfig,
    fcConfig: formDefaults.fcConfig,
    llmConfigBotTwo: formDefaults.llmConfig,
    fcConfigBotTwo: formDefaults.fcConfig,
    ttsConfig: formDefaults.ttsConfig,
    ttsConfigBotTwo: formDefaults.ttsConfig,
    vcConfig: formDefaults.vcConfig,
    vcConfigBotTwo: formDefaults.vcConfig,
    debugLevel: testConversation.debugLevel,
  });
  const [, forceUpdate] = useState(false);
  const [selectedSTTSource, setSelectedSTTSource] = useState(formDefaults.selectSTTSource);
  const [selectedSTTSourceBotTwo, setSelectedSTTSourceBotTwo] = useState(
    formDefaults.selectSTTSource
  );
  const [selectedLLMSourceBotTwo, setSelectedLLMSourceBotTwo] = useState(
    formDefaults.selectLLMSource
  );
  const [selectedLLMSource, setSelectedLLMSource] = useState(formDefaults.selectLLMSource);
  const [selectedTTSSource, setSelectedTTSSource] = useState(formDefaults.selectTTSSource);
  const [selectedTTSSourceBotTwo, setSelectedTTSSourceBotTwo] = useState(
    formDefaults.selectTTSSource
  );
  const [selectedVCSource, setSelectedVCSource] = useState(formDefaults.selectVCSource);
  const [selectedVCSourceBotTwo, setSelectedVCSourceBotTwo] = useState(formDefaults.selectVCSource);
  const [voiceSpeaking, setVoiceSpeaking] = useState(null);
  const [audio, setAudio] = useState(null);
  const audioRef = useRef(null);
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const canvasRef = useRef();
  const source = useRef();
  const analyzer = useRef();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const runningRef = useRef(false);
  const loopTimeoutRef = useRef(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [formDisabled, setFormDisabled] = useState(false);
  const [timestamps, setTimestamps] = useState([]);
  const [audioBlobs, setAudioBlobs] = useState([]);
  const [combinedAudioUrl, setCombinedAudioUrl] = useState("");
  const [downloadButton, setDownloadButton] = useState(false);

  const addAudioBlob = (blob) => {
    setAudioBlobs((prevBlobs) => [...prevBlobs, blob]);
  };

  const handleDownload = () => {
    if (combinedAudioUrl) {
      const link = document.createElement("a");
      link.href = combinedAudioUrl;
      link.download = "combined_audio.webm"; // Adjust filename and extension as needed
      link.click();
    }
  };

  useEffect(() => {
    if (combinedAudioUrl) {
      handleDownload();
      setCombinedAudioUrl("");
    }
  }, [combinedAudioUrl]);

  function createSilenceBlob(seconds = 1) {
    const sampleRate = 8000;
    const numChannels = 1;
    const bitsPerSample = 8;
    const blockAlign = (numChannels * bitsPerSample) / 8;
    const byteRate = sampleRate * blockAlign;
    const dataSize = Math.ceil(seconds * sampleRate) * blockAlign;
    const chunkSize = 36 + dataSize;
    const byteLength = 8 + chunkSize;
    const buffer = new ArrayBuffer(byteLength);
    const view = new DataView(buffer);

    view.setUint32(0, 0x52494646, false); // Chunk ID 'RIFF'
    view.setUint32(4, chunkSize, true); // File size
    view.setUint32(8, 0x57415645, false); // Format 'WAVE'
    view.setUint32(12, 0x666d7420, false); // Sub-chunk 1 ID 'fmt '
    view.setUint32(16, 16, true); // Sub-chunk 1 size
    view.setUint16(20, 1, true); // Audio format
    view.setUint16(22, numChannels, true); // Number of channels
    view.setUint32(24, sampleRate, true); // Sample rate
    view.setUint32(28, byteRate, true); // Byte rate
    view.setUint16(32, blockAlign, true); // Block align
    view.setUint16(34, bitsPerSample, true); // Bits per sample
    view.setUint32(36, 0x64617461, false); // Sub-chunk 2 ID 'data'
    view.setUint32(40, dataSize, true); // Sub-chunk 2 size

    for (let offset = 44; offset < byteLength; offset++) {
      view.setUint8(offset, 128);
    }
    const blob = new Blob([view], { type: "audio/wav" });
    return blob;
  }

  const sendCombinedBlobsToBackend = async (combinedBlobs) => {
    try {
      let formData = new FormData();
      for (let i = 0; i < combinedBlobs.length; i++) {
        formData.append("audio", combinedBlobs[i], `audio${i}.wav`);
      }
      const response = await fetch(backendAddress + "/concatenate-audio", {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`Failed to upload audio: ${response.status} ${response.statusText}`);
      }
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      setCombinedAudioUrl(url, response.data);
      updateOutput("Debug Blob URL created: " + url, 2);
    } catch (error) {
      updateOutput("Error uploading combined audio blobs:", error);
    }
  };

  const combineAudioBlobs = async () => {
    updateOutput("Combining audio blobs", 2);
    try {
      let combinedBlobs = [];
      for (let i = 0; i < audioBlobs.length; i++) {
        combinedBlobs.push(audioBlobs[i]);
        if (i < audioBlobs.length - 1) {
          const silenceDuration = timestamps[i] / 1000;
          if (isNaN(silenceDuration)) {
            console.warn(`Silence Duration: NaN at index ${i}`);
            continue;
          }
          const silenceBlob = createSilenceBlob(silenceDuration);
          combinedBlobs.push(silenceBlob);
        }
      }
      sendCombinedBlobsToBackend(combinedBlobs);
    } catch (error) {
      updateOutput("Error combining audio blobs:" + error, 0);
    }
  };

  const toggleFormVisibility = () => setFormVisible(!formVisible);
  const toggleMic = () => setIsRunning(!isRunning);

  const handleAudioPlay = () => {
    if (playEndTimestamp) {
      const silenceDuration = Date.now() - playEndTimestamp;
      setTimestamps((prevTimestamps) => [...prevTimestamps, silenceDuration]);
    }
    setIsAudioPlaying(true);
    let audioContext = new AudioContext();
    source.current = audioContext.createMediaElementSource(audioRef.current);
    analyzer.current = audioContext.createAnalyser();
    source.current.connect(analyzer.current);
    analyzer.current.connect(audioContext.destination);
    visualizeData();
  };

  let animationController;

  const visualizeData = () => {
    animationController = window.requestAnimationFrame(visualizeData);
    if (audioRef.current.paused) {
      return cancelAnimationFrame(animationController);
    }
    const songData = new Uint8Array(140);
    analyzer.current.getByteFrequencyData(songData);
    const bar_width = 1;
    let start = 0;
    const ctx = canvasRef.current.getContext("2d");
    ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    for (let i = 0; i < songData.length; i++) {
      start = i * 4;
      let gradient = ctx.createLinearGradient(
        0,
        0,
        canvasRef.current.width,
        canvasRef.current.height
      );
      gradient.addColorStop(0.2, "#2392f5");
      gradient.addColorStop(0.2, "#2392f5");
      gradient.addColorStop(1.0, "#2392f5");
      //gradient.addColorStop(0.5, "#fe0095");
      //gradient.addColorStop(1.0, "purple");
      ctx.fillStyle = gradient;
      ctx.fillRect(start, canvasRef.current.height, bar_width, -songData[i]);
    }
  };

  const stopAudio = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      setAudio(null);
      setVoiceSpeaking(null);
      const ctx = canvasRef.current.getContext("2d");
      ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    }
  };

  const handleAudioEnded = () => {
    cancelAnimationFrame(animationController);
    setVoiceSpeaking(null);
    setAudio(null);
  };

  const handleChangeSTTSource = (event) => {
    setSelectedSTTSource(event.target.value);
    handleInputChange(event);
  };

  const handleChangeLLMSource = (event) => {
    setSelectedLLMSource(event.target.value);
    handleInputChange(event);
  };
  const handleChangeTTSSource = (event) => {
    setSelectedTTSSource(event.target.value);
    handleInputChange(event);
  };

  const handleChangeVCSource = (event) => {
    setSelectedVCSource(event.target.value);
    handleInputChange(event);
  };

  const handleClearOutput = () => {
    if (outputRef.current) {
      outputRef.current.value = "";
    }
  };

  const handleChangeSTTSourceBotTwo = (event) => {
    setSelectedSTTSourceBotTwo(event.target.value);
    handleInputChange(event);
  };

  const handleChangeLLMSourceBotTwo = (event) => {
    setSelectedLLMSourceBotTwo(event.target.value);
    handleInputChange(event);
  };
  const handleChangeTTSSourceBotTwo = (event) => {
    setSelectedTTSSourceBotTwo(event.target.value);
    handleInputChange(event);
  };

  const handleChangeVCSourceBotTwo = (event) => {
    setSelectedVCSourceBotTwo(event.target.value);
    handleInputChange(event);
  };

  function useDebounce(callback, delay) {
    const [args, setArgs] = useState(null);

    useEffect(() => {
      if (args === null) return;
      const handler = setTimeout(() => {
        callback(...args);
        setArgs(null);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    }, [args, callback, delay]);

    return useCallback((...args) => {
      setArgs(args);
    }, []);
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    if (name == "systemMessage") {
      debounceUpdateConversationHistory(value);
    } else if (name == "systemMessageBotTwo") {
      debounceUpdateConversationHistoryBotTwo(value);
    } else if (name == "debugLevel") {
      setDebugLevel(parseInt(value));
    } else if (name == "selectSTTSource") {
      let sttConfig = null;
      switch (value) {
        case "openai":
          sttConfig = enginesDefaults.openAISTTConfig;
          break;
        case "fast-whisper":
          sttConfig = enginesDefaults.fwSTTConfig;
          break;
        default:
          sttConfig = enginesDefaults.openAISTTConfig;
      }
      setFormData((prevFormData) => ({
        ...prevFormData,
        sttConfig: sttConfig,
      }));
    } else if (name == "selectSTTSourceBotTwo") {
      let sttConfigBotTwo = null;
      switch (value) {
        case "openai":
          sttConfigBotTwo = enginesDefaults.openAISTTConfig;
          break;
        case "fast-whisper":
          sttConfigBotTwo = enginesDefaults.fwSTTConfig;
          break;
        default:
          sttConfigBotTwo = enginesDefaults.openAISTTConfig;
      }
      setFormData((prevFormData) => ({
        ...prevFormData,
        sttConfigBotTwo: sttConfigBotTwo,
      }));
    } else if (name == "selectLLMSource") {
      let llmConfig = null;
      let fcConfig = null;
      switch (value) {
        case "openai":
          llmConfig = enginesDefaults.openAILLMConfig;
          fcConfig = enginesDefaults.openAIFCConfig;
          break;
        case "ollama":
          llmConfig = enginesDefaults.ollamaLLMConfig;
          fcConfig = enginesDefaults.ollamaFCConfig;
          break;
        case "lmstudio":
          llmConfig = enginesDefaults.lmStudioLLMConfig;
          fcConfig = enginesDefaults.lmStudioFCConfig;
          break;
        default:
          llmConfig = enginesDefaults.openAILLMConfig;
          fcConfig = enginesDefaults.openAIFCConfig;
      }
      setFormData((prevFormData) => ({
        ...prevFormData,
        llmConfig: llmConfig,
        fcConfig: fcConfig,
      }));
    } else if (name == "selectLLMSourceBotTwo") {
      let llmConfigBotTwo = null;
      let fcConfigBotTwo = null;
      switch (value) {
        case "openai":
          llmConfigBotTwo = enginesDefaults.openAILLMConfig;
          fcConfigBotTwo = enginesDefaults.openAIFCConfig;
          break;
        case "ollama":
          llmConfigBotTwo = enginesDefaults.ollamaLLMConfig;
          fcConfigBotTwo = enginesDefaults.ollamaFCConfig;
          break;
        case "lmstudio":
          llmConfigBotTwo = enginesDefaults.lmStudioLLMConfig;
          fcConfigBotTwo = enginesDefaults.lmStudioFCConfig;
          break;
        default:
          llmConfigBotTwo = enginesDefaults.openAILLMConfig;
          fcConfigBotTwo = enginesDefaults.openAIFCConfig;
      }
      setFormData((prevFormData) => ({
        ...prevFormData,
        llmConfigBotTwo: llmConfigBotTwo,
        fcConfigBotTwo: fcConfigBotTwo,
      }));
    } else if (name == "selectTTSSource") {
      let ttsConfig = null;
      switch (value) {
        case "openai":
          ttsConfig = enginesDefaults.openAITTSConfig;
          break;
        case "openvoicev1":
          ttsConfig = enginesDefaults.openVoiceV1Config;
          break;
        case "openvoicev2":
          ttsConfig = enginesDefaults.openVoiceV2Config;
          break;
        case "elevenlabs":
          ttsConfig = enginesDefaults.elevenLabsTTSConfig;
          break;
        default:
          ttsConfig = enginesDefaults.openAITTSConfig;
      }
      setFormData((prevFormData) => ({
        ...prevFormData,
        ttsConfig: ttsConfig,
      }));
    } else if (name == "selectTTSSourceBotTwo") {
      let ttsConfigBotTwo = null;
      switch (value) {
        case "openai":
          ttsConfigBotTwo = enginesDefaults.openAITTSConfig;
          break;
        case "openvoicev1":
          ttsConfigBotTwo = enginesDefaults.openVoiceV1Config;
          break;
        case "openvoicev2":
          ttsConfigBotTwo = enginesDefaults.openVoiceV2Config;
          break;
        case "elevenlabs":
          ttsConfigBotTwo = enginesDefaults.elevenLabsTTSConfig;
          break;
        default:
          ttsConfigBotTwo = enginesDefaults.openAITTSConfig;
      }
      setFormData((prevFormData) => ({
        ...prevFormData,
        ttsConfigBotTwo: ttsConfigBotTwo,
      }));
    } else if (name == "selectVCSource") {
      let vcConfig = null;
      switch (value) {
        case "openvoicev1":
          vcConfig = enginesDefaults.openVoiceV1VCConfig;
          break;
        case "openvoicev2":
          vcConfig = enginesDefaults.openVoiceV2VCConfig;
          break;
        //case "elevenlabs":
        //  vcConfig = enginesDefaults.elevenLabsTTSConfig;
        //  break;
        default:
          vcConfig = enginesDefaults.defaultVCConfig;
      }
      setFormData((prevFormData) => ({
        ...prevFormData,
        vcConfig: vcConfig,
      }));
    } else if (name == "selectVCSourceBotTwo") {
      let vcConfigBotTwo = null;
      switch (value) {
        case "openvoicev1":
          vcConfigBotTwo = enginesDefaults.openVoiceV1VCConfig;
          break;
        case "openvoicev2":
          vcConfigBotTwo = enginesDefaults.openVoiceV2VCConfig;
          break;
        //case "elevenlabs":
        //  vcConfig = enginesDefaults.elevenLabsTTSConfig;
        //  break;
        default:
          vcConfigBotTwo = enginesDefaults.defaultVCConfig;
      }
      setFormData((prevFormData) => ({
        ...prevFormData,
        vcConfigBotTwo: vcConfigBotTwo,
      }));
    }
  };

  const debounceUpdateConversationHistory = useDebounce((value) => {
    setConversationHistory([{ role: "system", content: value }]);
  }, 2000);

  const debounceUpdateConversationHistoryBotTwo = useDebounce((value) => {
    setConversationHistoryBotTwo([{ role: "system", content: value }]);
  }, 2000);

  useEffect(() => {
    setConversationHistory([{ role: "system", content: defaultSystemMessage }]);
    setConversationHistoryBotTwo([{ role: "system", content: formDefaults.systemMessageBotTwo }]);
    setDebugLevel(testConversation.debugLevel);
  }, []);

  useEffect(() => {
    if (isRunning) {
      startConversation();
    } else {
      stopConversation();
    }
  }, [isRunning]);

  const startConversation = async () => {
    setAudioBlobs([]);
    setTimestamps([]);
    setCombinedAudioUrl("");
    setDownloadButton(false);
    updateOutput("Started new conversation", 0);
    const form = document.getElementById("conversationForm");
    const conversation_history = formData.systemMessage;
    const conversation_history_bot_two = formData.systemMessageBotTwo;
    const message = formData.startMessage;
    const maxHistory = parseInt(formData.maxHistory, 10);
    const maxHistoryBotTwo = parseInt(formData.maxHistoryBotTwo, 10);
    if (!formData.startMessage) {
      setIsRunning(false);
      setErrorMessage("Please add a start message");
      setTimeout(() => {
        setErrorMessage("");
      }, 10000);
      return;
    } else if (!formData.systemMessage) {
      setIsRunning(false);
      setErrorMessage("Please add a system message for first bot");
      setTimeout(() => {
        setErrorMessage("");
      }, 10000);
      return;
    } else if (!formData.systemMessageBotTwo) {
      setIsRunning(false);
      setErrorMessage("Please add a system message for second bot");
      setTimeout(() => {
        setErrorMessage("");
      }, 10000);
      return;
    }
    setFormDisabled(true);
    runningRef.current = true;
    let audioBlob = null;
    let initialResponse = null;
    try {
      initialResponse = await sendLLMToServer(1, form, message);
    } catch (error) {
      try {
        if (!initialResponse) {
          initialResponse = await sendLLMToServer(1, form, message);
        }
      } catch (error) {
        setIsRunning(false);
        console.log(`Error starting conversation: ${error.message}`);
        return;
      }
    }
    if (!initialResponse) {
      setIsRunning(false);
      setFormDisabled(false);
      setErrorMessage("Error with initial LLM request.");
      setTimeout(() => {
        setErrorMessage("");
      }, 10000);
      return;
    }
    let conversationHistory = [
      { role: "system", content: conversation_history },
      { role: "assistant", content: message },
    ];
    let conversationHistoryBotTwo = [{ role: "system", content: conversation_history_bot_two }];
    audioBlob = initialResponse.audioBlob;
    addAudioBlob(audioBlob);
    let currentBot = 2;
    let userMessage = "";
    let assistantMessage = "";
    let nextResponse = "";
    let history = "";
    let maxHis = 10;
    const loopFunction = async () => {
      while (runningRef.current) {
        try {
          //console.log("BOT: " + currentBot);
          // Send current audioBlob to server and get response from user
          userMessage = await sendBlobToServer(currentBot, form, audioBlob);
          if (!userMessage) {
            userMessage = await sendBlobToServer(currentBot, form, audioBlob);
          }
          if (!userMessage && runningRef.current) {
            setIsRunning(false);
            setFormDisabled(false);
            setErrorMessage("Error with STT request.");
            setTimeout(() => {
              setErrorMessage("");
            }, 10000);
            return;
          }
          if (currentBot == 1) {
            history = conversationHistory;
            maxHis = maxHistory;
          } else {
            history = conversationHistoryBotTwo;
            maxHis = maxHistoryBotTwo;
          }
          // Send user's message to get assistant's response
          assistantMessage = await sendSTTToServer(currentBot, form, userMessage, history, maxHis);
          if (!assistantMessage) {
            assistantMessage = await sendSTTToServer(
              currentBot,
              form,
              userMessage,
              history,
              maxHis
            );
          }
          if (!assistantMessage && runningRef.current) {
            setIsRunning(false);
            setFormDisabled(false);
            setErrorMessage("Error with LLM request.");
            setTimeout(() => {
              setErrorMessage("");
            }, 10000);
            return;
          }
          // Update conversation history based on current bot
          if (currentBot === 1) {
            conversationHistory.push(
              { role: "user", content: userMessage },
              { role: "assistant", content: assistantMessage }
            );
            if (conversationHistory.length > maxHistory) {
              conversationHistory.splice(1, conversationHistory.length - maxHistory);
            }
          } else {
            conversationHistoryBotTwo.push(
              { role: "user", content: userMessage },
              { role: "assistant", content: assistantMessage }
            );
            if (conversationHistoryBotTwo.length > maxHistoryBotTwo) {
              conversationHistoryBotTwo.splice(
                1,
                conversationHistoryBotTwo.length - maxHistoryBotTwo
              );
            }
          }
          nextResponse = await sendLLMToServer(currentBot, form, assistantMessage);
          if (!nextResponse) {
            nextResponse = await sendLLMToServer(currentBot, form, assistantMessage);
          }
          if (!nextResponse && runningRef.current) {
            setIsRunning(false);
            setFormDisabled(false);
            setErrorMessage("Error with LLM request.");
            setTimeout(() => {
              setErrorMessage("");
            }, 10000);
            return;
          }
          audioBlob = nextResponse.audioBlob;
          addAudioBlob(audioBlob);
          currentBot = currentBot === 1 ? 2 : 1;
        } catch (error) {
          setIsRunning(false);
          setFormDisabled(false);
          console.log(`Error in conversation loop: ${error.message}`);
          setTimeout(() => {
            setErrorMessage("");
          }, 10000);
          return;
        }
      }
      setFormDisabled(false);
    };
    await loopFunction();
  };

  const stopConversation = () => {
    runningRef.current = false; // Stop the loop
    if (isAudioPlaying) {
      stopAudio();
    }
    setFormDisabled(false);
    clearTimeout(loopTimeoutRef.current); // Clear any pending timeout
    if (audioBlobs.length > 0) {
      setDownloadButton(true);
    }
  };

  const sendBlobToServer = async (bot, form, blob) => {
    if (!runningRef.current) {
      return;
    }
    let botName = formData.firstBotName;
    let sttSource = formData.selectSTTSource;
    let sttConfig = formData.sttConfig;
    if (bot == 2) {
      botName = formData.secondBotName;
      sttSource = formData.selectSTTSourceBotTwo;
      sttConfig = formData.sttConfigBotTwo;
    }
    try {
      updateOutput(botName + ": Getting STT data", 2);
      const sendData = new FormData();
      sendData.append("audio", blob, "recorded_audio.webm");
      const sttConfigObject = JSON.parse(sttConfig);
      const url = backendAddress + "/speech-to-text/" + sttSource;
      const params = new URLSearchParams(sttConfigObject);
      params.forEach((value, key) => {
        sendData.append(key, value);
      });
      const response = await fetch(url, {
        method: "POST",
        body: sendData,
      });
      const elapsed_time = response.headers.get("X-Elapsed-Time");
      const formatted_time = parseFloat(elapsed_time).toFixed(4);
      const data = await response.json();
      let message = "";
      if (response.status === 200) {
        message = data["result"]["data"].text;
      } else {
        message = response.status + " " + data["result"].message;
      }
      updateOutput(botName + ": (" + formatted_time + "ms) STT result: " + message, 0);
      if (message && response.status === 200) {
        return message;
      }
      return null;
    } catch (error) {
      let errorMessage = botName + ": Error getting STT data";
      if (error.response) {
        const elapsed_time = error.response.headers.get("X-Elapsed-Time");
        const formatted_time = parseFloat(elapsed_time).toFixed(4);
        errorMessage = ": (" + formatted_time + "ms) " + errorMessage;
        errorMessage += ": " + error.response.data.result.message;
      } else {
        errorMessage += ": " + error.message;
      }
      updateOutput(errorMessage, 0);
    }
  };

  const sendSTTToServer = async (bot, form, message, history, maxHis) => {
    if (!runningRef.current) {
      return;
    }
    let botName = formData.firstBotName;
    let llmSource = formData.selectLLMSource;
    let llmConfig = formData.llmConfig;
    let fcConfig = formData.fcConfig;
    if (bot == 2) {
      botName = formData.secondBotName;
      llmSource = formData.selectLLMSourceBotTwo;
      llmConfig = formData.llmConfigBotTwo;
      fcConfig = formData.fcConfigBotTwo;
    }
    try {
      updateOutput(botName + ": Getting LLM data", 2);
      const llmConfigObject = JSON.parse(llmConfig);
      const url = backendAddress + "/chat/" + llmSource;
      const params = {
        __conversation_history: history,
        __message: message,
        __max_conversation_history: maxHis,
        ...llmConfigObject,
      };
      if (fcConfig) {
        params["tools"] = JSON.parse(fcConfig);
      }
      const response = await axios.post(url, params);
      const elapsed_time = response.headers.get("X-Elapsed-Time");
      const formatted_time = parseFloat(elapsed_time).toFixed(4);
      const data = await response.data;
      let response_message = "";
      //let conversation_history = "";
      if (response.status == 200) {
        response_message = data["result"]["data"].response_message;
        //conversation_history = data["result"]["data"].conversation_history;
      } else {
        response_message = response.status + " " + data["result"].message;
      }
      updateOutput(botName + ": (" + formatted_time + "ms) LLM result: " + response_message, 0);
      return response_message;
    } catch (error) {
      let errorMessage = botName + ": Error getting LLM data";
      if (error.response) {
        const elapsed_time = error.response.headers.get("X-Elapsed-Time");
        const formatted_time = parseFloat(elapsed_time).toFixed(4);
        errorMessage = "(" + formatted_time + "ms) " + errorMessage;
        errorMessage += ": " + error.response.data.result.message;
      } else {
        errorMessage += ": " + error.message;
      }
      updateOutput(errorMessage, 0);
    }
  };

  let playEndTimestamp = null;

  const sendLLMToServer = async (bot, form, message) => {
    if (!runningRef.current) {
      return;
    }
    let botName = formData.firstBotName;
    let ttsSource = formData.selectTTSSource;
    let ttsConfig = formData.ttsConfig;
    let vcSource = formData.selectVCSource;
    if (bot === 2) {
      botName = formData.secondBotName;
      ttsSource = formData.selectTTSSourceBotTwo;
      ttsConfig = formData.ttsConfigBotTwo;
      vcSource = formData.selectVCSourceBotTwo;
    }
    try {
      updateOutput(botName + ": Getting TTS data", 2);
      const url = backendAddress + "/text-to-speech/" + ttsSource;
      const params = {
        input: message,
        ...JSON.parse(ttsConfig),
      };
      const response = await axios.post(url, params);
      const elapsed_time = response.headers.get("X-Elapsed-Time");
      const formatted_time = parseFloat(elapsed_time).toFixed(4);
      const data = await response.data;
      let response_message = "";
      let audioBlob = null;

      if (response.status === 200) {
        const audio = data["result"]["data"].audio_base64;
        const byteCharacters = atob(audio);
        const byteArrays = [];
        for (let i = 0; i < byteCharacters.length; i++) {
          byteArrays.push(byteCharacters.charCodeAt(i));
        }
        const binaryData = new Uint8Array(byteArrays);
        response_message = ": Returned audio " + binaryData.length + " bytes, playing now";
        audioBlob = new Blob([binaryData], { type: "audio/wav" });
        if (vcSource === "none") {
          updateOutput(botName + ": (" + formatted_time + "ms) TTS result: " + response_message, 0);
          await new Promise((resolve) => {
            const blobUrl = URL.createObjectURL(audioBlob);
            updateOutput(botName + ": Debug Blob URL created: " + blobUrl, 2);
            const audioElement = new Audio(blobUrl);
            setVoiceSpeaking(audioBlob);
            audioRef.current = audioElement;
            audioElement.addEventListener("ended", () => {
              playEndTimestamp = Date.now();
              handleAudioEnded();
              resolve();
            });
            audioElement.addEventListener("play", handleAudioPlay);
            audioElement.addEventListener("pause", () => setIsAudioPlaying(false));
            setAudio(audioElement);
            audioElement.play();
          });
        }
      } else {
        response_message = response.status + " " + data["result"].message;
        updateOutput(botName + ": (" + formatted_time + "ms) TTS result: " + response_message, 0);
      }
      if (vcSource != "none") {
        updateOutput(botName + ": (" + formatted_time + "ms) TTS result: " + response_message, 0);
        const audioBlobVC = await sendVCBlobToServer(bot, form, audioBlob);
        audioBlob = audioBlobVC;
      }
      return { audioBlob };
    } catch (error) {
      let errorMessage = botName + ": Error getting TTS data";
      if (error.response) {
        const elapsed_time = error.response.headers.get("X-Elapsed-Time");
        const formatted_time = parseFloat(elapsed_time).toFixed(4);
        errorMessage = botName + ": (" + formatted_time + "ms) " + errorMessage;
        errorMessage += ": " + error.response.data.result.message;
      } else {
        errorMessage += ": " + error.message;
      }
      updateOutput(errorMessage, 0);
      throw error; // Rethrow the error to handle it in the calling function if needed
    }
  };

  const sendVCBlobToServer = async (bot, form, blob) => {
    if (!runningRef.current) {
      return;
    }
    let botName = formData.firstBotName;
    let vcSource = formData.selectVCSource;
    let vcConfig = formData.vcConfig;
    if (bot === 2) {
      botName = formData.secondBotName;

      vcSource = formData.selectVCSourceBotTwo;
      vcConfig = formData.vcConfigBotTwo;
    }
    try {
      updateOutput(botName + ": Getting SV data", 2);
      const sendData = new FormData();
      sendData.append("audio", blob, "recorded_audio.wav");
      const vcConfigObject = JSON.parse(vcConfig);
      const url = backendAddress + "/convert-voice/" + vcSource;
      const params = new URLSearchParams(vcConfigObject);
      params.forEach((value, key) => {
        sendData.append(key, value);
      });
      const response = await fetch(url, {
        method: "POST",
        body: sendData,
      });
      const elapsed_time = response.headers.get("X-Elapsed-Time");
      const formatted_time = parseFloat(elapsed_time).toFixed(4);
      const data = await response.json();
      let message = "";
      let blobUrl = "";
      let audioBlob = null;
      if (response.status === 200) {
        const audio = data["result"]["data"].audio_base64;
        const byteCharacters = atob(audio);
        const byteArrays = [];
        for (let i = 0; i < byteCharacters.length; i++) {
          byteArrays.push(byteCharacters.charCodeAt(i));
        }
        const binaryData = new Uint8Array(byteArrays);
        message = "Returned converted audio " + binaryData.length + " bytes, playing now";
        updateOutput(botName + ": (" + formatted_time + "ms) VC result: " + message, 0);
        audioBlob = new Blob([binaryData], { type: "audio/wav" });
        await new Promise((resolve) => {
          const blobUrl = URL.createObjectURL(audioBlob);
          updateOutput(botName + ": Debug Blob URL created: " + blobUrl, 2);
          const audioElement = new Audio(blobUrl);
          setVoiceSpeaking(audioBlob);
          audioRef.current = audioElement;
          audioElement.addEventListener("ended", () => {
            playEndTimestamp = Date.now();
            handleAudioEnded();
            resolve();
          });
          audioElement.addEventListener("play", handleAudioPlay);
          audioElement.addEventListener("pause", () => setIsAudioPlaying(false));
          setAudio(audioElement);
          audioElement.play();
        });
      } else {
        message = response.status + " " + data["result"].message;
        updateOutput(botName + ": (" + formatted_time + "ms) VC result: " + message, 0);
      }
      return audioBlob;
    } catch (error) {
      let errorMessage = botName + ": Error getting VC data";
      if (error.response) {
        const elapsed_time = error.response.headers.get("X-Elapsed-Time");
        const formatted_time = parseFloat(elapsed_time).toFixed(4);
        errorMessage = "(" + formatted_time + "ms) " + errorMessage;
        errorMessage += ": " + error.response.data.result.message;
      } else {
        errorMessage += botName + ": " + error.message;
      }
      updateOutput(errorMessage, 0);
    }
  };

  const updateOutput = (text, debug = 0, output = "all") => {
    if (outputRef.current && debugLevel >= debug) {
      switch (debug) {
        case 0:
          text = "--- " + text + " ---\n";
          break;
        case 1:
          text = "=== " + text + " ===\n";
          break;
        case 2:
          text = ">>> " + text + " <<<\n";
          break;
        default:
          text = text + "\n";
          break;
      }
      if (output === "view" || output === "all") {
        outputRef.current.value += text;
        outputRef.current.scrollTop = outputRef.current.scrollHeight;
      }
      if (output === "console" || output === "all") {
        console.log(text);
      }
    }
  };

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const [tabValue1, setTabValue1] = useState(0);
  const [tabValue2, setTabValue2] = useState(0);

  const handleTabChange1 = (event, newValue) => {
    setTabValue1(newValue);
  };

  const handleTabChange2 = (event, newValue) => {
    setTabValue2(newValue);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container justifyContent="center" style={{ minHeight: "100vh" }}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <StyledCard>
            <Button onClick={toggleFormVisibility}>
              {formVisible ? "Hide Config" : "Show Config"}
            </Button>

            <Box mt={2} style={{ display: formVisible ? "block" : "none" }}>
              <Typography
                variant="body1"
                style={{
                  color: darkMode ? "white" : "black",
                  marginBottom: 8,
                  //fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                First Bot Configuration
              </Typography>

              <Tabs value={tabValue1} onChange={handleTabChange1} aria-label="basic tabs example">
                <Tab label="General" {...a11yProps(0)} />
                <Tab label="Speech to Text" {...a11yProps(1)} />
                <Tab label="Language Model" {...a11yProps(2)} />
                <Tab label="Text to Speech" {...a11yProps(3)} />
                <Tab label="Voice Converter" {...a11yProps(4)} />
              </Tabs>
              <form id="conversationForm">
                <CustomTabPanel value={tabValue1} index={0}>
                  <TextField
                    fullWidth
                    id="firstBotName"
                    name="firstBotName"
                    label="Name"
                    value={formData.firstBotName}
                    onChange={handleInputChange}
                    multiline
                    rows={1}
                    variant="outlined"
                    margin="normal"
                    disabled={formDisabled}
                  />
                  <TextField
                    fullWidth
                    id="systemMessage"
                    name="systemMessage"
                    label="System Message"
                    value={formData.systemMessage}
                    onChange={handleInputChange}
                    multiline
                    rows={3}
                    variant="outlined"
                    margin="normal"
                    disabled={formDisabled}
                  />
                </CustomTabPanel>
                <CustomTabPanel value={tabValue1} index={1}>
                  <InputLabel htmlFor="selectSTTSource">STT Source</InputLabel>
                  <Select
                    fullWidth
                    id="selectSTTSource"
                    name="selectSTTSource"
                    value={selectedSTTSource}
                    onChange={handleChangeSTTSource}
                    variant="outlined"
                    margin="none"
                    sx={{ minHeight: 40 }}
                    disabled={formDisabled}
                  >
                    <MenuItem value="openai">OpenAI</MenuItem>
                    <MenuItem value="fast-whisper">Fast Whisper</MenuItem>
                    {/* Add more options as needed */}
                  </Select>
                  <TextField
                    fullWidth
                    id="sttConfig"
                    name="sttConfig"
                    label="STT Config"
                    value={formData.sttConfig}
                    onChange={handleInputChange}
                    multiline
                    rows={3}
                    variant="outlined"
                    margin="normal"
                    disabled={formDisabled}
                  />
                </CustomTabPanel>
                <CustomTabPanel value={tabValue1} index={2}>
                  <InputLabel htmlFor="selectLLMSource">LLM Source</InputLabel>
                  <Select
                    fullWidth
                    id="selectLLMSource"
                    name="selectLLMSource"
                    value={selectedLLMSource}
                    onChange={handleChangeLLMSource}
                    variant="outlined"
                    margin="none"
                    sx={{ minHeight: 40 }}
                    disabled={formDisabled}
                  >
                    <MenuItem value="openai">OpenAI</MenuItem>
                    <MenuItem value="ollama">Ollama</MenuItem>
                    <MenuItem value="lmstudio">LM Studio</MenuItem>
                    {/* Add more options as needed */}
                  </Select>
                  <TextField
                    fullWidth
                    id="llmConfig"
                    name="llmConfig"
                    label="LLM Config"
                    value={formData.llmConfig}
                    onChange={handleInputChange}
                    multiline
                    rows={3}
                    variant="outlined"
                    margin="normal"
                    disabled={formDisabled}
                  />
                  <TextField
                    fullWidth
                    id="maxHistoryInput"
                    name="maxHistory"
                    label="Conversation History"
                    type="number"
                    value={formData.maxHistory}
                    InputProps={{ inputProps: { min: 1, max: 1000 } }}
                    onChange={handleInputChange}
                    variant="outlined"
                    margin="normal"
                    disabled={formDisabled}
                  />
                  <TextField
                    fullWidth
                    id="fcConfig"
                    name="fcConfig"
                    label="Functions"
                    value={formData.fcConfig}
                    onChange={handleInputChange}
                    multiline
                    minRows={3}
                    maxRows={20}
                    variant="outlined"
                    margin="normal"
                  />
                </CustomTabPanel>
                <CustomTabPanel value={tabValue1} index={3}>
                  <InputLabel htmlFor="selectTTSSource">TTS Source</InputLabel>
                  <Select
                    fullWidth
                    id="selectTTSSource"
                    name="selectTTSSource"
                    value={selectedTTSSource}
                    onChange={handleChangeTTSSource}
                    variant="outlined"
                    margin="none"
                    sx={{ minHeight: 40 }}
                    disabled={formDisabled}
                  >
                    <MenuItem value="openai">OpenAI</MenuItem>
                    <MenuItem value="elevenlabs">ElevenLabs</MenuItem>
                    <MenuItem value="openvoicev1">OpenVoice V1</MenuItem>
                    <MenuItem value="openvoicev2">OpenVoice V2</MenuItem>
                  </Select>
                  <TextField
                    fullWidth
                    id="ttsConfig"
                    name="ttsConfig"
                    label="TTS Config"
                    value={formData.ttsConfig}
                    onChange={handleInputChange}
                    multiline
                    rows={3}
                    variant="outlined"
                    margin="normal"
                    disabled={formDisabled}
                  />
                </CustomTabPanel>
                <CustomTabPanel value={tabValue1} index={4}>
                  <InputLabel htmlFor="selectTTSSource">Voice Converter</InputLabel>
                  <Select
                    fullWidth
                    id="selectVCSource"
                    name="selectVCSource"
                    value={formData.selectVCSource}
                    onChange={handleChangeVCSource}
                    variant="outlined"
                    margin="none"
                    sx={{ minHeight: 40 }}
                    disabled={formDisabled}
                  >
                    <MenuItem value="none">None</MenuItem>
                    {/*<MenuItem value="elevenlabs">ElevenLabs</MenuItem>*/}
                    <MenuItem value="openvoicev1">OpenVoice V1</MenuItem>
                    <MenuItem value="openvoicev2">OpenVoice V2</MenuItem>
                  </Select>
                  <TextField
                    fullWidth
                    id="vcConfig"
                    name="vcConfig"
                    label="Voice Converter Config"
                    value={formData.vcConfig}
                    onChange={handleInputChange}
                    multiline
                    rows={3}
                    variant="outlined"
                    margin="normal"
                    disabled={formDisabled}
                  />
                </CustomTabPanel>

                <Grid item style={{ height: 20 }} />
                <Typography
                  variant="body1"
                  style={{
                    color: darkMode ? "white" : "black",
                    marginBottom: 8,
                    //fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Second Bot Configuration
                </Typography>

                <Tabs value={tabValue2} onChange={handleTabChange2} aria-label="basic tabs example">
                  <Tab label="General" {...a11yProps(0)} />
                  <Tab label="Speech to Text" {...a11yProps(1)} />
                  <Tab label="Language Model" {...a11yProps(2)} />
                  <Tab label="Text to Speech" {...a11yProps(3)} />
                  <Tab label="Voice Converter" {...a11yProps(4)} />
                </Tabs>
                <CustomTabPanel value={tabValue2} index={0}>
                  <TextField
                    fullWidth
                    id="secondBotName"
                    name="secondBotName"
                    label="Name"
                    value={formData.secondBotName}
                    onChange={handleInputChange}
                    multiline
                    rows={1}
                    variant="outlined"
                    margin="normal"
                    disabled={formDisabled}
                  />
                  <TextField
                    fullWidth
                    id="systemMessageBotTwo"
                    name="systemMessageBotTwo"
                    label="System Message"
                    value={formData.systemMessageBotTwo}
                    onChange={handleInputChange}
                    multiline
                    rows={3}
                    variant="outlined"
                    margin="normal"
                    disabled={formDisabled}
                  />
                </CustomTabPanel>
                <CustomTabPanel value={tabValue2} index={1}>
                  <InputLabel htmlFor="selectSTTSourceBotTwo">STT Source</InputLabel>
                  <Select
                    fullWidth
                    id="selectSTTSourceBotTwo"
                    name="selectSTTSourceBotTwo"
                    value={selectedSTTSourceBotTwo}
                    onChange={handleChangeSTTSourceBotTwo}
                    variant="outlined"
                    margin="none"
                    sx={{ minHeight: 40 }}
                    disabled={formDisabled}
                  >
                    <MenuItem value="openai">OpenAI</MenuItem>
                    <MenuItem value="fast-whisper">Fast Whisper</MenuItem>
                    {/* Add more options as needed */}
                  </Select>
                  <TextField
                    fullWidth
                    id="sttConfigBotTwo"
                    name="sttConfigBotTwo"
                    label="STT Config"
                    value={formData.sttConfigBotTwo}
                    onChange={handleInputChange}
                    multiline
                    rows={3}
                    variant="outlined"
                    margin="normal"
                    disabled={formDisabled}
                  />
                </CustomTabPanel>
                <CustomTabPanel value={tabValue2} index={2}>
                  <InputLabel htmlFor="selectLLMSource">LLM Source</InputLabel>
                  <Select
                    fullWidth
                    id="selectLLMSourceBotTwo"
                    name="selectLLMSourceBotTwo"
                    value={selectedLLMSourceBotTwo}
                    onChange={handleChangeLLMSourceBotTwo}
                    variant="outlined"
                    margin="none"
                    sx={{ minHeight: 40 }}
                    disabled={formDisabled}
                  >
                    <MenuItem value="openai">OpenAI</MenuItem>
                    <MenuItem value="ollama">Ollama</MenuItem>
                    <MenuItem value="lmstudio">LM Studio</MenuItem>
                    {/* Add more options as needed */}
                  </Select>
                  <TextField
                    fullWidth
                    id="llmConfigBotTwo"
                    name="llmConfigBotTwo"
                    label="LLM Config"
                    value={formData.llmConfigBotTwo}
                    onChange={handleInputChange}
                    multiline
                    rows={3}
                    variant="outlined"
                    margin="normal"
                    disabled={formDisabled}
                  />
                  <TextField
                    fullWidth
                    id="maxHistoryBotTwo"
                    name="maxHistoryBotTwo"
                    label="Conversation History"
                    type="number"
                    value={formData.maxHistoryBotTwo}
                    InputProps={{ inputProps: { min: 1, max: 1000 } }}
                    onChange={handleInputChange}
                    variant="outlined"
                    margin="normal"
                    disabled={formDisabled}
                  />
                  <TextField
                    fullWidth
                    id="fcConfigBotTwo"
                    name="fcConfigBotTwo"
                    label="Functions"
                    value={formData.fcConfigBotTwo}
                    onChange={handleInputChange}
                    multiline
                    minRows={3}
                    maxRows={20}
                    variant="outlined"
                    margin="normal"
                  />
                </CustomTabPanel>
                <CustomTabPanel value={tabValue2} index={3}>
                  <InputLabel htmlFor="selectTTSSource">TTS Source</InputLabel>
                  <Select
                    fullWidth
                    id="selectTTSSourceBotTwo"
                    name="selectTTSSourceBotTwo"
                    value={selectedTTSSourceBotTwo}
                    onChange={handleChangeTTSSourceBotTwo}
                    variant="outlined"
                    margin="none"
                    sx={{ minHeight: 40 }}
                    disabled={formDisabled}
                  >
                    <MenuItem value="openai">OpenAI</MenuItem>
                    <MenuItem value="elevenlabs">ElevenLabs</MenuItem>
                    <MenuItem value="openvoicev1">OpenVoice V1</MenuItem>
                    <MenuItem value="openvoicev2">OpenVoice V2</MenuItem>
                  </Select>
                  <TextField
                    fullWidth
                    id="ttsConfigBotTwo"
                    name="ttsConfigBotTwo"
                    label="TTS Config"
                    value={formData.ttsConfigBotTwo}
                    onChange={handleInputChange}
                    multiline
                    rows={3}
                    variant="outlined"
                    margin="normal"
                    disabled={formDisabled}
                  />
                </CustomTabPanel>
                <CustomTabPanel value={tabValue2} index={4}>
                  <InputLabel htmlFor="selectTTSSource">Voice Converter</InputLabel>
                  <Select
                    fullWidth
                    id="selectVCSourceBotTwo"
                    name="selectVCSourceBotTwo"
                    value={formData.selectVCSourceBotTwo}
                    onChange={handleChangeVCSourceBotTwo}
                    variant="outlined"
                    margin="none"
                    sx={{ minHeight: 40 }}
                    disabled={formDisabled}
                  >
                    <MenuItem value="none">None</MenuItem>
                    {/*<MenuItem value="elevenlabs">ElevenLabs</MenuItem>*/}
                    <MenuItem value="openvoicev1">OpenVoice V1</MenuItem>
                    <MenuItem value="openvoicev2">OpenVoice V2</MenuItem>
                  </Select>
                  <TextField
                    fullWidth
                    id="vcConfigBotTwo"
                    name="vcConfigBotTwo"
                    label="Voice Converter Config"
                    value={formData.vcConfigBotTwo}
                    onChange={handleInputChange}
                    multiline
                    rows={3}
                    variant="outlined"
                    margin="normal"
                    disabled={formDisabled}
                  />
                </CustomTabPanel>
                <Grid item style={{ height: 20 }} />
                <Typography
                  variant="body1"
                  style={{
                    color: darkMode ? "white" : "black",
                    marginBottom: 8,
                    //fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Write a message for first bot to begin conversation and press play
                </Typography>
                <TextField
                  fullWidth
                  id="startMessage"
                  name="startMessage"
                  label="Start Message"
                  value={formData.startMessage}
                  onChange={handleInputChange}
                  multiline
                  rows={3}
                  variant="outlined"
                  margin="normal"
                  disabled={formDisabled}
                />
                <TextField
                  fullWidth
                  id="debugLevel"
                  name="debugLevel"
                  label="Output Level"
                  type="number"
                  value={formData.debugLevel}
                  InputProps={{ inputProps: { min: 0, max: 2 } }}
                  onChange={handleInputChange}
                  variant="outlined"
                  margin="normal"
                />
              </form>
            </Box>
            <Box textAlign="center">
              <TextareaAutosize
                ref={outputRef}
                minRows={10}
                aria-label="Output"
                placeholder="Output"
                style={{
                  border: "none",
                  outline: "none",
                  width: "100%",
                  height: "200px",
                  padding: "8px",
                  fontSize: "1rem",
                  resize: "vertical",
                  overflow: "auto",
                  minHeight: "120px",
                  color: darkMode ? "white" : "black",
                  backgroundColor: darkMode ? "#333" : "#fff",
                  //maxHeight: "600px",
                }}
                readOnly
              />
              <Button onClick={handleClearOutput}>Clear Output</Button>
            </Box>
          </StyledCard>
          <Grid container justifyContent="center" style={{ marginTop: "20px" }}>
            {isRunning ? (
              <Grid container direction="column" alignItems="center" justifyContent="center">
                <RedRoundButton onClick={toggleMic}>
                  <StopCircleOutlined
                    style={{ fontSize: 50, color: "white" }}
                    ref={(el) => {
                      if (el) {
                        el.style.setProperty("font-size", "50px", "important");
                      }
                    }}
                  />
                </RedRoundButton>
              </Grid>
            ) : (
              <Grid container direction="column" alignItems="center" justifyContent="center">
                <GreenRoundButton onClick={toggleMic}>
                  <PlayCircle
                    style={{ fontSize: 50, color: "white" }}
                    ref={(el) => {
                      if (el) {
                        CloudDownload;
                        el.style.setProperty("font-size", "50px", "important");
                      }
                    }}
                  />
                </GreenRoundButton>
                <Grid item style={{ height: 10 }} />
                {errorMessage && (
                  <Grid item>
                    <span style={{ color: "red" }}>{errorMessage}</span>
                  </Grid>
                )}
              </Grid>
            )}
            {voiceSpeaking && (
              <>
                <Grid container justifyContent="center" style={{ marginTop: "10px" }}>
                  <canvas ref={canvasRef} width={250} height={20} />
                </Grid>
              </>
            )}
            {downloadButton && (
              <Button
                variant="contained"
                color="primary"
                onClick={combineAudioBlobs}
                style={{ color: "white", marginTop: "20px" }}
                startIcon={<CloudDownload />}
              >
                Download Conversation
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default BotVsBot;
