import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { apiCodeBlocks } from "configDefaults";
import {
  CardContent,
  Grid,
  Icon,
  IconButton,
  Select,
  MenuItem,
  TextareaAutosize,
  Tooltip,
} from "@mui/material";
import { getSubdomainAddress, useApiKeyServices } from "services";
import { MDTypography, MDBox, Panel } from "components";
import { useMaterialUIController } from "context";
import { lang } from "hooks";

const APIPanel = ({ sid }) => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const { getApiKeys } = useApiKeyServices();
  const [apiKeys, setApiKeys] = useState([]);
  const [currentApiKey, setCurrentApiKey] = useState("{{ none }}");
  const [selectLanguage, setSelectLanguage] = useState("curl");
  const [labelText, setLabelText] = useState(lang("ucfirst")("API_CODE_BLOCK_LABEL"));
  const [codeBlockValue, setCodeBlockValue] = useState("");
  const base_url = getSubdomainAddress("api");

  useEffect(() => {
    const fetchApiKeys = async () => {
      try {
        const apiKeys = await getApiKeys();
        console.log("API.apiKeys", apiKeys);
        setApiKeys(apiKeys.data);
      } catch (error) {
        console.error("Error fetching API keys:", error);
      }
    };
    fetchApiKeys();
    const codeBlock = replacePlaceholders(apiCodeBlocks.curl);
    setCodeBlockValue(codeBlock);
  }, []);

  const replacePlaceholders = (template) => {
    return template
      .replace("{{ base_url }}", base_url)
      .replace("{{ assistant_id }}", sid)
      .replace(
        "{{ api_key }}",
        currentApiKey !== "{{ none }}" ? currentApiKey : "YOUR API KEY HERE"
      );
  };

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(codeBlockValue)
      .then(() => {
        setTooltipOpen(true); // Show tooltip on copy
        setTimeout(() => setTooltipOpen(false), 2000); // Hide after 2 seconds
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  useEffect(() => {
    if (currentApiKey) {
      const codeBlock = replacePlaceholders(apiCodeBlocks[selectLanguage]);
      setCodeBlockValue(codeBlock);
    }
  }, [currentApiKey, selectLanguage]);

  const handleSelectChange = (event) => {
    const value = event.target.value;
    setCurrentApiKey(value);
  };

  const handleSelectChangeLanguage = (event) => {
    const value = event.target.value;
    setSelectLanguage(value);
    const codeBlock = replacePlaceholders(apiCodeBlocks[value]);
    setCodeBlockValue(codeBlock);
  };
  const [selectOpen, setSelectOpen] = useState(false); // State to control dropdown
  const [selectOpenLanguage, setSelectOpenLanguage] = useState(false); // State to control dropdown

  const handleIconClick = (event) => {
    event.stopPropagation(); // Prevent label click event from triggering twice
    setSelectOpen((prev) => !prev); // Toggle the open state
  };

  const handleIconClickLanguage = (event) => {
    event.stopPropagation(); // Prevent label click event from triggering twice
    setSelectOpenLanguage((prev) => !prev); // Toggle the open state
  };

  return (
    <Grid item xs={12} lg={12}>
      <Panel darkMode={darkMode}>
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "69.7vh", // Define height to ensure scroll area
            overflow: "hidden", // Prevents CardContent from scrolling
          }}
        >
          <MDBox
            mt={1}
            sx={{
              flex: 1,
              overflowY: "auto",
              paddingRight: "10px",
              "&::-webkit-scrollbar": {
                width: "6px", // Thin scrollbar width
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: darkMode ? "#333" : "#f1f1f1", // Track color
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: darkMode ? "#888" : "#ccc", // Thumb color
                borderRadius: "4px", // Rounded edges for scrollbar thumb
              },
              "&::-webkit-scrollbar-thumb:hover": {
                backgroundColor: darkMode ? "#555" : "#999", // Hover effect
              },
            }}
          >
            <MDTypography
              variant="h5"
              gutterBottom
              color="error"
              opacity={darkMode ? 0.8 : 1.0}
              fontWeight="regular"
            >
              <Icon fontSize="medium" sx={{ transform: "translateY(4px)" }}>
                api
              </Icon>{" "}
              {lang("ucwords")("API_TITLE")}
            </MDTypography>
            <MDTypography
              variant="body1"
              fontWeight="regular"
              sx={{ margin: "2px 0 10px 0" }}
              opacity={darkMode ? 0.6 : 0.8}
            >
              {lang("span", { "@api_url": `${base_url}/docs/api` })("API_CONNECT_TEXT")}
            </MDTypography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <MDBox>
                  <MDTypography
                    variant="body2"
                    fontWeight="light"
                    sx={{ margin: "2px 0 5px 0" }}
                    opacity={darkMode ? 0.6 : 0.8}
                  >
                    {lang("ucfirst")("API_SELECT_KEY_LABEL_TEXT")}:
                  </MDTypography>
                  <Select
                    name="api_key"
                    value={currentApiKey}
                    onChange={handleSelectChange}
                    open={selectOpen}
                    onClose={() => setSelectOpen(false)}
                    onOpen={() => setSelectOpen(true)}
                    variant="outlined"
                    margin="none"
                    sx={{ height: 40, minWidth: "200px" }}
                    IconComponent={() => (
                      <IconButton
                        onClick={handleIconClick}
                        sx={{
                          fontSize: "18px",
                          padding: "0 5px",
                          color: darkMode ? "lightgrey" : "black",
                        }}
                      >
                        <Icon>expand_more</Icon>
                      </IconButton>
                    )}
                  >
                    <MenuItem value="{{ none }}" disabled={false}>
                      {lang("ucfirst")("API_SELECT_API_KEY_EMPTY_VALUE")}
                    </MenuItem>
                    {apiKeys.map((keyData) => {
                      if (keyData.type === "private") {
                        return (
                          <MenuItem key={keyData.api_key} value={keyData.api_key}>
                            {keyData.name}
                          </MenuItem>
                        );
                      }
                      return null;
                    })}
                  </Select>
                </MDBox>

                <MDBox mt={2}>
                  <MDTypography
                    variant="body2"
                    fontWeight="light"
                    sx={{ margin: "2px 0 5px 0" }}
                    opacity={darkMode ? 0.6 : 0.8}
                  >
                    {lang("ucfirst")("API_SELECT_LANGUAGE_LABEL_TEXT")}:
                  </MDTypography>
                  <Select
                    name="language"
                    value={selectLanguage}
                    onChange={handleSelectChangeLanguage}
                    open={selectOpenLanguage}
                    onClose={() => setSelectOpenLanguage(false)}
                    onOpen={() => setSelectOpenLanguage(true)}
                    variant="outlined"
                    margin="none"
                    sx={{ height: 40, minWidth: "200px" }}
                    IconComponent={() => (
                      <IconButton
                        onClick={handleIconClickLanguage}
                        sx={{
                          fontSize: "18px",
                          padding: "0 5px",
                          color: darkMode ? "lightgrey" : "black",
                        }}
                      >
                        <Icon>expand_more</Icon>
                      </IconButton>
                    )}
                  >
                    <MenuItem value="curl">Curl</MenuItem>
                    <MenuItem value="javascript">JavaScript</MenuItem>
                    <MenuItem value="nodejs">NodeJS</MenuItem>
                    <MenuItem value="php">PHP</MenuItem>
                    <MenuItem value="python">Python</MenuItem>
                    <MenuItem value="react">React</MenuItem>
                  </Select>
                </MDBox>

                <MDBox mt={2}></MDBox>
                <MDBox
                  mt={2}
                  sx={{
                    position: "relative",
                    width: {
                      xs: "100%", // Full width on extra small to medium screens
                      sm: "100%",
                      md: "100%",
                      lg: "100%", // 800px on large screens
                      xl: "100%", // 800px on extra large screens
                    },
                  }}
                >
                  <MDTypography
                    variant="body2"
                    gutterBottom
                    //color="error"
                    opacity={0.6}
                    fontWeight="regular"
                  >
                    {labelText}:
                  </MDTypography>
                  <TextareaAutosize
                    name="embed_value"
                    minRows={10}
                    value={codeBlockValue}
                    style={{
                      borderColor: darkMode ? "rgba(255, 255, 255, 0.5)" : "rgb(12 10 10 / 12%)",
                      outline: "none",
                      width: "100%",
                      //maxHeight: "200px",
                      //height: "100px !important",
                      padding: "12px",
                      fontSize: "0.9rem",
                      resize: "vertical",
                      overflow: "auto",
                      //minHeight: "80px",
                      color: darkMode ? "white" : "black",
                      backgroundColor: "transparent",
                      borderRadius: "8px",
                    }}
                    readOnly
                  />

                  <Tooltip
                    title={lang("ucwords")("G_COPIED")}
                    open={tooltipOpen}
                    arrow
                    placement="top"
                  >
                    <MDBox
                      component="span"
                      sx={{
                        position: "absolute",
                        bottom: 15,
                        left: "50%",
                        transform: "translateX(-50%)",
                        color: darkMode ? "lightgrey !important" : "white !important",
                        cursor: "pointer",
                        textDecoration: "none",
                        padding: "2px 10px",
                        borderRadius: "16px",
                        transition: "background-color 0.3s ease, color 0.3s ease",
                        backgroundColor: "black !important",
                        fontSize: "12px",
                        "&:hover": {
                          backgroundColor: "#2C2C2C !important",
                        },
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        copyToClipboard();
                      }}
                    >
                      {lang("ucwords")("G_COPY_TO_CLIPBOARD")}
                    </MDBox>
                  </Tooltip>
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
        </CardContent>
      </Panel>
    </Grid>
  );
};

APIPanel.propTypes = {
  sid: PropTypes.string,
};

export default APIPanel;
