import { useGetServices, getBackendUrl } from "services"; // Adjust path as needed
import axios from "../axiosConfig";

export const useChatAssistantService = () => {
  const { getToken, getCurrentWorkspace } = useGetServices();

  const getChatAssistants = async ({ workspace, token } = {}) => {
    console.log("Fetching chat assistants...");
    const authToken = token || getToken();
    const workspaceParam = workspace || getCurrentWorkspace();
    const response = await axios.get(
      `${getBackendUrl()}/workspaces/${workspaceParam}/assistants/chat`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  };

  const getChatPromptTemplates = async ({ token } = {}) => {
    console.log("Fetching chat assistants prompt templates...");
    const authToken = token || getToken();
    const response = await axios.get(`${getBackendUrl()}/assistants/chat/templates`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  };

  const addChatAssistant = async ({ workspace, token, name, model } = {}) => {
    console.log("Adding chat assistant...");
    const authToken = token || getToken();
    const workspaceParam = workspace || getCurrentWorkspace();
    const response = await axios.post(
      `${getBackendUrl()}/workspaces/${workspaceParam}/assistants/chat`,
      {
        name,
        model,
      },
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  };

  const duplicateChatAssistant = async ({ sid, name, workspace, token } = {}) => {
    console.log("Duplicating chat assistant...");
    const authToken = token || getToken();
    const workspaceParam = workspace || getCurrentWorkspace();
    const response = await axios.post(
      `${getBackendUrl()}/workspaces/${workspaceParam}/assistants/chat/${sid}`,
      {
        name,
      },
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  };

  const moveChatAssistant = async ({ sid, new_workspace, workspace, token } = {}) => {
    console.log("Duplicating chat assistant...");
    const authToken = token || getToken();
    const workspaceParam = workspace || getCurrentWorkspace();
    const response = await axios.put(
      `${getBackendUrl()}/workspaces/${workspaceParam}/assistants/chat/${sid}`,
      {
        workspace_sid: new_workspace,
      },
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  };

  const editChatAssistant = async ({ workspace, token, sid, name, model } = {}) => {
    console.log("Editing chat assistant...", sid);
    const authToken = token || getToken();
    const workspaceParam = workspace || getCurrentWorkspace();
    const response = await axios.patch(
      `${getBackendUrl()}/workspaces/${workspaceParam}/assistants/chat/${sid}`,
      {
        name,
        model,
      },
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  };

  const deleteChatAssistant = async ({ workspace, token, sid } = {}) => {
    console.log("Deleting api key...", sid);
    const authToken = token || getToken();
    const workspaceParam = workspace || getCurrentWorkspace();
    const response = await axios.delete(
      `${getBackendUrl()}/workspaces/${workspaceParam}/assistants/chat/${sid}`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  };

  const editEmbedSettings = async ({
    workspace,
    token,
    sid,
    error_message,
    placeholder_text,
    start_message = "",
    allowed_origins = [],
  } = {}) => {
    console.log("Editing embed settings...", sid);
    const authToken = token || getToken();
    const workspaceParam = workspace || getCurrentWorkspace();
    const response = await axios.patch(
      `${getBackendUrl()}/workspaces/${workspaceParam}/assistants/embed/${sid}`,
      {
        error_message,
        placeholder_text,
        start_message,
        allowed_origins,
      },
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  };

  return {
    getChatAssistants,
    addChatAssistant,
    editChatAssistant,
    deleteChatAssistant,
    getChatPromptTemplates,
    duplicateChatAssistant,
    moveChatAssistant,
    editEmbedSettings,
  };
};
