import { useState, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Breadcrumbs from "components/Breadcrumbs";
import NotificationItem from "components/Items/NotificationItem";
import TripleToggleSwitch from "./components/TripleToggleSwitch";
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "components/Navbars/DashboardNavbar/styles";
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setFixedNavbar,
} from "context";
import { Select, MenuItem } from "@mui/material";
import DialogWorkSpaces from "components/Dialogs/DialogWorkSpaces";
import { useUserContext } from "context/UserContext";
import { useNavigate } from "react-router-dom";
import { useSelectToggle } from "hooks";

function DashboardNavbar({ absolute, light, isMini }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, darkMode, sidenavColor } = controller;
  const route = useLocation().pathname.split("/").slice(1);
  const [dialogOpen, setDialogOpen] = useState(false);
  const { workspaces, currentWorkspace, setCurrentWorkspace, logout, userData, isSuperAdmin } =
    useUserContext();
  const [selectedValue, setSelectedValue] = useState("");
  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const isSuperAdminUser = useMemo(() => isSuperAdmin(), [userData]);
  const [anchorEl, setAnchorEl] = useState(null);
  //const navigate = useNavigate();
  const { selectOpen, handleIconClick, closeSelect, openSelect } = useSelectToggle();

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    setSelectedValue(currentWorkspace);

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  // Dynamically create MenuItem components based on workspaces
  const workspaceOptions = workspaces
    ? Object.entries(workspaces).map(([key, value]) => (
        <MenuItem key={key} value={key}>
          {value.name}
        </MenuItem>
      ))
    : null;

  const handleLogout = () => {
    logout({ logoutParams: { returnTo: window.location.origin } });
    y;
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleWorkspaceClick = (event) => {
    setDialogOpen(true);
  };

  const handleCloseDialog = (event) => {
    setDialogOpen(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    setCurrentWorkspace(event.target.value);
    //navigate(`/dashboard`);
    window.location.reload(); // Reloads the current page
  };

  const handleFixedNavBar = () => {
    setFixedNavbar(dispatch, !fixedNavbar);
  };

  const renderMenu = () => (
    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
      <NotificationItem
        onClick={handleLogout}
        icon={<Icon>logout</Icon>}
        title="Logout"
      ></NotificationItem>
    </Menu>
  );

  // Styles for the navbar icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} />
        </MDBox>
        {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
            <MDBox display="flex" alignItems="center">
              <MDTypography
                sx={{
                  marginRight: 1,
                  fontSize: "0.875rem",
                  display: {
                    xs: "none", // Hide on xs screens
                    sm: "block", // Show on sm and larger screens
                  },
                }}
              >
                Workspace:
              </MDTypography>
              <Select
                name="workspace"
                labelId="workspace-label"
                value={selectedValue}
                onChange={handleChange}
                open={selectOpen["workspace"] || false}
                onClose={() => closeSelect("workspace")}
                onOpen={() => openSelect("workspace")}
                variant="outlined"
                margin="none"
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                sx={{
                  maxWidth: {
                    xs: "100px",
                    sm: "100%",
                  },
                  height: 25.5,
                }}
                IconComponent={(key) => (
                  <IconButton edge="end" onClick={(event) => handleIconClick("workspace", event)}>
                    <Icon
                      sx={{
                        color: darkMode ? "#fff" : "black",
                        fontSize: "18px !important",
                        marginRight: "10px",
                      }}
                    >
                      expand_more
                    </Icon>
                  </IconButton>
                )}
              >
                {workspaceOptions}
              </Select>
              {isSuperAdminUser && (
                <>
                  <DialogWorkSpaces
                    open={dialogOpen}
                    onClose={handleCloseDialog}
                    title="Workspace Manager"
                  />
                  <IconButton
                    sx={navbarIconButton}
                    size="small"
                    disableRipple
                    onClick={handleWorkspaceClick}
                  >
                    <Icon sx={iconsStyle}>dashboard</Icon>
                  </IconButton>
                </>
              )}
            </MDBox>

            <MDBox color={light ? "white" : "inherit"}>
              <IconButton sx={navbarIconButton} size="small" disableRipple onClick={handleClick}>
                <Icon sx={iconsStyle}>account_circle</Icon>
              </IconButton>

              {renderMenu()}
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon sx={iconsStyle} fontSize="medium">
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>
              <IconButton
                //sx={navbarIconButton}
                size="small"
                disableRipple
                onClick={handleFixedNavBar}
              >
                <Icon color={fixedNavbar ? "secondary" : sidenavColor}>push_pin</Icon>
              </IconButton>
            </MDBox>
            <MDBox color={light ? "white" : "inherit"}>
              <TripleToggleSwitch />
            </MDBox>
          </MDBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
