import React, { createContext, useState, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import { useAuth0 } from "@auth0/auth0-react";
import { useGetProviders } from "services/providerService";
import { useKnowledgeBaseServices } from "services/knowledgeBaseService";
import { useChatAssistantService } from "services/chatAssistantService";
import { jwtDecode } from "jwt-decode";
import CircularProgress from "@mui/material/CircularProgress";
import DialogComponent from "components/Dialogs/Dialog";
import { getUiConfig, setUiConfig } from "localStorage";

const UserContext = createContext({
  userData: null,
  setUserData: () => {},
  userToken: null,
  setUserToken: () => {},
  currentWorkspace: null,
  setCurrentWorkspace: () => {},
  workspaces: null,
  setWorkspaces: () => {},
  providers: null,
  setProviders: () => {},
  getProviders: () => {},
  decodeToken: () => {},
  isSuperAdmin: () => {},
  logout: () => {},
  knowledgeBaseFiles: [],
  getKnowledgeBaseFiles: () => {},
  setKnowledgeBaseFiles: () => {},
  chatPromptTemplates: null,
  getChatPromptTemplates: () => {},
  setChatPromptTemplates: () => {},
});

const UserProvider = ({ children }) => {
  const [userData, setUserData] = useState(null);
  const [userToken, setUserToken] = useState(null);
  const [currentWorkspace, setCurrentWorkspace] = useState(null);
  const [workspaces, setWorkspaces] = useState(null);
  const [providers, setProviders] = useState(null);
  const [knowledgeBaseFiles, setKnowledgeBaseFiles] = useState(null);
  const [chatPromptTemplates, setChatPromptTemplates] = useState(null);
  const [isAuthLoading, setIsAuthLoading] = useState(true);
  const [isLoginError, setIsLoginError] = useState(null);
  const [loginErrorMsg, setLoginErrorMsg] = useState(null);
  const [isFinishedSetup, setIsFinishedSetup] = useState(false);
  const {
    isAuthenticated,
    isLoading,
    user,
    loginWithRedirect,
    logout: authLogout,
    getAccessTokenSilently,
  } = useAuth0();
  const { getProviders: getServiceProviders } = useGetProviders();
  const { getKnowledgeBaseFiles: getKBFiles } = useKnowledgeBaseServices();
  const { getChatPromptTemplates: getCPTemplates } = useChatAssistantService();
  const { search } = useLocation();

  const handleCloseLoginErrorDialog = () => {
    authLogout({ logoutParams: { returnTo: window.location.origin } });
  };

  const handleSetUserData = (newData) => {
    console.log("Setting user data:", newData);
    setUserData((prevData) => ({
      ...prevData,
      ...newData,
    }));
  };

  const handleSetUserToken = (newData) => {
    console.log("Setting user token:", newData);
    setUserToken(newData);
  };

  const handleSetCurrentWorkspace = (newData) => {
    console.log("Setting user current workspace:", newData);
    setCurrentWorkspace(newData);
    setUiConfig({ current_workspace: newData });
    //localStorage.setItem("currentWorkspace", newData);
  };

  const handleSetWorkspaces = (newData) => {
    console.log("Setting user workspaces:", newData);
    setWorkspaces(newData);
  };

  const handleSetProviders = (newData) => {
    console.log("Setting user providers:", newData);
    setProviders(newData);
  };

  const handleSetKnowledgeBaseFiles = (newData) => {
    console.log("Setting knowledge base files:", newData);
    setKnowledgeBaseFiles(newData);
  };

  const handleSetChatPromptTemplates = (newData) => {
    console.log("Setting chat prompt templates:", newData);
    setChatPromptTemplates(newData);
  };

  const handleDecodeToken = (token = userToken) => {
    try {
      console.log("Decoding token:", token);
      const decoded = jwtDecode(token);
      console.log("Decoded token:", decoded);
      return decoded;
    } catch (error) {
      console.error("Error decoding token:", error);
      return null;
    }
  };

  const handleIsSuperAdmin = (token = userToken) => {
    try {
      const decoded = handleDecodeToken(token);

      if (decoded.is_superadmin) {
        console.log("User is a superadmin.");
        return true;
      }

      console.log("User is not a superadmin.");
      return false;
    } catch (error) {
      console.error("Error decoding token:", error);
      return null;
    }
  };

  const setCookie = (name, value, days) => {
    let expires = "";
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  };

  const handleGetProviders = async (workspace = currentWorkspace, token = userToken) => {
    const providers = await getServiceProviders({ workspace: workspace, token: token });
    return providers.data;
  };

  const handleGetKnowledgeBaseFiles = async (workspace = currentWorkspace, token = userToken) => {
    const knowledgeBaseFiles = await getKBFiles({ workspace: workspace, token: token });
    return knowledgeBaseFiles.data;
  };

  const handleGetChatPromptTemplates = async (token = userToken) => {
    const chatPromptTemplates = await getCPTemplates({ token: token });
    return chatPromptTemplates.data;
  };

  useEffect(() => {
    const checkAuthStatus = async () => {
      const queryParams = new URLSearchParams(search);
      const login_error = queryParams.get("error");
      const errorDescription = queryParams.get("error_description");

      if (login_error) {
        setIsLoginError(true);
        setLoginErrorMsg(errorDescription);
        return;
      }

      if (isLoading) {
        setIsAuthLoading(true);
      } else if (!isAuthenticated) {
        loginWithRedirect();
      } else {
        setIsAuthLoading(false);
        if (user) {
          handleSetUserData(user);
          const accessToken = await getAccessTokenSilently();
          handleSetUserToken(accessToken);
          if (handleIsSuperAdmin(accessToken)) {
            setCookie("accessToken", accessToken, 7);
          }
          const newWorkspaces = user.user_metadata.workspaces;
          handleSetWorkspaces(newWorkspaces);
          const saveWorkspace = getUiConfig("current_workspace");
          const firstWorkspace = Object.keys(newWorkspaces)[0];
          let currentWorkspace = saveWorkspace || firstWorkspace;
          currentWorkspace = Object.keys(newWorkspaces).includes(currentWorkspace)
            ? currentWorkspace
            : firstWorkspace;
          handleSetCurrentWorkspace(currentWorkspace);
          const providers = await getServiceProviders({
            token: accessToken,
            workspace: currentWorkspace,
          });
          handleSetProviders(providers.data);
          const knowledgeBaseFiles = await getKBFiles({
            token: accessToken,
            workspace: currentWorkspace,
          });
          handleSetKnowledgeBaseFiles(knowledgeBaseFiles.data);
          const chatPromptTemplates = await getCPTemplates({
            token: accessToken,
          });
          handleSetChatPromptTemplates(chatPromptTemplates.data);
          setIsFinishedSetup(true);
        }
      }
    };

    checkAuthStatus();
  }, [isLoading, isAuthenticated, user, search, getAccessTokenSilently, loginWithRedirect]);

  const pageLoder = () => {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  };

  if (isLoginError) {
    return (
      <DialogComponent
        open={true}
        onClose={handleCloseLoginErrorDialog}
        title="Authentication"
        message={loginErrorMsg}
        onButtonClick={handleCloseLoginErrorDialog}
        buttonText="Go to login"
      />
    );
  } else if (isAuthLoading || !isAuthenticated || !isFinishedSetup) {
    return pageLoder();
  }

  return (
    <UserContext.Provider
      value={{
        userData,
        setUserData: handleSetUserData,
        userToken,
        setUserToken: handleSetUserToken,
        currentWorkspace,
        setCurrentWorkspace: handleSetCurrentWorkspace,
        workspaces,
        setWorkspaces: handleSetWorkspaces,
        providers,
        setProviders: handleSetProviders,
        getProviders: handleGetProviders,
        decodeToken: handleDecodeToken,
        isSuperAdmin: handleIsSuperAdmin,
        logout: authLogout,
        knowledgeBaseFiles,
        getKnowledgeBaseFiles: handleGetKnowledgeBaseFiles,
        setKnowledgeBaseFiles: handleSetKnowledgeBaseFiles,
        chatPromptTemplates,
        getChatPromptTemplates: handleGetChatPromptTemplates,
        setChatPromptTemplates: handleSetChatPromptTemplates,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

// Define PropTypes for the UserProvider
UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Custom hook for using the context
const useUserContext = () => useContext(UserContext);

export { UserProvider, useUserContext };
