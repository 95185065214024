// axiosConfig.js

import axios from "axios";

// Create an Axios instance without default configurations
const instance = axios.create({ timeout: 10 * 60 * 1000 }); // 10 minutes

// Set up interceptors
instance.interceptors.response.use(
  (response) => {
    // Handle successful responses (2xx) or modify if needed
    // Uncomment if you need to check non-200 status codes
    // if (response.status !== 200) {
    //   console.warn(`Received non-200 status code: ${response.status}`);
    // }
    return response;
  },
  (error) => {
    // Handle errors here
    console.error("Axios error occurred:", error);
    //return Promise.reject(error);
    return error.response;
  }
);

export default instance;
