import React, { useState } from "react";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Grid,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import Icon from "@mui/material/Icon";
import Axios from "axios"; // Import Axios
import { useMaterialUIController } from "context";
import { backendAddress } from "configDefaults"; // Import backendAddress from your configuration

const HealthCheck = () => {
  const [checkAll, setCheckAll] = useState(false);
  const [checkOpenVoiceServer, setCheckOpenVoiceServer] = useState(false);
  const [tryOpenAICompletion, setTryOpenAICompletion] = useState(false);
  const [checkOllamaServer, setCheckOllamaServer] = useState(false);
  const [checkLMStudioServer, setCheckLMStudioServer] = useState(false);
  const [checkElevenLabsCredits, setCheckElevenLabsCredits] = useState(false);
  const [checkFWServer, setCheckFWServer] = useState(false);
  const [output, setOutput] = useState("");
  const [checkAllLabel, setCheckAllLabel] = useState("Check All");
  const [submitting, setSubmitting] = useState(false);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    if (name === "checkAll") {
      setCheckAll(checked);
      setCheckOpenVoiceServer(checked);
      setTryOpenAICompletion(checked);
      setCheckOllamaServer(checked);
      setCheckLMStudioServer(checked);
      setCheckElevenLabsCredits(checked);
      setCheckFWServer(checked);
      setCheckAllLabel(checked ? "Uncheck All" : "Check All");
    } else if (name === "checkOpenVoiceServer") {
      setCheckOpenVoiceServer(checked);
    } else if (name === "tryOpenAICompletion") {
      setTryOpenAICompletion(checked);
    } else if (name === "checkOllamaServer") {
      setCheckOllamaServer(checked);
    } else if (name === "checkLMStudioServer") {
      setCheckLMStudioServer(checked);
    } else if (name === "checkElevenLabsCredits") {
      setCheckElevenLabsCredits(checked);
    } else if (name === "checkFWServer") {
      setCheckFWServer(checked);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitting(true);
    setOutput("Checking...");

    // Send checkboxes data to backend
    const formData = {
      checkAll,
      checkOpenVoiceServer,
      tryOpenAICompletion,
      checkOllamaServer,
      checkLMStudioServer,
      checkElevenLabsCredits,
      checkFWServer,
    };

    Axios.post(`${backendAddress}/health-check`, formData)
      .then((response) => {
        setOutput(response.data.result["data"]["message"]);
      })
      .catch((error) => {
        //console.error("Error submitting form:", error);
        setOutput("Error occurred. Please try again.");
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const isAnyCheckboxChecked =
    checkAll ||
    checkOpenVoiceServer ||
    tryOpenAICompletion ||
    checkOllamaServer ||
    checkLMStudioServer ||
    checkElevenLabsCredits ||
    checkFWServer;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container justifyContent="center" style={{ minHeight: "calc(100vh - 64px)" }}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Card>
            <CardContent>
              <Typography variant="h4" gutterBottom>
                <Icon fontSize="small">medication</Icon> Health Check
              </Typography>
              <form onSubmit={handleSubmit}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkAll}
                      onChange={handleCheckboxChange}
                      color="primary"
                      inputProps={{ "aria-label": "primary checkbox" }}
                      name="checkAll"
                    />
                  }
                  label={checkAllLabel}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkOpenVoiceServer}
                      onChange={handleCheckboxChange}
                      color="primary"
                      inputProps={{ "aria-label": "primary checkbox" }}
                      name="checkOpenVoiceServer"
                    />
                  }
                  label="Check OpenVoice server availability"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={tryOpenAICompletion}
                      onChange={handleCheckboxChange}
                      color="primary"
                      inputProps={{ "aria-label": "primary checkbox" }}
                      name="tryOpenAICompletion"
                    />
                  }
                  label="Try OpenAI completion request"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkOllamaServer}
                      onChange={handleCheckboxChange}
                      color="primary"
                      inputProps={{ "aria-label": "primary checkbox" }}
                      name="checkOllamaServer"
                    />
                  }
                  label="Try Ollama completion request"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkLMStudioServer}
                      onChange={handleCheckboxChange}
                      color="primary"
                      inputProps={{ "aria-label": "primary checkbox" }}
                      name="checkLMStudioServer"
                    />
                  }
                  label="Try LM Studio completion request"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkElevenLabsCredits}
                      onChange={handleCheckboxChange}
                      color="primary"
                      inputProps={{ "aria-label": "primary checkbox" }}
                      name="checkElevenLabsCredits"
                    />
                  }
                  label="Try ElevenLabs text to speech request"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkFWServer}
                      onChange={handleCheckboxChange}
                      color="primary"
                      inputProps={{ "aria-label": "primary checkbox" }}
                      name="checkFWServer"
                    />
                  }
                  label="Check Fast Whisper server availability"
                />
                <Box mt={2}>
                  <TextareaAutosize
                    minRows={5}
                    maxRows={10}
                    aria-label="Output"
                    placeholder="Output"
                    style={{
                      width: "100%",
                      minHeight: "40px",
                      fontSize: "1rem",
                      resize: "vertical",
                      padding: "8px",
                      marginBottom: "10px",
                      border: "none",
                      outline: "none",
                      overflow: "auto",
                      color: darkMode ? "white" : "black",
                      backgroundColor: darkMode ? "#333" : "#fff",
                    }}
                    value={output}
                    onChange={(e) => setOutput(e.target.value)}
                    readOnly
                  />
                </Box>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{ color: "white" }}
                  disabled={!isAnyCheckboxChecked || submitting} // Disable if no checkbox is checked or submitting
                >
                  {submitting ? "Submitting..." : "Submit"}
                </Button>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default HealthCheck;
