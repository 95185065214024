import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import { useMaterialUIController } from "context";
import theme from "assets/theme";
import themeDark from "assets/theme-dark";
import { lang, useSelectToggle } from "hooks";
import { Icon } from "@mui/material";
import { useUserContext } from "context/UserContext";
import { Select, MenuItem, IconButton, FormControl, InputLabel } from "@mui/material";
import { MDTypography, MDBox } from "components";

const MoveRecordDialog = ({
  open,
  onClose,
  title,
  alertText,
  onButtonClick,
  buttonText,
  closeOnBackdropClick = true, // Default to true
}) => {
  const [internalOpen, setInternalOpen] = useState(open);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const myTheme = darkMode ? themeDark : theme;
  const [selectedValue, setSelectedValue] = useState("");
  const { workspaces, currentWorkspace } = useUserContext();
  const { selectOpen, handleIconClick, closeSelect, openSelect } = useSelectToggle();

  const workspaceOptions = workspaces
    ? Object.entries(workspaces)
        .filter(([key]) => key !== currentWorkspace) // Filter out the current workspace
        .map(([key, value]) => (
          <MenuItem key={key} value={key}>
            {value.name}
          </MenuItem>
        ))
    : null;

  useEffect(() => {
    setInternalOpen(open); // Sync internal state with the open prop
    setSelectedValue("");
  }, [open]);

  const handleClose = (event, reason) => {
    if (reason === "backdropClick" && !closeOnBackdropClick) {
      // Do nothing if clicking on the backdrop and closeOnBackdropClick is false
      return;
    }
    setInternalOpen(false); // Close the dialog by setting the internal state
    if (onClose && typeof onClose === "function") {
      onClose(event, reason); // Call the onClose prop if it exists
    }
  };

  const handleInputChange = (event) => {
    setSelectedValue(event.target.value); // Update input value state
  };

  return (
    <Dialog
      open={internalOpen}
      onClose={handleClose} // Use the custom handleClose function
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: {
          backgroundColor: darkMode ? myTheme.palette.dark.main : myTheme.palette.white.main,
          color: darkMode ? myTheme.palette.dark.main : myTheme.palette.white.main,
          minWidth: "350px",
          //maxWidth: "500px",
        },
      }}
      disableEscapeKeyDown={!closeOnBackdropClick} // Prevent closing with the Escape key if closeOnBackdropClick is false
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{
          color: darkMode ? myTheme.palette.white.main : myTheme.palette.dark.main,
        }}
      >
        <Icon
          sx={{
            color: darkMode ? theme.palette.white.main : theme.palette.dark.main,
            fontSize: "18px !important",
            paddingRight: "20px",
          }}
        >
          move_up_outlined
        </Icon>
        {title || "Dialog Title"}
      </DialogTitle>
      <DialogContent
        sx={{
          color: darkMode ? myTheme.palette.white.main : myTheme.palette.dark.main,
        }}
      >
        <FormControl fullWidth margin="normal">
          <InputLabel id="workspace-label">{lang("ucfirst")("G_SELECT_WORKSPACE")}*</InputLabel>
          <Select
            name="workspace"
            labelId="workspace-label"
            value={selectedValue}
            onChange={handleInputChange}
            open={selectOpen["workspace"] || false}
            onClose={() => closeSelect("workspace")}
            onOpen={() => openSelect("workspace")}
            variant="outlined"
            margin="none"
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            sx={{ height: 43.5 }}
            IconComponent={(key) => (
              <IconButton edge="end" onClick={(event) => handleIconClick("workspace", event)}>
                <Icon
                  sx={{
                    color: darkMode ? "#fff" : "black",
                    fontSize: "18px !important",
                    marginRight: "10px",
                  }}
                >
                  expand_more
                </Icon>
              </IconButton>
            )}
          >
            {workspaceOptions}
          </Select>
        </FormControl>
        {alertText && (
          <MDBox mt={2} ml={0.5}>
            <MDTypography
              variant="body1"
              component="label"
              fontWeight="bold"
              color="error"
              style={{ fontSize: "16px" }}
            >
              {alertText}
            </MDTypography>
          </MDBox>
        )}
      </DialogContent>
      <DialogActions
        sx={{
          color: darkMode ? myTheme.palette.white.main : myTheme.palette.dark.main,
        }}
      >
        <Button
          disabled={selectedValue ? false : true}
          onClick={() => {
            if (onButtonClick) {
              onButtonClick(selectedValue);
            }
            setInternalOpen(false);
          }}
          color="secondary"
          //autoFocus
          sx={{
            color: darkMode ? myTheme.palette.white.main : myTheme.palette.white.main,
          }}
          variant={darkMode ? "outlined" : "contained"}
        >
          {buttonText || "Close"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

// Define PropTypes for the component
MoveRecordDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  title: PropTypes.string,
  alertText: PropTypes.string,
  onButtonClick: PropTypes.func,
  buttonText: PropTypes.string,
  closeOnBackdropClick: PropTypes.bool, // Ensure this is included
};

export default MoveRecordDialog;
