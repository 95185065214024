import TestConversation from "pages/legacy/test-conversation";
import TestCall from "pages/legacy/test-call";
import HealthCheck from "pages/legacy/health-check";
import BotVsBot from "pages/legacy/bot-vs-bot";
import ChatAssistants from "pages/chat-assistants";
import EmptyPage from "pages/empty";
import Providers from "pages/providers";
import Files from "pages/files";
import ApiKeys from "pages/api-keys";
import Icon from "@mui/material/Icon";
import { getSubdomainAddress } from "services";

const API_URL = getSubdomainAddress("api");

const routes = [
  {
    type: "link",
    name: "{G_OVERVIEW}",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/overview",
    component: <EmptyPage />,
  },
  {
    type: "collapse",
    name: "{G_ASSISTANTS}",
    key: "assistants",
    route: "/assistants",
    disabled: true,
    icon: <Icon fontSize="small">people</Icon>,
    children: [
      {
        type: "link",
        name: "{G_CHAT_ASSISTANTS}",
        key: "agents/chat",
        icon: <Icon fontSize="small">comment</Icon>,
        route: "/assistants/chat",
        component: <ChatAssistants />,
      },
      /*{
        type: "link",
        name: "Voice Assistants",
        key: "agents/voice",
        icon: <Icon fontSize="small">mic</Icon>,
        route: "/assistants/voice",
        component: <EmptyPage />,
      },*/
    ],
  },
  {
    type: "collapse",
    name: "{G_SETTINGS}",
    key: "settings",
    route: "/settings",
    disabled: true,
    icon: <Icon fontSize="small">settings</Icon>,
    children: [
      {
        type: "link",
        name: "{G_PROVIDERS}",
        key: "providers",
        icon: <Icon fontSize="small">public</Icon>,
        route: "/settings/providers",
        component: <Providers />,
      },
      {
        type: "link",
        name: "{G_API_KEYS}",
        key: "api-keys",
        icon: <Icon fontSize="small">key</Icon>,
        route: "/settings/api-keys",
        component: <ApiKeys />,
      },
      {
        type: "link",
        name: "{G_FILES}",
        key: "files",
        icon: <Icon fontSize="small">insert_drive_file</Icon>,
        route: "/settings/files",
        component: <Files />,
      },
    ],
  },
  {
    type: "collapse",
    name: "{G_LOGS}",
    key: "logs",
    route: "/logs",
    disabled: true,
    icon: <Icon fontSize="small">history</Icon>,
    children: [
      {
        type: "link",
        name: "{G_CONVERSATIONS}",
        key: "conversations",
        icon: <Icon fontSize="small">question_answer</Icon>,
        route: "/logs/conversations",
        component: <EmptyPage />,
      },
      {
        type: "link",
        name: "{G_REQUESTS}",
        key: "requests",
        icon: <Icon fontSize="small">equalizer</Icon>,
        route: "/logs/requests",
        component: <EmptyPage />,
      },
    ],
  },
  {
    type: "link",
    name: "{G_USAGE}",
    key: "usage",
    icon: <Icon fontSize="small">data_usage</Icon>,
    route: "/usage",
    component: <EmptyPage />,
  },
  {
    type: "collapse",
    name: "{G_HELP_AND_SUPPORT}",
    key: "help-and-support",
    route: "/help-and-support",
    disabled: true,
    icon: <Icon fontSize="small">help_outline</Icon>,
    children: [
      {
        type: "link",
        name: "{G_API_REFERENCE}",
        key: "api-reference",
        external: true,
        icon: <Icon fontSize="small">api</Icon>,
        href: `${API_URL}/docs/api`,
      },
      {
        type: "link",
        name: "{G_ASK_QUESTION}",
        key: "ask-a-question",
        icon: <Icon fontSize="small">psychology_alt</Icon>,
        route: "/help-and-support/questions",
        component: <EmptyPage />,
        onClick: "openChatEl",
      },
    ],
  },
  {
    type: "collapse",
    name: "Legacy",
    key: "legacy",
    route: "/legacy",
    disabled: true,
    icon: <Icon fontSize="small">archive</Icon>,
    children: [
      {
        type: "link",
        name: "Test Conversation",
        key: "test-conversation",
        icon: <Icon fontSize="small">mic</Icon>,
        route: "/legacy/test-conversation",
        component: <TestConversation />,
      },
      {
        type: "link",
        name: "Test Call",
        key: "test-call",
        icon: <Icon fontSize="small">call</Icon>,
        route: "/legacy/test-call",
        component: <TestCall />,
      },
      {
        type: "link",
        name: "Bot Versus Bot",
        key: "bot-vs-bot",
        icon: <Icon fontSize="small">people</Icon>,
        route: "/legacy/bot-vs-bot",
        component: <BotVsBot />,
      },
      {
        type: "link",
        name: "Health Check",
        key: "health-check",
        icon: <Icon fontSize="small">medication</Icon>,
        route: "/legacy/health-check",
        component: <HealthCheck />,
      },
    ],
  },
];

export default routes;
