import React, { createContext, useContext, useReducer, useMemo, useEffect, useState } from "react"; // Import useState here
import PropTypes from "prop-types";
import { getUiConfig, setUiConfig } from "localStorage";

// Material Dashboard 2 React main context
const MaterialUI = createContext();

// Setting custom name for the context which is visible on react dev tools
MaterialUI.displayName = "MaterialUIContext";

// Material Dashboard 2 React reducer
function reducer(state, action) {
  switch (action.type) {
    case "MINI_SIDENAV": {
      return { ...state, miniSidenav: action.value };
    }
    case "TRANSPARENT_SIDENAV": {
      return { ...state, transparentSidenav: action.value };
    }
    case "WHITE_SIDENAV": {
      return { ...state, whiteSidenav: action.value };
    }
    case "SIDENAV_COLOR": {
      setUiConfig({ sidenav_color: action.value });
      return { ...state, sidenavColor: action.value };
    }
    case "TRANSPARENT_NAVBAR": {
      return { ...state, transparentNavbar: action.value };
    }
    case "FIXED_NAVBAR": {
      setUiConfig({ fixed_navbar: action.value });
      return { ...state, fixedNavbar: action.value };
    }
    case "OPEN_CONFIGURATOR": {
      return { ...state, openConfigurator: action.value };
    }
    case "DIRECTION": {
      return { ...state, direction: action.value };
    }
    case "LAYOUT": {
      return { ...state, layout: action.value };
    }
    case "DARKMODE": {
      return { ...state, darkMode: action.value };
    }
    case "CURRENTMODE": {
      return { ...state, currentMode: action.value };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

const getCookie = (name) => {
  const cookieValue = document.cookie.match("(^|;)\\s*" + name + "\\s*=\\s*([^;]+)");
  return cookieValue ? cookieValue.pop() : null;
};

const setCookie = (name, value, days) => {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
};

// Material Dashboard 2 React context provider
function MaterialUIControllerProvider({ children }) {
  const useThemeDetector = () => {
    const [isDarkTheme, setIsDarkTheme] = useState(); // Default to light theme initially
    const [currentMode, setCurrentMode] = useState(); // Default to light mode initially

    useEffect(() => {
      const savedMode = getCookie("selectedMode");
      const savedSidenavColor = getCookie("sidenavColor");

      const setThemeFromCookie = (mode) => {
        if (mode === "auto") {
          const getCurrentTheme = () => window.matchMedia("(prefers-color-scheme: dark)").matches;
          setDarkMode(dispatch, getCurrentTheme());
        } else {
          setDarkMode(dispatch, mode === "dark");
        }
      };

      if (savedMode) {
        setThemeFromCookie(savedMode);
        setCurrentMode(savedMode); // Set current mode from cookie
      } else {
        const currentTheme = window.matchMedia("(prefers-color-scheme: dark)").matches;
        setDarkMode(dispatch, currentTheme);
        setCurrentMode(currentTheme ? "dark" : "light"); // Set current mode based on OS theme
        setCookie("selectedMode", "auto", 10 * 365);
      }

      const mqListener = (e) => {
        setIsDarkTheme(e.matches);
      };

      const darkThemeMq = window.matchMedia("(prefers-color-scheme: dark)");
      darkThemeMq.addListener(mqListener);
      setIsDarkTheme(darkThemeMq.matches); // Set initial theme state based on media query
      return () => darkThemeMq.removeListener(mqListener);
    }, []);
    return { isDarkTheme, currentMode };
  };

  const { isDarkTheme, currentMode } = useThemeDetector();

  const initialState = {
    miniSidenav: false,
    transparentSidenav: false,
    whiteSidenav: false,
    sidenavColor: getUiConfig("sidenav_color", "success"),
    transparentNavbar: false,
    fixedNavbar: getUiConfig("fixed_navbar", true),
    openConfigurator: false,
    direction: "ltr",
    layout: "dashboard",
    darkMode: isDarkTheme,
    currentMode: currentMode,
  };

  const [controller, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    dispatch({ type: "CURRENTMODE", value: currentMode });
  }, [currentMode]);

  const value = useMemo(() => [controller, dispatch], [controller, dispatch]);

  return <MaterialUI.Provider value={value}>{children}</MaterialUI.Provider>;
}

// Material Dashboard 2 React custom hook for using context
function useMaterialUIController() {
  const context = useContext(MaterialUI);

  if (!context) {
    throw new Error(
      "useMaterialUIController should be used inside the MaterialUIControllerProvider."
    );
  }

  return context;
}

// Typechecking props for the MaterialUIControllerProvider
MaterialUIControllerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Context module functions
const setMiniSidenav = (dispatch, value) => dispatch({ type: "MINI_SIDENAV", value });
const setTransparentSidenav = (dispatch, value) => dispatch({ type: "TRANSPARENT_SIDENAV", value });
const setWhiteSidenav = (dispatch, value) => dispatch({ type: "WHITE_SIDENAV", value });
const setSidenavColor = (dispatch, value) => dispatch({ type: "SIDENAV_COLOR", value });
const setTransparentNavbar = (dispatch, value) => dispatch({ type: "TRANSPARENT_NAVBAR", value });
const setFixedNavbar = (dispatch, value) => dispatch({ type: "FIXED_NAVBAR", value });
const setOpenConfigurator = (dispatch, value) => dispatch({ type: "OPEN_CONFIGURATOR", value });
const setDirection = (dispatch, value) => dispatch({ type: "DIRECTION", value });
const setLayout = (dispatch, value) => dispatch({ type: "LAYOUT", value });
const setDarkMode = (dispatch, value) => dispatch({ type: "DARKMODE", value });
const setCurrentMode = (dispatch, value) => dispatch({ type: "CURRENTMODE", value });

export {
  MaterialUIControllerProvider,
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
  setSidenavColor,
  setTransparentNavbar,
  setFixedNavbar,
  setOpenConfigurator,
  setDirection,
  setLayout,
  setDarkMode,
  setCurrentMode,
};

export { useUserContext } from "./UserContext";
export { FormValuesProvider, FormValuesContext } from "./FormValuesContext";
