import React from "react";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";

const EmptyPage = () => {
  return (
    <DashboardLayout>
      <DashboardNavbar />
    </DashboardLayout>
  );
};

export default EmptyPage;
