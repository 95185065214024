import React, { useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import PropTypes from "prop-types";
import { embedOptions, embedDefaultMessages } from "configDefaults";
import {
  CardContent,
  Grid,
  Icon,
  IconButton,
  Select,
  MenuItem,
  TextareaAutosize,
  Tooltip,
} from "@mui/material";
import { getSubdomainAddress } from "services";
import { MDTypography, MDBox, Panel, CustomHidden, MDButton } from "components";
import { useMaterialUIController } from "context";
import { useSnackbar, lang } from "hooks";
import {
  HelpToolTip,
  scrollBarStyle,
  areRequiredFieldsFilled,
  hasChanges,
  CustomBadge,
} from "utils";
import { useTheme } from "@mui/material/styles";
import { useChatAssistantService } from "services";

function renderCustomTextareaWithLabel({
  value,
  handleInputChange,
  labelText,
  tooltipText,
  name,
  placeholder,
  darkMode,
  formErrors = [],
  rows = 3,
  peventBrakes = true,
}) {
  const labelColor = formErrors.includes(name) ? "red" : darkMode ? "white" : "black";
  const borderColor = formErrors.includes(name)
    ? "red"
    : darkMode
      ? "rgba(255, 255, 255, 0.5)"
      : "rgb(12 10 10 / 12%)";
  const textColor = formErrors.includes(name) ? "red" : darkMode ? "white" : "black";

  const handleKeyPress = (e) => {
    if (peventBrakes) {
      if (e.key === "Enter") {
        e.preventDefault();
      }
    }
  };

  return (
    <MDBox mb={0} mt={0} sx={{ opacity: 0.8 }}>
      <MDBox display="flex" alignItems="center">
        <label
          htmlFor={name}
          style={{
            color: labelColor,
            display: "block",
            opacity: formErrors.includes(name) ? 1.0 : 0.6,
            marginRight: "8px",
            fontSize: "0.875rem",
            flex: "1",
          }}
        >
          {labelText}
        </label>
        {HelpToolTip(tooltipText, darkMode, "textarea")}
      </MDBox>
      <TextareaAutosize
        name={name}
        minRows={rows}
        maxRows={rows}
        aria-label={placeholder}
        placeholder={placeholder}
        value={value}
        onChange={handleInputChange}
        onKeyPress={handleKeyPress} // Add the onKeyPress handler
        style={{
          borderColor: borderColor,
          outline: "none",
          width: "100%",
          padding: "12px",
          fontSize: "0.9rem",
          resize: "none",
          overflow: "auto",
          scrollbarWidth: "thin",
          color: textColor,
          borderRadius: "8px",
          backgroundColor: "transparent",
        }}
      />
    </MDBox>
  );
}

const Embed = ({ embed_sid, embed_config }) => {
  const [controller] = useMaterialUIController();
  const { darkMode, sidenavColor: mainColor } = controller;
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState("iframe");
  const [labelText, setLabelText] = useState(lang("ucfirst")("EMBED_IFRAME_LABEL"));
  const [embedValue, setEmbedValue] = useState();
  const iframe_theme = darkMode ? "dark" : "light";
  //const start_message = embedDefaultMessages.start_message;
  //const placeholder_text = embedDefaultMessages.placeholder_text;
  //const error_message = embedDefaultMessages.error_message;
  const { showSnackbar, SnackbarComponent } = useSnackbar();
  const [formErrors, setFormErrors] = useState([]);
  const [initialValues, setInitialValues] = useState({});
  const base_url = getSubdomainAddress("embed");
  const [activeTab, setActiveTab] = useState(0);
  const [formValues, setFormValues] = useState({
    start_message: embed_config.start_message,
    placeholder_text: embed_config.placeholder_text,
    error_message: embed_config.error_message,
    allowed_origins: embed_config.allowed_origins.join(", "),
  });
  const [requiredFields] = useState(["placeholder_text", "error_message"]);
  const theme = useTheme();
  const { editEmbedSettings } = useChatAssistantService();

  const handleInputChange = (event) => {
    const { name, value: inputValue } = event.target;
    let newValue = inputValue;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: newValue,
    }));
    setFormErrors((prevErrors) => prevErrors.filter((errorField) => errorField !== name));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { start_message, placeholder_text, error_message, allowed_origins } = formValues;
    try {
      let updatedAllowedOrigins;
      if (allowed_origins) {
        const urlPattern = /^(https?:\/\/[a-zA-Z0-9.-]+(:\d+)?)(\/)?$/;
        const urlList = allowed_origins.split("\n").map((url) => url.trim());
        const validURLs = new Set();
        for (let url of urlList) {
          const match = url.match(urlPattern);
          if (!match) {
            setFormErrors(["allowed_origins"]);
            showSnackbar("error", lang("ucfirst")("EMBED_INVALID_ALLOWED_ORIGINS_FORMAT_ERROR"));
            return;
          }
          const formattedUrl = match[1];
          validURLs.add(formattedUrl);
        }
        updatedAllowedOrigins = Array.from(validURLs);
      } else {
        updatedAllowedOrigins = [];
      }
      const response = await editEmbedSettings({
        sid: embed_config.sid,
        error_message,
        placeholder_text,
        start_message: start_message ? start_message : "",
        allowed_origins: updatedAllowedOrigins,
      });
      if (response.status_code === 200) {
        setInitialValues(formValues);
        showSnackbar("success", lang("ucfirst")("EMBED_SETTINGS_UPDATED_SUCCESSFULLY"));
      } else if ((errorMsg = formatErrorMsg(response.data))) {
        setFormErrors(collectKeys(response.data.details));
        showSnackbar("error", errorMsg);
      } else {
        showSnackbar("error", lang("ucfirst")("G_FORM_ERROR"));
      }
    } catch (error) {
      console.error("Error handling record:", error);
      showSnackbar("error", lang("ucfirst")("G_FORM_ERROR"));
      return;
    }
  };

  useEffect(() => {
    const { start_message, placeholder_text, error_message, allowed_origins } = embed_config;
    setFormValues({
      start_message: start_message,
      placeholder_text: placeholder_text,
      error_message: error_message,
      allowed_origins: allowed_origins.join(", "),
    });
    setInitialValues({
      start_message: start_message,
      placeholder_text: placeholder_text,
      error_message: error_message,
      allowed_origins: allowed_origins.join(", "),
    });
    const iframeSrc = replacePlaceholders(embedOptions.iframe);
    setEmbedValue(iframeSrc);
    setSelectedValue("iframe");
  }, [embed_sid, darkMode]);

  const replacePlaceholders = (template) => {
    return template
      .replace("{{ base_url }}", base_url)
      .replace("{{ embed_sid }}", embed_sid)
      .replace("{{ theme }}", iframe_theme);
    //.replace("{{ placeholder_text }}", placeholder_text)
    //.replace("{{ start_message }}", start_message)
    //.replace("{{ error_message }}", error_message);
  };

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(embedValue)
      .then(() => {
        setTooltipOpen(true); // Show tooltip on copy
        setTimeout(() => setTooltipOpen(false), 2000); // Hide after 2 seconds
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const handleSelectChange = (event) => {
    const value = event.target.value;
    setSelectedValue(value);

    if (value === "iframe") {
      setLabelText(lang("ucfirst")("EMBED_IFRAME_LABEL"));
      setEmbedValue(replacePlaceholders(embedOptions.iframe));
    } else if (value === "link") {
      setLabelText(lang("ucfirst")("EMBED_DIRECT_LINK"));
      setEmbedValue(replacePlaceholders(embedOptions.link));
    } else if (value === "bubble") {
      setLabelText(lang("span")("EMBED_BUBBLE_LABEL"));
      setEmbedValue(replacePlaceholders(embedOptions.bubble));
    }
  };
  const [selectOpen, setSelectOpen] = useState(false); // State to control dropdown
  const [isCollapsed, setIsCollapsed] = useState(true);

  const handleIconClick = (event) => {
    event.stopPropagation(); // Prevent label click event from triggering twice
    setSelectOpen((prev) => !prev); // Toggle the open state
  };

  return (
    <Tabs
      forceRenderTabPanel
      selectedIndex={activeTab}
      onSelect={(index) => setActiveTab(index)}
      selectedTabClassName={
        darkMode ? "react-tabs-dark__tab--selected" : "react-tabs-dark__tab--selected"
      }
      style={{
        width: "100%",
        //border: "1px solid #eee",
        height: "95%",
        //paddingBottom: "6px",
      }}
    >
      {SnackbarComponent}
      <TabList>
        <Tab>
          <MDTypography variant="h6" fontWeight="regular" color="inherit">
            <Icon fontSize="small">code</Icon> {lang("ucfirst")("G_CODE")}
          </MDTypography>
        </Tab>
        <Tab>
          <MDTypography variant="h6" fontWeight="regular" color="inherit">
            <Icon fontSize="small">settings</Icon> {lang("ucfirst")("G_SETTINGS")}
          </MDTypography>
        </Tab>
      </TabList>
      <TabPanel style={{ height: "100%" }}>
        <Panel
          darkMode={darkMode}
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "95%", // Ensure panel takes full height
          }}
        >
          <CardContent
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <MDBox
              mt={1}
              sx={{
                flex: 1,
                overflowY: "auto", // Enable scrolling
                paddingRight: "10px",
                height: "100%", // Make sure it takes the full height of the parent container
                ...scrollBarStyle(darkMode),
              }}
            >
              <MDBox
                sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
              >
                <MDTypography
                  variant="h5"
                  gutterBottom
                  color="error"
                  opacity={darkMode ? 0.8 : 1.0}
                  fontWeight="regular"
                >
                  <Icon fontSize="medium" sx={{ transform: "translateY(4px)" }}>
                    code
                  </Icon>{" "}
                  {lang("ucwords")("EMBED_TITLE")}
                </MDTypography>
                <CustomHidden only={["xs", "sm"]}>
                  <CustomBadge text={embed_sid} lang={lang} darkMode={darkMode} />
                </CustomHidden>
              </MDBox>
              <MDBox>
                <MDTypography
                  variant="body1"
                  fontWeight="regular"
                  sx={{ margin: "2px 0 5px 0" }}
                  opacity={darkMode ? 0.6 : 0.8}
                >
                  {lang("ucfirst")("EMBED_SELECT_OPTION_LABEL_TEXT")}:
                </MDTypography>
                <Select
                  name="embed_type"
                  value={selectedValue}
                  onChange={handleSelectChange}
                  open={selectOpen}
                  onClose={() => setSelectOpen(false)}
                  onOpen={() => setSelectOpen(true)}
                  variant="outlined"
                  margin="none"
                  sx={{ height: 40, minWidth: "200px" }}
                  IconComponent={() => (
                    <IconButton
                      onClick={handleIconClick}
                      sx={{
                        fontSize: "18px",
                        padding: "0 5px",
                        color: darkMode ? "lightgrey" : "black",
                      }}
                    >
                      <Icon>expand_more</Icon>
                    </IconButton>
                  )}
                >
                  <MenuItem value="iframe">{lang("ucwords")("G_IFRAME")}</MenuItem>
                  <MenuItem value="link">{lang("ucwords")("G_DIRECT_LINK")}</MenuItem>
                  <MenuItem value="bubble">{lang("ucwords")("G_BUBBLE")}</MenuItem>
                </Select>
              </MDBox>

              <MDBox mt={2}>
                <MDTypography
                  variant="body2"
                  gutterBottom
                  opacity={0.6}
                  fontWeight="regular"
                  onClick={() => setIsCollapsed(!isCollapsed)} // Toggle collapse on click
                  style={{ cursor: "pointer", color: darkMode ? "white" : "black" }} // Cursor and color styling for clickable text
                >
                  {lang("ucwords")("G_OPTIONS")} {/* The clickable G_OPTIONS */}
                  <Icon fontSize="medium" sx={{ transform: "translateY(4px)" }}>
                    {isCollapsed ? "expand_more" : "expand_less"}
                  </Icon>{" "}
                </MDTypography>

                {!isCollapsed && ( // Render content only if not collapsed
                  <MDBox>
                    {selectedValue === "bubble" && (
                      <>
                        <MDTypography variant="body2" gutterBottom opacity={darkMode ? 0.5 : 0.8}>
                          {lang("span")("EMBED_OPTION_ID")}
                        </MDTypography>
                      </>
                    )}
                    <MDTypography variant="body2" gutterBottom opacity={darkMode ? 0.5 : 0.8}>
                      {lang("span")("EMBED_OPTION_THEME")}
                    </MDTypography>
                    <MDTypography variant="body2" gutterBottom opacity={darkMode ? 0.5 : 0.8}>
                      {lang("span", { "@start_message": embedDefaultMessages.start_message })(
                        "EMBED_OPTION_START_MESSAGE"
                      )}
                    </MDTypography>
                    <MDTypography variant="body2" gutterBottom opacity={darkMode ? 0.5 : 0.8}>
                      {lang("span", { "@placeholder_text": embedDefaultMessages.placeholder_text })(
                        "EMBED_OPTION_PLACEHOLDER_TEXT"
                      )}
                    </MDTypography>
                    <MDTypography variant="body2" gutterBottom opacity={darkMode ? 0.5 : 0.8}>
                      {lang("span", { "@error_message": embedDefaultMessages.error_message })(
                        "EMBED_OPTION_ERROR_MESSAGE"
                      )}
                    </MDTypography>
                    <MDTypography variant="body2" gutterBottom opacity={darkMode ? 0.5 : 0.8}>
                      {lang("span", { "@keep_session": embedDefaultMessages.keep_session })(
                        "EMBED_OPTION_KEEP_SESSION"
                      )}
                    </MDTypography>
                    {selectedValue === "bubble" && (
                      <>
                        <MDTypography variant="body2" gutterBottom opacity={darkMode ? 0.5 : 0.8}>
                          {lang("span", { "@position": embedDefaultMessages.position })(
                            "EMBED_OPTION_POSITION"
                          )}
                        </MDTypography>
                        <MDTypography variant="body2" gutterBottom opacity={darkMode ? 0.5 : 0.8}>
                          {lang("span", {
                            "@background_color": embedDefaultMessages.background_color,
                          })("EMBED_OPTION_BACKGROUND_COLOR")}
                        </MDTypography>
                        <MDTypography variant="body2" gutterBottom opacity={darkMode ? 0.5 : 0.8}>
                          {lang("span", { "@icon_color": embedDefaultMessages.icon_color })(
                            "EMBED_OPTION_ICON_COLOR"
                          )}
                        </MDTypography>
                        <MDTypography variant="body2" gutterBottom opacity={darkMode ? 0.5 : 0.8}>
                          {lang("span", { "@el_prefix": embedDefaultMessages.el_prefix })(
                            "EMBED_OPTION_EL_PREFIX"
                          )}
                        </MDTypography>
                      </>
                    )}
                  </MDBox>
                )}
              </MDBox>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <MDBox
                    mt={2}
                    sx={{
                      position: "relative",
                      width: {
                        xs: "100%", // Full width on extra small to medium screens
                        sm: "100%",
                        md: "100%",
                        lg: "100%", // 800px on large screens
                        xl: "100%", // 800px on extra large screens
                      },
                    }}
                  >
                    <MDTypography
                      variant="body2"
                      gutterBottom
                      //color="error"
                      opacity={0.6}
                      fontWeight="regular"
                    >
                      {labelText}
                    </MDTypography>
                    <TextareaAutosize
                      name="embed_value"
                      minRows={10}
                      value={embedValue}
                      style={{
                        borderColor: darkMode ? "rgba(255, 255, 255, 0.5)" : "rgb(12 10 10 / 12%)",
                        outline: "none",
                        width: "100%",
                        //height: "100px !important",
                        padding: "12px",
                        fontSize: "0.9rem",
                        resize: "vertical",
                        overflow: "auto",
                        //minHeight: "80px",
                        color: darkMode ? "white" : "black",
                        backgroundColor: "transparent",
                        borderRadius: "8px",
                      }}
                      readOnly
                    />

                    <Tooltip
                      title={lang("ucwords")("G_COPIED")}
                      open={tooltipOpen}
                      arrow
                      placement="top"
                    >
                      <MDBox
                        component="span"
                        sx={{
                          position: "absolute",
                          bottom: 15,
                          left: "50%",
                          transform: "translateX(-50%)",
                          color: darkMode ? "lightgrey !important" : "white !important",
                          cursor: "pointer",
                          textDecoration: "none",
                          padding: "2px 10px",
                          borderRadius: "16px",
                          transition: "background-color 0.3s ease, color 0.3s ease",
                          backgroundColor: "black !important",
                          fontSize: "12px",
                          "&:hover": {
                            backgroundColor: "#2C2C2C !important",
                          },
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          copyToClipboard();
                        }}
                      >
                        {lang("ucwords")("G_COPY_TO_CLIPBOARD")}
                      </MDBox>
                    </Tooltip>
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>
          </CardContent>
        </Panel>
      </TabPanel>
      <TabPanel>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Panel darkMode={darkMode}>
              <CardContent
                sx={{
                  maxHeight: "60vh",
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  overflowY: "auto",
                  //border: "1px solid #eee",
                  ...scrollBarStyle(darkMode),
                }}
              >
                <MDBox component="form" mt={1} onSubmit={handleSubmit}>
                  <MDBox mb={0} mt={1}>
                    <MDTypography
                      variant="h5"
                      gutterBottom
                      color="error"
                      opacity={darkMode ? 0.8 : 1.0}
                      fontWeight="regular"
                    >
                      <Icon fontSize="medium" sx={{ transform: "translateY(4px)" }}>
                        settings
                      </Icon>{" "}
                      {lang("ucwords")("EMBED_SETTINGS_TITLE")}
                    </MDTypography>
                  </MDBox>
                  <Grid container spacing={2}>
                    <Grid item xs={12} lg={6}>
                      {renderCustomTextareaWithLabel({
                        labelText: lang("ucfirst")("EMBED_SETTINGS_START_MESSAGE_LABEL"),
                        tooltipText: lang()("EMBED_SETTINGS_TIP_FIELD_START_MESSAGE"),
                        name: "start_message",
                        placeholder: lang("ucfirst")(
                          "EMBED_SETTINGS_START_MESSAGE_PLACEHOLDER_TEXT"
                        ),
                        darkMode: darkMode,
                        value: formValues.start_message,
                        handleInputChange: handleInputChange,
                        formErrors: formErrors,
                      })}
                      {renderCustomTextareaWithLabel({
                        labelText: lang("ucfirst")("EMBED_SETTINGS_ERROR_MESSAGE_LABEL") + "*",
                        tooltipText: lang()("EMBED_SETTINGS_TIP_FIELD_ERROR_MESSAGE"),
                        name: "error_message",
                        placeholder: lang("ucfirst")(
                          "EMBED_SETTINGS_ERROR_MESSAGE_PLACEHOLDER_TEXT"
                        ),
                        darkMode: darkMode,
                        value: formValues.error_message,
                        handleInputChange: handleInputChange,
                        formErrors: formErrors,
                      })}
                      {renderCustomTextareaWithLabel({
                        labelText: lang("ucfirst")("EMBED_SETTINGS_PLACEHOLDER_TEXT_LABEL") + "*",
                        tooltipText: lang()("EMBED_SETTINGS_TIP_FIELD_PLACEHOLDER_TEXT"),
                        name: "placeholder_text",
                        placeholder: lang("ucfirst")("EMBED_SETTINGS_PLACEHOLDER_TEXT"),
                        darkMode: darkMode,
                        value: formValues.placeholder_text,
                        rows: 1,
                        handleInputChange: handleInputChange,
                        formErrors: formErrors,
                      })}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      lg={6}
                      sx={{
                        [theme.breakpoints.down("lg")]: {
                          marginTop: "-14px",
                        },
                      }}
                    >
                      {renderCustomTextareaWithLabel({
                        labelText: lang("ucfirst")("EMBED_SETTINGS_ALLOWED_ORIGINS_LABEL"),
                        tooltipText: lang()("EMBED_SETTINGS_TIP_FIELD_ALLOWED_ORIGINS"),
                        name: "allowed_origins",
                        placeholder: "Ex: \nhttps://yourdomain.com\nhttp://localhost:3000",
                        darkMode: darkMode,
                        rows: 15,
                        value: "",
                        handleInputChange: handleInputChange,
                        value: formValues.allowed_origins,
                        formErrors: formErrors,
                        peventBrakes: false,
                      })}
                      <MDBox mt={2} sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <MDButton
                          type="submit"
                          color={mainColor}
                          variant="gradient"
                          sx={{
                            [theme.breakpoints.down("lg")]: {
                              width: "100%", // Make button full width on md, sm, xs screens
                            },
                          }}
                          disabled={
                            !areRequiredFieldsFilled(requiredFields, formValues) ||
                            !hasChanges(formValues, initialValues) ||
                            formErrors.length > 0
                          }
                        >
                          {lang()("G_SAVE")}
                        </MDButton>
                      </MDBox>
                    </Grid>
                  </Grid>
                </MDBox>
              </CardContent>
            </Panel>
          </Grid>
        </Grid>
      </TabPanel>
    </Tabs>
  );
};

Embed.propTypes = {
  embed_sid: PropTypes.string,
  embed_config: PropTypes.object,
};

export default Embed;
