import { useState, useCallback } from "react";
import Snackbar from "components/Notifications/Snackbar"; // Import the Snackbar component

const useSnackbar = () => {
  const [snackbar, setSnackbar] = useState({
    open: false,
    type: "info",
    message: "",
    title: "",
    onClose: null,
    dateTime: "",
  });

  const showSnackbar = useCallback(
    (type, message, title = "", onClose = null, dateTime = "", duration = 5000) => {
      const snackbarProps = {
        success: {
          color: "success",
          icon: "check",
          title: title || "Success",
        },
        error: {
          color: "error",
          icon: "error",
          title: title || "Error",
        },
        info: {
          color: "info",
          icon: "info",
          title: title || "Information",
        },
      };

      const { color, icon, title: snackbarTitle } = snackbarProps[type] || snackbarProps.info;

      setSnackbar({
        open: true,
        type: color,
        message,
        title: snackbarTitle,
        onClose: onClose || (() => setSnackbar((prev) => ({ ...prev, open: false }))),
        dateTime: dateTime || "",
      });

      if (!onClose) {
        setTimeout(() => {
          setSnackbar((prev) => ({ ...prev, open: false }));
        }, duration);
      }
    },
    []
  );

  const SnackbarComponent = snackbar.open ? (
    <Snackbar
      open={snackbar.open}
      onClose={snackbar.onClose}
      type={snackbar.type}
      message={snackbar.message}
      title={snackbar.title}
      dateTime={snackbar.dateTime}
    />
  ) : null;

  return {
    showSnackbar,
    SnackbarComponent,
  };
};

export default useSnackbar;
