import { useState } from "react";

const useSelectToggle = () => {
  const [selectOpen, setSelectOpen] = useState({});

  const handleIconClick = (key, event) => {
    event.stopPropagation();
    setSelectOpen((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  const closeSelect = (key) => {
    setSelectOpen((prev) => ({ ...prev, [key]: false }));
  };

  const openSelect = (key) => {
    setSelectOpen((prev) => ({ ...prev, [key]: true }));
  };

  return {
    selectOpen,
    handleIconClick,
    closeSelect,
    openSelect,
  };
};

export default useSelectToggle;
