import { useUserContext } from "context";
import axios from "../axiosConfig";

export { useApiKeyServices } from "./apiKeyService";
//export { useGetProviders } from "./providerService";
export { useChatAssistantService } from "./chatAssistantService";
export { useHelpAssistantServices } from "./helpAssiatantService";
export { useKnowledgeBaseServices } from "./knowledgeBaseService";
export { useTestProviderServices } from "./testProviderService";

export const getBackendUrl = () => {
  let url = process.env.REACT_APP_BACKEND_ADDRESS || "/backend";
  return url;
};

export const getSubdomainAddress = (subdomain) => {
  const { protocol, hostname } = window.location;
  const domainParts = hostname.split(".");
  const mainDomain = domainParts.slice(-2).join(".");
  return `${protocol}//${subdomain}.${mainDomain}`;
};
export const getBrandName = async (token) => {
  const authToken = token;
  const response = await axios.get(`${getBackendUrl()}/brand-name.txt`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  });
  return response.data;
};

// @Deprecated alias for getBackendUrl
export const backendUrl = (() => {
  console.warn(
    "Warning: 'backendUrl' is deprecated and will be removed in a future release. Please use 'getBackendUrl()' instead."
  );
  return getBackendUrl();
})();

export const useGetServices = () => {
  const { userToken, currentWorkspace } = useUserContext();

  const getToken = () => {
    if (!userToken) {
      console.error("User token is required but not provided.");
      //throw new Error("User token is required but not provided.");
    }
    return userToken;
  };

  const getCurrentWorkspace = () => {
    if (!currentWorkspace) {
      console.error("User workspace is required but not provided.");
      //throw new Error("User token is required but not provided.");
    }
    return currentWorkspace;
  };

  return { getToken, getCurrentWorkspace };
};
