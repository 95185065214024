import React from "react";
import PropTypes from "prop-types";
import { MDBox, DataTable } from "components";
import { ActionsCellV2, SidCell } from "utils";
import { useMaterialUIController } from "context";

const CellComponent = ({ row, darkMode, editAction, duplicateAction, moveAction }) => (
  <ActionsCellV2
    sid={row.original.sid}
    darkMode={darkMode}
    editAction={editAction}
    duplicateAction={duplicateAction}
    moveAction={moveAction}
  />
);

CellComponent.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      sid: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  darkMode: PropTypes.bool.isRequired,
  editAction: PropTypes.func.isRequired,
  duplicateAction: PropTypes.func.isRequired,
  moveAction: PropTypes.func.isRequired,
};

function RecordsGrid({ records, onViewDetails, duplicateAction, moveAction }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const columns = [
    {
      Header: "Unique ID",
      accessor: "sid",
      Cell: (props) => <SidCell {...props} darkMode={darkMode} />,
    },
    { Header: "Name", accessor: "name" },
    {
      Header: "Provider",
      accessor: "provider",
      Cell: ({ value }) => value.name,
    },
    { Header: "Model", accessor: "model", Cell: ({ value }) => value.model },
    { Header: "System Embed ID", accessor: "embed_sid", hidden: true },
    { Header: "Created", accessor: "created" },
    {
      Header: "Actions",
      accessor: "actions",
      Cell: (props) => (
        <CellComponent
          {...props}
          darkMode={darkMode}
          editAction={onViewDetails}
          duplicateAction={duplicateAction}
          moveAction={moveAction}
        />
      ),
      width: 150,
    },
  ];

  const rows = Array.isArray(records)
    ? records.map((record) => ({
        sid: record.sid,
        name: record.name,
        provider: { name: record.model.provider.name },
        model: { model: record.model.model },
        created: record.created,
        embed_sid: record.embed_sid,
        //api_key: record.api_key,
      }))
    : [];

  return (
    <MDBox>
      <DataTable
        table={{ columns, rows }}
        showTotalEntries={false}
        isSorted={true}
        noEndBorder={true}
        entriesPerPage={{ defaultValue: 10 }}
        canSearch={true}
        pagination={{ variant: "contained" }}
      />
    </MDBox>
  );
}

RecordsGrid.propTypes = {
  records: PropTypes.arrayOf(
    PropTypes.shape({
      sid: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      model: PropTypes.object.isRequired,
      created: PropTypes.string.isRequired,
      embed_sid: PropTypes.string,
    })
  ).isRequired,
  onViewDetails: PropTypes.func.isRequired,
  duplicateAction: PropTypes.func.isRequired,
  moveAction: PropTypes.func.isRequired,
};

export default RecordsGrid;
