import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { useMaterialUIController } from "context";
import { useKnowledgeBaseServices } from "services";
import { useUserContext } from "context/UserContext";
import { useSnackbar, lang } from "hooks";
import { CircularProgress } from "@mui/material";
import { MDBox, DialogComponent } from "components";
import { RecordsGrid } from "./components";
import theme from "assets/theme";

const RecordsList = ({ records, setRecords }) => {
  const { getKnowledgeBaseFiles, deleteKnowledgeBaseFile } = useKnowledgeBaseServices();
  const { knowledgeBaseFiles, setKnowledgeBaseFiles } = useUserContext();
  const [loading, setLoading] = useState(true);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteMsg, setDeleteMsg] = useState("");
  const [currentFilename, setCurrentFilename] = useState("");
  const { showSnackbar, SnackbarComponent } = useSnackbar();

  const fetchRecords = useCallback(async () => {
    try {
      const recordsArray = await getKnowledgeBaseFiles();
      setRecords(recordsArray.data);
      setKnowledgeBaseFiles(recordsArray.data);
    } catch (error) {
      console.error("Error fetching records:", error);
    } finally {
      setLoading(false);
    }
  }, [setRecords]);

  useEffect(() => {
    fetchRecords();
  }, [fetchRecords]);

  const handleRecordDelete = (filename) => {
    setDeleteMsg(
      `<strong style=' color: red; margin: 20px;'>${lang("ucfirst")("G_CONFIRM_ACTION")}</strong>`
    );
    setCurrentFilename(filename);
    setDeleteDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  const handleDoDeleteRecord = async () => {
    try {
      const result = await deleteKnowledgeBaseFile({ filename: currentFilename });
      if (result.status_code === 200) {
        fetchRecords();
      } else if (result.status_code === 409) {
        showSnackbar(
          "error",
          lang("", { "@assistants": result.data.join(", ") })("FILES_DELETE_FAILED_ASSISTANTS_MSG")
        );
      } else {
        showSnackbar("error", lang("ucfirst")("FILES_DELETE_FAILED_MSG"));
      }
      setDeleteDialogOpen(false);
    } catch (error) {
      showSnackbar("error", lang("ucfirst")("FILES_DELETE_FAILED_MSG"));
      console.error("Error deleting record:", error);
    }
  };

  return (
    <>
      <DialogComponent
        open={deleteDialogOpen}
        onClose={handleDialogClose}
        title={lang("ucwords")("FILES_DELETE_DIALOG_TITLE")}
        message={lang("span", { "@name": currentFilename })("FILES_DELETE_DIALOG_TEXT")}
        onButtonClick={handleDoDeleteRecord}
        buttonText={lang("strtoupper")("G_DELETE")}
        closeOnBackdropClick={true}
      />
      {SnackbarComponent}
      {loading ? (
        <MDBox
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress
            sx={{
              color: darkMode ? theme.palette.white.main : theme.palette.dark.main,
            }}
          />
        </MDBox>
      ) : (
        <RecordsGrid records={records} deleteAction={handleRecordDelete} />
      )}
    </>
  );
};

RecordsList.propTypes = {
  records: PropTypes.array.isRequired,
  setRecords: PropTypes.func.isRequired,
};

export default RecordsList;
