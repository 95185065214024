import i18n from "i18next";
import HttpApi from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import { getUiConfig } from "localStorage";

// Initialize i18next
i18n
  .use(HttpApi)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: `${process.env.REACT_APP_BASE_PATH}/locales/{{lng}}/translation.json`,
    },
    lng: getUiConfig("lang", process.env.REACT_APP_DEFAULT_LANG),
    fallbackLng: process.env.REACT_APP_DEFAULT_LANG,
    interpolation: {
      escapeValue: false,
    },
    debug:
      process.env.NODE_ENV !== "production" || process.env.REACT_APP_DEBUG_BUILT ? true : false,
  });

/*i18n.on('languageChanged', (lng) => {
  setUiConfig("lang", lng);
});*/

export default i18n;
