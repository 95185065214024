import React from "react";
import PropTypes from "prop-types";
import Hidden from "@mui/material/Hidden";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const CustomHidden = ({ children, only }) => {
  const theme = useTheme();
  //console.log(theme.breakpoints.up(only));
  const matches = useMediaQuery(theme.breakpoints.up("xxl"));

  if (only.includes("xxl") && matches) {
    return null;
  }

  return <Hidden only={only.filter((breakpoint) => breakpoint !== "xxl")}>{children}</Hidden>;
};

CustomHidden.propTypes = {
  children: PropTypes.node.isRequired,
  only: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired,
};

export default CustomHidden;
