import React, { createContext, useState } from "react";
import PropTypes from "prop-types";

const FormValuesContext = createContext();

const FormValuesProvider = ({ children }) => {
  const [formValues, setFormValues] = useState({
    name: "New Provider",
    type: "",
    source: "",
    address: "",
    api_key: "",
    default_config: "",
  });

  const handleChange = (formValues) => {
    console.log(formValues);
    setFormValues(formValues);
  };

  FormValuesProvider.propTypes = {
    children: PropTypes.node.isRequired,
  };

  return (
    <FormValuesContext.Provider value={{ formValues, handleChange }}>
      {children}
    </FormValuesContext.Provider>
  );
};

export { FormValuesContext, FormValuesProvider };
